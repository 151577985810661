import { format } from 'date-fns';
import { useContext } from 'react';
import { AxiosError } from 'axios';
import { NotificationContext } from '../components/Notification/NotificationContext';
import { BookingDataContext } from '../../context/bookingDataContext/bookingDataContext';
import { BookingConfigContext } from '../../context/bookingConfigContext/bookingConfigContext';
import { BookingQuoteContext } from '../../context/quoteContext/quoteContext';
import { BookingStepsContextState } from '../../context/bookingContext/bookingContext.types';
import useApi from './api.service';
import {
  BookingPolicy,
  BookingPolicyEnum,
  PolicyBeneficiaries,
  PolicyBeneficiariesEnum,
  Promotion,
} from '../interfaces/RegisterPolicy.interface';
import {
  Subscriber,
  SubscriberEnum,
} from '../interfaces/subscriber.interfaces';
import { InsurancePayload } from '../interfaces/insurance.interface';
import { getToken, isAgent } from '../helpers/auth.helper';
import getInsurancePayload from '../helpers/getInsurancePayload.helper';
import getBeneficiariesArray from '../helpers/beneficiaries.helper';
import ErrorLoggingService from './errorlogging.service';
import { Policy, PolicyResponse } from '../interfaces/policyResponse.interface';
import {
  BookingConfigEnum,
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from '../../components/BookingWidget/bookingSteps.interface';

import { BookingQuoteBeneficiaries } from '../../components/BookingWidget/BookingQuoteManagment/bookingQuote.interface';
import {
  BookingDataEnum,
  BookingDataResponse,
  BookingManagementEnum,
  Consent,
  ConsentEnum,
  ConsentUsage,
  ConsentUsageEnum,
} from '../../components/BookingWidget/BookingManagment/bookingManagment.interface';
import {
  ExtraHeaders,
  RequestHeaders,
} from '../interfaces/requestHeaders.interface';
import { getUrlPlatformAndCatalog } from '../helpers/url.helper';
import { useSessionContext } from '../../context/sessionContext/sessionContext';

interface PolicyParams {
  bookingInfo: BookingDataResponse | null;
  bookingSteps: BookingStepsContextState;
  bookingReferenceId: string | null;
}

interface UsePolicyDataService {
  registerPolicy: (
    bookingInfo: PolicyParams['bookingInfo'],
    bookingSteps: PolicyParams['bookingSteps'],
    bookingReferenceId: PolicyParams['bookingReferenceId'],
  ) => Promise<Policy>;
  modifyPolicy: (
    bookingInfo: PolicyParams['bookingInfo'],
    bookingSteps: PolicyParams['bookingSteps'],
    bookingReferenceId: PolicyParams['bookingReferenceId'],
  ) => Promise<Policy>;
}

const usePolicyDataService = (): UsePolicyDataService => {
  // context
  const { showNotification } = useContext(NotificationContext);
  const { bookingConfigData } = useContext(BookingConfigContext);
  const { bookingDataResponse } = useContext(BookingDataContext);
  const { bookingQuoteResponse } = useContext(BookingQuoteContext);
  const { decodedSessionToken } = useSessionContext();
  // util
  const { sessionParams } = useSessionContext();
  const API = useApi(bookingConfigData, sessionParams);
  const errorService: ErrorLoggingService = ErrorLoggingService.getInstance();
  // data
  const { cm360Key, cm360Endpoint, psPlatform } = bookingConfigData.dataCenter;
  const headers: ExtraHeaders = {
    [RequestHeaders.X_API_KEY]: cm360Key,
    [RequestHeaders.CLIENT_ID]:
      bookingConfigData[BookingConfigEnum.Channel].psClient,
  };
  const checkIfPromoConsentExists = (): boolean => {
    const consents: Consent[] = <Consent[]>(
      bookingDataResponse![BookingManagementEnum.Consents]
    );
    return consents.some((item: Consent) =>
      item[ConsentEnum.Usages].some(
        (usage: ConsentUsage): boolean =>
          usage.type === 'COMM_PROMOTIONS_ACCEPTANCE' ||
          usage.type === 'MARKETING_PORTAL_PROMO_ACCEPTANCE',
      ),
    );
  };

  const ensureConsents = async (bookingData: any): Promise<void> => {
    try {
      // consent promo not accepted, send request to opt-out
      if (checkIfPromoConsentExists() || !isAgent()) {
        return;
      }
      const bookingId = bookingData[BookingDataEnum.Id];
      const marketingConsent = [
        {
          [ConsentUsageEnum.Type]: 'MARKETING_PORTAL_PROMO_ACCEPTANCE_OPT_OUT',
          [ConsentUsageEnum.IsAgentExclusive]: true,
          [ConsentUsageEnum.Version]: 1,
        },
      ];
      await API.post(
        `${
          bookingConfigData[BookingConfigEnum.DataCenter].cm360Endpoint
        }/booking/${bookingId}/consents`,
        marketingConsent,
      );
    } catch (error) {
      if ((error as any)?.isAxiosError) {
        errorService.log(
          'Set marketing consents error',
          (error as AxiosError)?.response?.data,
        );
      } else {
        errorService.log('Set marketing consents unknown error');
      }
    }
  };

  const preparePayload = (
    bookingInfo: any,
    bookingSteps: BookingStepsContextState,
    bookingReferenceId: string | null,
  ): BookingPolicy => {
    const { bookingData, customer } = bookingInfo;
    const beneficiaries: PolicyBeneficiaries[] = getBeneficiariesArray(
      bookingSteps,
      bookingConfigData,
      bookingInfo,
    ).map(
      (beneficiary: BookingQuoteBeneficiaries): PolicyBeneficiaries => ({
        ...beneficiary,
        ...{
          [PolicyBeneficiariesEnum.CountryCode]:
            bookingConfigData[BookingConfigEnum.Country].country,
        },
      }),
    );

    const insurance: InsurancePayload = getInsurancePayload(
      bookingSteps,
      bookingConfigData,
      bookingQuoteResponse,
      bookingReferenceId,
      decodedSessionToken,
    );

    const policySubscriber: Subscriber = {
      [SubscriberEnum.FirstName]: customer.firstName,
      [SubscriberEnum.LastName]: customer.lastName,
      [SubscriberEnum.Birthdate]: format(
        new Date(customer.dateOfBirth),
        'yyyy-MM-dd',
      ),
      [SubscriberEnum.Civility]: customer.title,
      [SubscriberEnum.Email]: customer.emailAddress,
      [SubscriberEnum.Phone]: customer.phoneNumber1,
      [SubscriberEnum.MobilePhone]: customer.phoneNumber2,
      [SubscriberEnum.Address1]: customer.addressLine1,
      [SubscriberEnum.Address2]: customer.addressLine2,
      [SubscriberEnum.City]: customer.addressLine3,
      [SubscriberEnum.PostalCode]: customer.postalCode,
      [SubscriberEnum.CountryCode]: customer.countryCode,
      [SubscriberEnum.SendEmail]: true,
    };

    const product = {
      productGroup: [{ code: bookingData.product }],
    };

    const subscription = {
      price:
        bookingSteps[BookingStepValueEnum.QuotationProposals][
          BookingSubStepValueEnum.TotalPrice
        ],
      currency: bookingData.proxy.amount.currency,
    };

    const promotion: Promotion = {
      promotionCode: bookingData[BookingDataEnum.PromoCode],
    };

    return {
      [BookingPolicyEnum.QuoteId]: bookingData.proxy.quoteId,
      [BookingPolicyEnum.Beneficiaries]: beneficiaries,
      [BookingPolicyEnum.Insurance]: insurance,
      [BookingPolicyEnum.Subscriber]: policySubscriber,
      [BookingPolicyEnum.Product]: product,
      [BookingPolicyEnum.Subscription]: subscription,
      [BookingPolicyEnum.Promotion]: promotion,
    };
  };

  const registerPolicy = async (
    bookingInfo: any,
    bookingSteps: BookingStepsContextState,
    bookingReferenceId: string | null,
  ): Promise<Policy> => {
    try {
      const apiUrl = `${cm360Endpoint}/ui-proxy/ws-partners/api/${getUrlPlatformAndCatalog(
        bookingConfigData,
      )}/policies`;
      const payload = preparePayload(
        bookingInfo,
        bookingSteps,
        bookingReferenceId,
      );
      await ensureConsents(bookingInfo.bookingData);

      const response = await API.post(apiUrl, payload, {
        headers,
      });

      if (!(response.status === 200 || response.status === 201)) {
        const errorResponse = await response.data;
        showNotification(errorResponse.error[0].description, 'error', false);
        throw new Error(errorResponse.error[0].description);
      }

      const policyDataResponse: PolicyResponse = await response.data;
      return policyDataResponse.policies[0];
    } catch (error) {
      errorService.log('Error when modifying policy: ', error);
      showNotification('unexpectedError', 'error', false);
      throw new Error('Unexpected error');
    }
  };

  const modifyPolicy = async (
    bookingInfo: any,
    bookingSteps: BookingStepsContextState,
    bookingReferenceId: string | null,
  ): Promise<Policy> => {
    try {
      const { policyId } = bookingInfo.bookingData.proxy;
      const apiUrl = `${cm360Endpoint}/ui-proxy/ws-partners/api/${getUrlPlatformAndCatalog(
        bookingConfigData,
      )}/policies/${policyId}/modify`;
      const payload = {
        ...preparePayload(bookingInfo, bookingSteps, bookingReferenceId),
        policyId,
        bookingDataId: bookingInfo.bookingData.id,
      };
      await ensureConsents(bookingInfo.bookingData);
      const response = await API.put(apiUrl, payload, {
        headers,
      });

      if (!(response.status === 200 || response.status === 201)) {
        const errorResponse = await response.data;
        showNotification(errorResponse.error[0].description, 'error', false);
        throw new Error(errorResponse.error[0].description);
      }

      const policyDataResponse: PolicyResponse = await response.data;
      return policyDataResponse.policies[0];
    } catch (error) {
      errorService.log('Error when modifying policy: ', error);
      showNotification('unexpectedError', 'error', false);
      throw new Error('Unexpected error');
    }
  };

  return { registerPolicy, modifyPolicy };
};

export default usePolicyDataService;
