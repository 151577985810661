import React, { createContext, useState, useContext, ReactNode } from 'react';

interface HomeMedicalContextType {
  isHomeMedical: boolean;
  setIsHomeMedical: React.Dispatch<React.SetStateAction<boolean>>;
  isMailSent: boolean;
  setIsMailSent: React.Dispatch<React.SetStateAction<boolean>>;
  secret: string;
  setSecret: React.Dispatch<React.SetStateAction<string>>;
}

const HomeMedicalContext = createContext<HomeMedicalContextType | undefined>(
  undefined,
);

export const HomeMedicalProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [isHomeMedical, setIsHomeMedical] = useState(false);
  const [isMailSent, setIsMailSent] = useState(false);
  const [secret, setSecret] = useState<string>('');

  return (
    <HomeMedicalContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isHomeMedical,
        setIsHomeMedical,
        isMailSent,
        setIsMailSent,
        secret,
        setSecret,
      }}
    >
      {children}
    </HomeMedicalContext.Provider>
  );
};

export const useHomeMedical = (): HomeMedicalContextType => {
  const context: HomeMedicalContextType | undefined =
    useContext(HomeMedicalContext);
  if (context === undefined) {
    throw new Error('useHomeMedical must be used within a HomeMedicalProvider');
  }
  return context;
};
