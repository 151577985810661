import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { format, isValid, parse, parseISO } from 'date-fns';

dayjs.extend(utc);

const DATES_SUPPORTED_FORMATS_PS = ['dd/MM/yyyy', 'yyyy-MM-dd', 'yyyy/MM/dd'];
export const DATES_SUPPORTED_FORMATS_PS_DAYJS = ['DD/MM/YYYY', 'YYYY-MM-DD'];

const parseDateDDMMYYYY = (dateString: string): Date => {
  const parts = dateString.split('/');
  const day = parseInt(parts[0], 10);
  // Subtract 1 from month because months are 0-indexed in JS Date
  const month = parseInt(parts[1], 10) - 1;
  const year = parseInt(parts[2], 10);
  return new Date(year, month, day);
};

const parseStringToDate = (dateString: string): Date => {
  const parsedDate =
    DATES_SUPPORTED_FORMATS_PS.map((dateFormat) =>
      parse(dateString, dateFormat, new Date()),
    ).find((date) => isValid(date)) || new Date(dateString);
  if (!isValid(parsedDate)) {
    throw new Error(`Can't parse date: ${dateString}`);
  }
  return parsedDate;
};

export const addTimeToDate = (date: dayjs.Dayjs): dayjs.Dayjs => {
  const now = dayjs();
  return dayjs(date).hour(now.hour()).minute(now.minute()).second(now.second());
};

export const parseDateTimeStringToDate = (date: string): string => {
  const parsedDate = parseISO(date);
  return format(parsedDate, 'MM/dd/yyyy');
};

export const customParseFunction = (dateString: string): Dayjs =>
  dayjs(dateString, DATES_SUPPORTED_FORMATS_PS_DAYJS, true);

export const getStartOfDay = (): Dayjs => dayjs().utc(true).startOf('day');

export const isDateBeforeNow = (date: string): boolean => {
  const startOfDay = getStartOfDay();
  const chekingDate = dayjs(date).utc(true);

  return chekingDate.isBefore(startOfDay);
};

export default parseStringToDate;
