import React, { createContext, useMemo, useState } from 'react';
import {
  BookingConfig,
  BookingConfigEnum,
  Country,
} from '../../components/BookingWidget/bookingSteps.interface';
import { BookingConfigContextState } from './bookingConfigContext.types';
import { bookingConfigInit } from '../../components/BookingWidget/BookingConfig/bookingConfig';

const contextDefaultValues: BookingConfigContextState = {
  bookingConfigData: bookingConfigInit,
  updateConfig: (bookingConfig: BookingConfig): void => undefined,
  getCountry: (): Country | null => null,
};
export const BookingConfigContext =
  createContext<BookingConfigContextState>(contextDefaultValues);

export const BookingConfigContextProvider: React.FC<
  React.PropsWithChildren<object>
> = ({ children }) => {
  const [bookingConfigData, setBookingConfig] = useState<BookingConfig>(
    contextDefaultValues.bookingConfigData,
  );

  const handleUpdate = (bookingConfig: BookingConfig): void =>
    setBookingConfig(bookingConfig);

  const getCountry = (): Country | null =>
    bookingConfigData[BookingConfigEnum.Country] || null;

  const bookingConfig: BookingConfigContextState = useMemo(
    () => ({ bookingConfigData, updateConfig: handleUpdate, getCountry }),
    [bookingConfigData],
  );

  return (
    <BookingConfigContext.Provider value={bookingConfig}>
      {children}
    </BookingConfigContext.Provider>
  );
};
