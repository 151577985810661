import { useCallback, useContext, useMemo } from 'react';
import { AxiosResponse } from 'axios';
import { BookingDataContext } from '../../../context/bookingDataContext/bookingDataContext';
import { BookingConfigEnum } from '../../BookingWidget/bookingSteps.interface';
import { BookingConfigContext } from '../../../context/bookingConfigContext/bookingConfigContext';
import useApi from '../../../shared/services/api.service';
import { NotificationContext } from '../../../shared/components/Notification/NotificationContext';
import { LoadingContext } from '../../../context/loadingContext/loadingContext';
import { useSessionContext } from '../../../context/sessionContext/sessionContext';

interface UseFinishMedicalScreening {
  isMedicalScreeningComplete: boolean;
  finishMedicalScreening: () => Promise<void>;
}

const useFinishMedicalScreening = (): UseFinishMedicalScreening => {
  const { bookingDataResponse, updateBookingData } =
    useContext(BookingDataContext);
  const { showNotification } = useContext(NotificationContext);
  const { setIsLoading } = useContext(LoadingContext);
  const { bookingConfigData } = useContext(BookingConfigContext);
  const { sessionParams } = useSessionContext();
  const API = useApi(bookingConfigData, sessionParams);
  const apiCM360Url: string =
    bookingConfigData?.[BookingConfigEnum.DataCenter]?.cm360Endpoint;

  const finishMedicalScreening = useCallback(async () => {
    setIsLoading(true);
    const url = `${apiCM360Url}/pemc-screenings/${bookingDataResponse?.bookingData?.id}`;
    const response: AxiosResponse<{ pemcScreeningId: string }> =
      await API.post(url);
    setIsLoading(false);
    if (!response.data?.pemcScreeningId) {
      showNotification('unexpectedError', 'error', false);
      return;
    }

    updateBookingData({
      ...bookingDataResponse!,
      bookingData: {
        ...bookingDataResponse!.bookingData!,
        pemcScreeningId: response.data.pemcScreeningId,
      },
    });
  }, [apiCM360Url, bookingDataResponse]);

  const isMedicalScreeningComplete = useMemo(
    () => !!bookingDataResponse?.bookingData.pemcScreeningId,
    [bookingDataResponse],
  );

  return { isMedicalScreeningComplete, finishMedicalScreening };
};

export default useFinishMedicalScreening;
