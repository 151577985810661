enum GTMEventTypes {
  TrackPageview = 'trackPageview',
  TrackInteraction = 'trackInteraction',
  TrackTransaction = 'trackTransaction',
}

enum InteractionTypes {
  CTAButtons = 'CTAButtons',
}

type GTMTripType = 'Single' | 'Multi' | '';

type PageViewEventPayload = {
  destination: string[];
  destinationIsoCodes: string[];
  startDate: string; // format expected YYYY-MM-DD
  endDate: string; // format expected YYYY-MM-DD
  tripDuration: string; // in days
  tripType: GTMTripType;
  leadTime: string; // number of days before trip from date of quote
  totalTravellers: string;
  travellers: {
    ages: string[];
  };
  productName: string; // name of plan selected
  productPrice: string;
  currencyCode: string; // 'AUD'
  planId: string; // id# unique to plan chosen, if possible
  selectedExcess: string;
  cancellationLimit: string;
  addOns: {
    selection: string[];
  };
  policyId: string;
};

type PageViewEvent = {
  event: GTMEventTypes.TrackPageview;
  pageType: string; // Insurance white label
  pageName: string; // Step name
  fullPageUrl: string;
} & PageViewEventPayload;

type InteractionEvent = {
  event: GTMEventTypes.TrackInteraction;
  interactionValue: InteractionTypes;
  interactionLabel: string;
};

type TransactionEventPayload = {
  transactionAffiliation: string; // make sure to match region FCAU / FCNZ / FCZA / FCUK / FCCA
  transactionId: string;
  transactionTotal: number;
  transactionTax: 0; // no tax in AU but is a required variable so send 0
  transactionProducts: [
    {
      sku: string; // policyNumber
      name: string; // name of plan bought ('Silver')
      category: 'insurance';
      price: number; // integer to 2 decimals
      quantity: number;
    },
  ];
  primaryEmailAddress: string;
  primaryPhone: string; // this is the purchaser's detail
  paymentMethod: string;
  merchantFeeTotal: number | null; // only include if applicable to platform
};

type TransactionEvent = {
  event: GTMEventTypes.TrackTransaction;
} & TransactionEventPayload;

type GtmEvent = PageViewEvent | InteractionEvent | TransactionEvent;

export {
  GtmEvent,
  GTMTripType,
  GTMEventTypes,
  PageViewEventPayload,
  InteractionTypes,
  TransactionEventPayload,
};
