import { useContext, useMemo } from 'react';
import { t } from 'i18next';
import {
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from '../../BookingWidget/bookingSteps.interface';
import { TravellerAgeAndBirthdate } from '../../QuotationFormWeb/quotationFormWeb.types';
import { BookingContext } from '../../../context/bookingContext/bookingContext';

interface TripParticipantDetailsBlueprint {
  childrenCount: number;
  adultsCount: number;
  haveAdultsOnTrip: boolean;
  haveChildrenOnTrip: boolean;
}

interface UseGetTravelers {
  getTravelers: string[];
}

const useGetTravelers = (): UseGetTravelers => {
  const { bookingSteps } = useContext(BookingContext);

  const isChild = (age: string): boolean => age === '<18' || +age < 18;
  const isAdult = (age: string): boolean => !isChild(age);

  const { adultsCount, childrenCount, haveAdultsOnTrip, haveChildrenOnTrip } =
    useMemo((): TripParticipantDetailsBlueprint => {
      const travellerDemographics: Pick<
        TripParticipantDetailsBlueprint,
        'adultsCount' | 'childrenCount'
      > = bookingSteps.quotationForm.travellersAge.reduce(
        (acc, info) => {
          if (isChild(info.age)) {
            acc.childrenCount += 1;
          } else {
            acc.adultsCount += 1;
          }
          return acc;
        },
        { adultsCount: 0, childrenCount: 0 },
      );

      return {
        ...travellerDemographics,
        haveAdultsOnTrip: travellerDemographics.adultsCount > 0,
        haveChildrenOnTrip: travellerDemographics.childrenCount > 0,
      };
    }, [bookingSteps.quotationForm.travellersAge]);

  const adultsTravellerText = useMemo(
    (): string =>
      `${adultsCount} ${
        adultsCount > 1
          ? t('BOOKING.QUOTATION_TRIP_SUMMARY_BLOCK.adults')
          : t('BOOKING.QUOTATION_TRIP_SUMMARY_BLOCK.adult')
      } - ${bookingSteps[BookingStepValueEnum.QuotationForm][
        BookingSubStepValueEnum.TravellersAge
      ]
        .filter((traveller: TravellerAgeAndBirthdate) => isAdult(traveller.age))
        .map((traveller: TravellerAgeAndBirthdate) => traveller.age)
        .join(', ')}`,
    [adultsCount, bookingSteps],
  );

  const getChildrenTravellerText = useMemo(
    (): string =>
      `${childrenCount} ${
        childrenCount > 1
          ? t('BOOKING.QUOTATION_TRIP_SUMMARY_BLOCK.children')
          : t('BOOKING.QUOTATION_TRIP_SUMMARY_BLOCK.child')
      } - ${bookingSteps[BookingStepValueEnum.QuotationForm][
        BookingSubStepValueEnum.TravellersAge
      ]
        .filter((traveller: TravellerAgeAndBirthdate) => isChild(traveller.age))
        .map((traveller: TravellerAgeAndBirthdate) => traveller.age)
        .join(', ')}`,
    [childrenCount, bookingSteps],
  );

  const getTravelers = useMemo(
    () => [
      ...(haveAdultsOnTrip ? [adultsTravellerText] : []),
      ...(haveChildrenOnTrip ? [getChildrenTravellerText] : []),
    ],
    [haveAdultsOnTrip, haveChildrenOnTrip],
  );

  return {
    getTravelers,
  };
};

export default useGetTravelers;
