import React, { memo, useContext, useEffect, useRef } from 'react';
import { t } from 'i18next';
import './OpenQuoteSummary.scss';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  BookingStep,
  BookingStepEnum,
} from '../BookingWidget/bookingSteps.interface';
import { CacheDataContext } from '../../context/cacheContext/cacheContext';
import getUrlSearchParam from '../../shared/helpers/urlSearchParams.helper';
import { useHomeMedical } from '../../context/homeMedicalContext/homeMedicalContext';

interface OpenQuoteSummaryProps {
  activeStep: BookingStep;
}

const OpenQuoteSummary: React.FC<OpenQuoteSummaryProps> = ({ activeStep }) => {
  const { store, setCacheValue } = useContext(CacheDataContext);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const urlParams: URLSearchParams = new URLSearchParams(
    window.location.search,
  );
  const homeMedicalQueryParam: string | null = getUrlSearchParam(
    'homeMedical',
    urlParams,
  );
  const { isHomeMedical } = useHomeMedical();

  const showSummaryForHomeMedical = !isHomeMedical && !homeMedicalQueryParam;

  const summaryVisibleFor = [
    showSummaryForHomeMedical && 'QuotationInformationForm',
    'QuotationProposals',
    'QuotationRecapDeclarations',
    'QuotationConfirmation',
  ];

  useEffect(() => {
    const bottom = wrapperRef.current?.getBoundingClientRect().bottom;
    if (bottom && store.mobileSummaryPosition !== bottom) {
      setCacheValue('mobileSummaryPosition', bottom);
    }
  }, [setCacheValue, store.mobileSummaryPosition, wrapperRef]);

  if (store.currentStep === 1 || store.currentStep === 5) {
    return null;
  }

  return (
    <div ref={wrapperRef} className="quotation-summary-wrapper">
      {summaryVisibleFor.includes(activeStep[BookingStepEnum.Component]) && (
        <button
          onClick={() =>
            setCacheValue('mobileSummaryVisible', !store.mobileSummaryVisible)
          }
          className="open-quote-summary"
          type="button"
        >
          {t('BOOKING.QUOTATION_SUMMARY_BLOCK.title')}
          {store.mobileSummaryVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </button>
      )}
    </div>
  );
};

export default memo(OpenQuoteSummary);
