import React, { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider } from '@mui/material';
import { BookingContext } from '../../../context/bookingContext/bookingContext';
import {
  BookingStepEnum,
  BookingStepValueEnum,
  BookingSubStepOptionEnum,
  BookingSubStepValueEnum,
} from '../../BookingWidget/bookingSteps.interface';
import { ProductEnum } from '../../QuotationProposals/useFetchProposalsConf';
import QuotationElementPriceRow from '../../QuotationsBlockShare/QuotationPriceElement/QuotationElementPriceRow';
import { PriceColorGray, PriceFontSmall } from './priceSummary.const';
import { getActiveStepConfig } from '../../../shared/helpers/getActiveCard';
import {
  AdditionalDataProposal,
  CancellationLimit,
  Excess,
  Option,
} from '../../QuotationProposals/components/quotationProposal.interface';
import { BookingConfigContext } from '../../../context/bookingConfigContext/bookingConfigContext';
import QuotationElementBlockPrice from '../../QuotationsBlockShare/QuotationPriceElement/QuotationElementBlockPrice';
import { usePriceSummaryLoading } from '../../../context/priceSummaryContext/priceSummaryContext';

const PriceSummarySelectedCover: FC = () => {
  const { t } = useTranslation();
  const { bookingSteps } = useContext(BookingContext);
  const { bookingConfigData } = useContext(BookingConfigContext);
  const { selectedCoverPrice } = usePriceSummaryLoading();

  const excessLabel = useMemo((): string | null => {
    const selectedProduct =
      bookingSteps[BookingStepValueEnum.QuotationProposals][
        BookingSubStepValueEnum.Proposal
      ]?.[ProductEnum.Code] ||
      bookingSteps[BookingStepValueEnum.QuotationProposals][
        BookingSubStepValueEnum.Tables
      ];
    const activeStepConfig = getActiveStepConfig(
      bookingSteps,
      bookingConfigData,
      BookingStepValueEnum.QuotationProposals,
    );
    const additionData = activeStepConfig[
      BookingStepEnum.AdditionalData
    ] as AdditionalDataProposal;
    const selectedTripType =
      bookingSteps[BookingStepValueEnum.QuotationForm][
        BookingSubStepValueEnum.TripDetails
      ][BookingSubStepOptionEnum.Value];
    const excess: Excess[] = additionData.excess[selectedTripType];
    const excessValue =
      bookingSteps[BookingStepValueEnum.QuotationProposals][
        BookingSubStepValueEnum.Excess
      ];
    const optionsSubStepActive: Excess = excess?.filter(
      (option: Excess) => option.code === selectedProduct,
    )?.[0];
    if (optionsSubStepActive === null) {
      throw new Error('useMemo-excessPrice bookingConfig excessOption is null');
    }
    const activeExcessOption: Option = optionsSubStepActive?.options?.filter(
      (option: Option) => option.value === excessValue,
    )?.[0];
    return activeExcessOption?.label;
  }, [
    bookingSteps[BookingStepValueEnum.QuotationProposals][
      BookingSubStepValueEnum.Excess
    ],
  ]);
  const cancellationLimitLabel = useMemo((): string | null => {
    const selectedProduct =
      bookingSteps[BookingStepValueEnum.QuotationProposals][
        BookingSubStepValueEnum.Proposal
      ]?.[ProductEnum.Code] ||
      bookingSteps[BookingStepValueEnum.QuotationProposals][
        BookingSubStepValueEnum.Tables
      ];
    const activeStepConfig = getActiveStepConfig(
      bookingSteps,
      bookingConfigData,
      BookingStepValueEnum.QuotationProposals,
    );
    const additionData = activeStepConfig[
      BookingStepEnum.AdditionalData
    ] as AdditionalDataProposal;
    const selectedTripType =
      bookingSteps[BookingStepValueEnum.QuotationForm][
        BookingSubStepValueEnum.TripDetails
      ][BookingSubStepOptionEnum.Value];

    const cancellationLimit: CancellationLimit[] =
      additionData.cancellationLimits[selectedTripType];
    const cancellationLimitValue =
      bookingSteps[BookingStepValueEnum.QuotationProposals][
        BookingSubStepValueEnum.CancellationLimit
      ];
    const optionsSubStepActive: CancellationLimit = cancellationLimit?.filter(
      (option: CancellationLimit) => option.code === selectedProduct,
    )?.[0];
    if (optionsSubStepActive === null) {
      throw new Error(
        'useMemo-cancellationLimitPrice bookingConfig excessOption is null',
      );
    }
    const activeCancellationLimitOption: Option =
      optionsSubStepActive?.options?.filter(
        (option: Option) => option.value === cancellationLimitValue,
      )?.[0];
    return activeCancellationLimitOption?.label;
  }, [
    bookingSteps[BookingStepValueEnum.QuotationProposals][
      BookingSubStepValueEnum.CancellationLimit
    ],
  ]);

  return selectedCoverPrice ? (
    <>
      <Box sx={{ marginBottom: '0.3rem' }}>
        <QuotationElementPriceRow
          label={t('BOOKING.QUOTATION_SUMMARY_BLOCK.selectedCover')}
          value={selectedCoverPrice.toString()}
          fontWeightValue="bold"
          fontColorLabel={PriceColorGray}
          isValueCurrency
        />
        <QuotationElementPriceRow
          label={
            bookingSteps[BookingStepValueEnum.QuotationProposals][
              BookingSubStepValueEnum.Proposal
            ][ProductEnum.Description]
          }
          value=""
          fontWeightLabel="bold"
        />
      </Box>
      {excessLabel ? (
        <>
          <QuotationElementPriceRow
            label={t('BOOKING.QUOTATION_SUMMARY_BLOCK.excess')}
            fontColorLabel={PriceColorGray}
            fontSizeLabel={PriceFontSmall}
          />
          <QuotationElementPriceRow
            label={excessLabel}
            fontWeightLabel="bold"
            fontSizeLabel={PriceFontSmall}
          />
        </>
      ) : null}
      {cancellationLimitLabel ? (
        <>
          <QuotationElementPriceRow
            label={t('BOOKING.QUOTATION_SUMMARY_BLOCK.cancellationLimit')}
            fontColorLabel={PriceColorGray}
            fontSizeLabel={PriceFontSmall}
          />
          <QuotationElementPriceRow
            label={cancellationLimitLabel}
            fontWeightLabel="bold"
            fontSizeLabel={PriceFontSmall}
          />
        </>
      ) : null}
      <Divider sx={{ margin: '0.5rem 0' }} />
    </>
  ) : null;
};

export default PriceSummarySelectedCover;
