import React, { useMemo, useContext, useCallback } from 'react';
import { Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import QuotationElementBlockPrice from '../../QuotationsBlockShare/QuotationPriceElement/QuotationElementBlockPrice';
import CurrencyComponent from '../../../shared/components/Currency/Currency';
import { PromotionPriceProps } from './PromotionPrice';
import {
  BookingConfigEnum,
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from '../../BookingWidget/bookingSteps.interface';
import { AgentConfigContext } from '../../../context/agentContext/agentConfigContext';
import { BookingConfigContext } from '../../../context/bookingConfigContext/bookingConfigContext';
import { BookingContext } from '../../../context/bookingContext/bookingContext';
import { usePriceSummaryLoading } from '../../../context/priceSummaryContext/priceSummaryContext';
import { PriceColorBlack } from '../PriceSummarySection/priceSummary.const';

const PromotionAppliedPrice: React.FC<PromotionPriceProps> = ({
  promoCode,
  price = 0,
  promoRate = '0',
  t,
}) => {
  const { bookingConfigData } = useContext(BookingConfigContext);
  const { update, bookingSteps } = useContext(BookingContext);
  const { isAgentView } = useContext(AgentConfigContext);
  const { totalPrice } = usePriceSummaryLoading();

  // @TODO - temporary solution, this value should be calculated on the P&S side
  const discountValue = useMemo(
    () =>
      (
        (totalPrice?.grossPremiumBeforePromotion ?? 0) -
        (totalPrice?.grossPremium ?? 0)
      ).toFixed(2),
    [totalPrice],
  );

  const showPromotionSection =
    isAgentView && bookingSteps.currentStep !== 'quotationRecapDeclarations';

  const getPromotionCodeName = (code: string): string => {
    const promotions =
      bookingConfigData?.[BookingConfigEnum.PromotionCodes] ?? [];

    const promotion = promotions.find(
      (item) => item.value.toLowerCase() === code.toLowerCase(),
    );

    return promotion?.name || '';
  };

  const handleRemovePromotion = useCallback(() => {
    update(
      '',
      BookingStepValueEnum.QuotationForm,
      BookingSubStepValueEnum.PromoCode,
    );
  }, [update]);

  return (
    <QuotationElementBlockPrice
      label={t('BOOKING.QUOTATION_FORM.web.promotion') || 'Discount'}
      color={PriceColorBlack}
      product={[
        {
          productName: getPromotionCodeName(promoCode) || promoCode,
          customTemplate: (
            <Box className="promotionSummary">
              <span>- </span>
              <CurrencyComponent
                typographyStyle={{
                  fontWeight: '300',
                  color: PriceColorBlack,
                }}
                value={Number(discountValue)}
              />
              {showPromotionSection && (
                <ClearIcon
                  fontSize="small"
                  className="clearPromotion"
                  onClick={handleRemovePromotion}
                />
              )}
            </Box>
          ),
        },
      ]}
    />
  );
};

export default PromotionAppliedPrice;
