import { format, subYears } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import {
  BookingDataResponse,
  BookingManagementEnum,
  Companion,
  CompanionEnum,
} from '../../components/BookingWidget/BookingManagment/bookingManagment.interface';
import { ApiDateFormat } from '../consts/app.const';
import {
  FormQuotationInformationTraveller,
  FormQuotationInformationTravellerEnum,
} from '../../components/QuotationInformationForm/quoation-information.interface';
import {
  BookingConfig,
  BookingConfigEnum,
} from '../../components/BookingWidget/bookingSteps.interface';
import {
  isTravellerAgeAndBirthdate,
  TravellerAgeAndBirthdate,
} from '../../components/QuotationFormWeb/quotationFormWeb.types';
import { getStartOfDay } from './date.helper';

export const getDateOfBirth = (age: string): string => {
  if (
    age === undefined ||
    age === null ||
    Number.isNaN(Number(age)) ||
    Number(age) < 0
  ) {
    throw new Error(`Invalid age ${age}. It can not be converted to DoB.`);
  }

  const parsedAge = Number(age);
  let startPointdate = dayjs();

  // Workaround for PS. It returns error when DOB = today date,
  // so just subtrract 1 day
  if (parsedAge === 0) {
    startPointdate = startPointdate.subtract(1, 'day');
  }
  // End of workaround
  const dob = startPointdate.subtract(parsedAge, 'year').toDate();
  return format(dob, 'yyyy-MM-dd');
};

export const getCompanionId = (
  companions: Companion[],
  index: number,
  bookingDataResponse: BookingDataResponse | null,
): number | undefined => {
  if (
    bookingDataResponse &&
    bookingDataResponse[BookingManagementEnum.Companions] &&
    bookingDataResponse[BookingManagementEnum.Companions][index]
  ) {
    return +bookingDataResponse[BookingManagementEnum.Companions][index][
      CompanionEnum.Id
    ]!;
  }
  return undefined;
};

export const getCompanionsAge = (
  travellerAgeAndBirthdates: TravellerAgeAndBirthdate[],
  bookingDataResponse: BookingDataResponse | null,
): Companion[] => {
  const travellersAge: Companion[] = [];
  travellerAgeAndBirthdates.forEach(
    (
      travellerAgeAndBirthdate: TravellerAgeAndBirthdate,
      index: number,
    ): void => {
      travellersAge.push({
        [CompanionEnum.DateOfBirth]: travellerAgeAndBirthdate.dateOfBirth
          ? travellerAgeAndBirthdate.dateOfBirth.format('YYYY-MM-DD')
          : getDateOfBirth(travellerAgeAndBirthdate.age),
        [CompanionEnum.Age]: +travellerAgeAndBirthdate.age,
        [CompanionEnum.IsCustomer]: index === 0,
      });
      const companionId = getCompanionId(
        travellersAge,
        index,
        bookingDataResponse,
      );
      if (companionId) {
        travellersAge[index][CompanionEnum.Id] = companionId;
      }
    },
  );
  return travellersAge;
};

export const getTravellersAge = (
  ageRanges: TravellerAgeAndBirthdate[] | string[],
): { birthDate: string }[] => {
  const travellersAge: { birthDate: string; age?: string }[] = [];

  ageRanges.forEach((range: TravellerAgeAndBirthdate | string) => {
    if (isTravellerAgeAndBirthdate(range)) {
      if (range.dateOfBirth) {
        travellersAge.push({
          birthDate: format(range.dateOfBirth.toDate(), 'yyyy-MM-dd'),
        });
      } else {
        travellersAge.push({ birthDate: getDateOfBirth(range.age) });
      }
    } else if (typeof range === 'string') {
      travellersAge.push({ birthDate: getDateOfBirth(range) });
    }
  });

  return travellersAge;
};

export const getBirthdateFromAge = (
  age: string | undefined,
  birthdayFormat: string,
): string => {
  const currentDate = new Date();
  const birthdate = subYears(currentDate, +age!);
  return format(birthdate, birthdayFormat);
};

export const getTravellerAgeFromDate = (
  birthDate: Dayjs | undefined,
  birthDateFormat = 'YYYY-MM-DD',
): number => {
  if (!birthDate) {
    return 0;
  }
  const today = getStartOfDay();
  const birthDay = dayjs(birthDate, birthDateFormat).utc(true).startOf('day');
  const age = today.diff(birthDay, 'year');

  return age;
};

export const getAgeForTraveller = (
  travellerAge: string | Dayjs | undefined,
): number =>
  dayjs.isDayjs(travellerAge)
    ? getTravellerAgeFromDate(travellerAge)
    : +travellerAge!;

export const getBirthdateForTraveller = (
  travellerAge: string | Dayjs | undefined,
): string => {
  if (!travellerAge) return '';
  return dayjs.isDayjs(travellerAge)
    ? format((travellerAge as Dayjs).toDate(), ApiDateFormat)
    : getBirthdateFromAge(travellerAge, ApiDateFormat);
};

export const getTravellerAge = (
  traveller: FormQuotationInformationTraveller,
  bookingConfigData: BookingConfig,
): string | number | undefined => {
  const travellerAge = traveller[FormQuotationInformationTravellerEnum.Age];
  const travellerBirthDate =
    traveller[FormQuotationInformationTravellerEnum.BirthDate];
  if (!travellerAge && travellerBirthDate) {
    return getTravellerAgeFromDate(travellerBirthDate as unknown as Dayjs);
  }
  return typeof travellerAge === 'string' && travellerAge.includes('/')
    ? dayjs().diff(
        dayjs(
          travellerAge,
          bookingConfigData[BookingConfigEnum.BirthdayFormat],
        ),
        'year',
      )
    : traveller.age;
};

export const getAgeByDayOfBirth = (
  age: string,
  dateOfBirth: Dayjs | undefined,
): { age: string; dateOfBirth: Dayjs } => {
  const calculateAge = (): string => {
    if (dateOfBirth && dayjs.isDayjs(dateOfBirth)) {
      return dayjs().diff(dateOfBirth, 'year').toString();
    }
    return age;
  };

  const calculateDateOfBirth = (): Dayjs =>
    dateOfBirth ? dayjs(dateOfBirth) : dayjs(getDateOfBirth(age));

  return {
    age: calculateAge(),
    dateOfBirth: calculateDateOfBirth(),
  };
};

export default getTravellersAge;
