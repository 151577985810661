export default function queryUrlBuilder<
  T extends Record<string, string | null> | object,
>(baseUrl: string, options: T): URL {
  const url = new URL(baseUrl);

  Object.entries(options).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      url.searchParams.set(key, value);
    }
  });

  return url;
}
