import {
  BookingStep,
  BookingStepEnum,
  BookingStepValueEnum,
  BookingSubStep,
} from '../BookingWidget/bookingSteps.interface';

export interface QuotationInformationFormProps {
  [BookingStepEnum.Cards]: BookingSubStep[];
  activeStepConfig: BookingStep;
  nextStep: (step?: number) => void;
  previousStep: () => void;
  keyName: BookingStepValueEnum;
}

export enum FormQuotationInformationEnum {
  Title = 'title',
  Firstname = 'firstname',
  Lastname = 'lastname',
  Email = 'email',
  ReenterEmail = 'reenterEmail',
  Phone = 'phone',
  State = 'state',
  Birthdate = 'birthdate',
  Travellers = 'travellers',
  MedicalCondition1 = 'medicalCondition1',
  MedicalCondition2 = 'medicalCondition2',
  MedicalCondition3 = 'medicalCondition3',
  MedicalDisclaimer = 'medicalDisclaimer',
  MedicalDisclaimerToggle = 'medicalDisclaimerToggle',
  MedicalAssessmentToggle = 'medicalAssessmentToggle',
  AutomaticallyCoveredConditions = 'automaticallyCoveredConditions',
}

export interface FormQuotationInformationPersonalData {
  [FormQuotationInformationEnum.Title]: string;
  [FormQuotationInformationEnum.Firstname]: string;
  [FormQuotationInformationEnum.Lastname]: string;
  [FormQuotationInformationEnum.Email]: string;
  [FormQuotationInformationEnum.ReenterEmail]?: string;
  [FormQuotationInformationEnum.Phone]: string;
  [FormQuotationInformationEnum.State]: string;
  [FormQuotationInformationEnum.Birthdate]: string;
}

export interface FormQuotationInformationMedicalData {
  [FormQuotationInformationEnum.MedicalCondition1]?: string;
  [FormQuotationInformationEnum.MedicalCondition2]?: string;
  [FormQuotationInformationEnum.MedicalCondition3]?: string;
  [FormQuotationInformationEnum.MedicalDisclaimer]: boolean;
  [FormQuotationInformationEnum.MedicalDisclaimerToggle]: string;
  [FormQuotationInformationEnum.AutomaticallyCoveredConditions]: string;
  [FormQuotationInformationEnum.MedicalAssessmentToggle]: string;
}

export enum FormQuotationInformationTravellerEnum {
  Firstname = 'firstname',
  Lastname = 'lastname',
  Age = 'age',
  BirthDate = 'birthDate',
  IsPolicyHolderTravelling = 'isPolicyHolderTravelling',
}

export interface FormQuotationInformationTraveller {
  [FormQuotationInformationTravellerEnum.Firstname]: string;
  [FormQuotationInformationTravellerEnum.Lastname]: string;
  [FormQuotationInformationTravellerEnum.Age]?: string;
  [FormQuotationInformationTravellerEnum.BirthDate]?: string;
  [FormQuotationInformationTravellerEnum.IsPolicyHolderTravelling]?: boolean;
}

export interface FormQuotationInformation
  extends FormQuotationInformationPersonalData,
    FormQuotationInformationMedicalData {
  [FormQuotationInformationEnum.Travellers]: FormQuotationInformationTraveller[];
}

export enum FormQuotationInformationErrorEnum {
  Title = 'titleError',
  Firstname = 'firstnameError',
  Lastname = 'lastnameError',
  Email = 'emailError',
  ReenterEmail = 'reenterEmailError',
  Phone = 'phoneError',
  Address = 'addressError',
  City = 'cityError',
  State = 'stateError',
  Postcode = 'postcodeError',
  Birthdate = 'birthdateError',
  Age = 'ageError',
  MedicalCondition2 = 'medicalCondition2Error',
  MedicalCondition3 = 'medicalCondition3Error',
  FormError = 'formError',
}

export interface FormQuotationInformationForm extends FormQuotationInformation {
  [FormQuotationInformationErrorEnum.Title]?: boolean;
  [FormQuotationInformationErrorEnum.Firstname]?: boolean;
  [FormQuotationInformationErrorEnum.Lastname]?: boolean;
  [FormQuotationInformationErrorEnum.Email]?: boolean;
  [FormQuotationInformationErrorEnum.ReenterEmail]?: boolean;
  [FormQuotationInformationErrorEnum.Phone]?: boolean;
  [FormQuotationInformationErrorEnum.Address]?: boolean;
  [FormQuotationInformationErrorEnum.City]?: boolean;
  [FormQuotationInformationErrorEnum.State]?: boolean;
  [FormQuotationInformationErrorEnum.Postcode]?: boolean;
  [FormQuotationInformationErrorEnum.Birthdate]?: boolean;
  [FormQuotationInformationErrorEnum.Age]?: boolean;
  [FormQuotationInformationErrorEnum.MedicalCondition2]?: boolean;
  [FormQuotationInformationErrorEnum.MedicalCondition3]?: boolean;
  [FormQuotationInformationErrorEnum.FormError]?: boolean;
}

export const FormInformationPersonalInformation: FormQuotationInformationEnum[] =
  [
    FormQuotationInformationEnum.Title,
    FormQuotationInformationEnum.Firstname,
    FormQuotationInformationEnum.Lastname,
    FormQuotationInformationEnum.Email,
    FormQuotationInformationEnum.Phone,
    FormQuotationInformationEnum.State,
    FormQuotationInformationEnum.Birthdate,
  ];

export const FormInformationMedicalData: FormQuotationInformationEnum[] = [
  FormQuotationInformationEnum.MedicalCondition1,
  FormQuotationInformationEnum.MedicalCondition2,
];

export interface FormQuotationInformationTravellerList {
  travellerAge0?: string;

  travellerAge1?: string;
  travellerFirstName1?: string;
  travellerLastName1?: string;

  travellerAge2?: string;
  travellerFirstName2?: string;
  travellerLastName2?: string;

  travellerAge3?: string;
  travellerFirstName3?: string;
  travellerLastName3?: string;

  travellerAge4?: string;
  travellerFirstName4?: string;
  travellerLastName4?: string;

  travellerAge5?: string;
  travellerFirstName5?: string;
  travellerLastName5?: string;

  travellerAge6?: string;
  travellerFirstName6?: string;
  travellerLastName6?: string;

  travellerAge7?: string;
  travellerFirstName7?: string;
  travellerLastName7?: string;

  travellerAge8?: string;
  travellerFirstName8?: string;
  travellerLastName8?: string;

  travellerAge9?: string;
  travellerFirstName9?: string;
  travellerLastName9?: string;

  travellerAge10?: string;
  travellerFirstName10?: string;
  travellerLastName10?: string;
}

export interface MedicalDeclarationAndExtraQuestion {
  orderId: number;
  value: string;
  level: number;
  question: string;
}
