import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
  useCallback,
} from 'react';
import { AxiosResponse } from 'axios';
import {
  BookingConfigEnum,
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from '../../components/BookingWidget/bookingSteps.interface';
import { BookingContext } from '../bookingContext/bookingContext';
import { LoadingContext } from '../loadingContext/loadingContext';
import { BookingDataContext } from '../bookingDataContext/bookingDataContext';
import useApi from '../../shared/services/api.service';
import { BookingConfigContext } from '../bookingConfigContext/bookingConfigContext';
import { NotificationContext } from '../../shared/components/Notification/NotificationContext';
import { useSessionContext } from '../sessionContext/sessionContext';

interface DiscountContextType {
  isDiscountDialog: boolean;
  isPercentageDiscount: boolean;
  setIsDiscountDialog: React.Dispatch<React.SetStateAction<boolean>>;
  discountStatus: string;
  discountAmount: string;
  selectedDiscountReason: string;
  getDiscountStatusHandler: () => void;
  setDiscountStatus: React.Dispatch<React.SetStateAction<string>>;
  setSelectedDiscountReason: React.Dispatch<React.SetStateAction<string>>;
  setIsPercentageDiscount: React.Dispatch<React.SetStateAction<boolean>>;
  setDiscountAmount: React.Dispatch<React.SetStateAction<string>>;
  removeDiscount: () => void;
}

const DiscountContext = createContext<DiscountContextType | undefined>(
  undefined,
);

export const DiscountProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  // context
  const { bookingDataResponse } = useContext(BookingDataContext);
  const { update } = useContext(BookingContext);
  const { bookingConfigData } = useContext(BookingConfigContext);
  // util
  const { setIsLoading } = useContext(LoadingContext);
  const { showNotification } = useContext(NotificationContext);
  const apiCM360Url: string =
    bookingConfigData[BookingConfigEnum.DataCenter]?.cm360Endpoint;
  const { sessionParams } = useSessionContext();
  const API = useApi(bookingConfigData, sessionParams);
  // state
  const [isDiscountDialog, setIsDiscountDialog] = useState(false);
  const [discountStatus, setDiscountStatus] = useState('');
  const [selectedDiscountReason, setSelectedDiscountReason] = useState('');
  const [discountAmount, setDiscountAmount] = useState('');
  const [isPercentageDiscount, setIsPercentageDiscount] = useState(false);

  const getDiscountStatusHandler = (): void => {
    setIsLoading(true);
    const bookingId = (bookingDataResponse as any).bookingData.id;
    const url = `${apiCM360Url}/booking/${bookingId}/discount`;
    API.get(url)
      .then((res: AxiosResponse) => {
        if (res.status !== 201) {
          showNotification('unexpectedError', 'error', false);
          setIsLoading(false);
        } else {
          setDiscountStatus(res.data.status);
          if (res.data.status === 'APPROVED') {
            update(
              {
                selectedDiscountReason,
                discountAmount,
                isPercentageDiscount,
                discountStatus: res.data.status,
              },
              BookingStepValueEnum.QuotationProposals,
              BookingSubStepValueEnum.AgentsDiscount,
              true,
              undefined,
              false,
              () => setIsLoading(false),
            );
          } else {
            setIsLoading(false);
          }
        }
      })
      .catch(() => {
        showNotification('unexpectedError', 'error', false);
        setIsLoading(false);
      });
  };

  const removeDiscount = useCallback(() => {
    update(
      null,
      BookingStepValueEnum.QuotationProposals,
      BookingSubStepValueEnum.AgentsDiscount,
      false,
      undefined,
      false,
      () => setIsLoading(false),
    );
  }, [update]);

  useEffect(() => {
    const discountValue =
      bookingDataResponse?.bookingData?.discountAmount ||
      bookingDataResponse?.bookingData?.discountPercentage;
    if (discountValue) {
      setDiscountAmount(discountValue);
      setIsPercentageDiscount(
        !!bookingDataResponse?.bookingData?.discountPercentage,
      );
      if (bookingDataResponse.bookingData.isDiscountApproved) {
        setDiscountStatus('APPROVED');
      } else {
        getDiscountStatusHandler();
      }
    }
  }, [bookingDataResponse]);

  return (
    <DiscountContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isDiscountDialog,
        selectedDiscountReason,
        setIsPercentageDiscount,
        setDiscountAmount,
        setIsDiscountDialog,
        discountAmount,
        discountStatus,
        isPercentageDiscount,
        setDiscountStatus,
        setSelectedDiscountReason,
        getDiscountStatusHandler,
        removeDiscount,
      }}
    >
      {children}
    </DiscountContext.Provider>
  );
};

export const useDiscountDialog = (): DiscountContextType => {
  const context: DiscountContextType | undefined = useContext(DiscountContext);
  if (context === undefined) {
    throw new Error('useDiscountDialog must be used within a DiscountProvider');
  }
  return context;
};
