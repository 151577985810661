import { BookingStepValueEnum } from '../../components/BookingWidget/bookingSteps.interface';
import {
  BookingStepsContextState,
  BookingSubStepOptionType,
} from '../../context/bookingContext/bookingContext.types';
import {
  AddonsPossibleValues,
  CancellationLimitPossibleValues,
  OptionsPossibleValues,
} from '../consts/optionsAndAddons.const';

const prepareAddons = (input: {
  [key: string]: boolean | undefined;
}): string => {
  let finalOptions = '';
  if (!input) {
    return finalOptions;
  }
  AddonsPossibleValues.forEach((value) => {
    finalOptions += input[value] ? `${value}|` : '|';
  });
  if (finalOptions.endsWith('|')) {
    finalOptions = finalOptions.slice(0, -1);
  }
  return finalOptions;
};

const prepareOptions = (input: {
  [key: string]: boolean | undefined;
}): string => {
  // HARDCODED VALUES
  let finalOptions = '';
  if (!input) {
    return finalOptions;
  }
  OptionsPossibleValues.forEach((value) => {
    finalOptions += input[value] ? `${value}|` : '|';
  });
  if (finalOptions.endsWith('|')) {
    finalOptions = finalOptions.slice(0, -1);
  }
  return finalOptions;
};

const insertAddonsIntoObject = (input: string): { [key: string]: boolean } => {
  const options: { [key: string]: boolean } = {};
  if (!input) {
    return options;
  }
  const optionArray = input.split('|');
  AddonsPossibleValues.forEach((option): void => {
    options[option] = optionArray.includes(option);
  });
  return options;
};

const insertOptionsIntoObject = (input: string): { [key: string]: boolean } => {
  const options: { [key: string]: boolean } = {};
  if (!input) {
    return options;
  }

  const optionArray = input.split('|');
  OptionsPossibleValues.forEach((option, index): void => {
    options[option] = optionArray[index] !== '';
  });
  return options;
};

const findTrueKey = (obj: {
  [key: string]: boolean | undefined;
}): string | undefined => {
  const keys = Object.keys(obj);
  return keys.find((key) => obj[key] === true);
};

const setTrueForKey = (
  obj: { [key: string]: boolean | undefined },
  trueKey: string,
): { [key: string]: boolean | undefined } => {
  const updatedObj = { ...obj };
  Object.keys(updatedObj).forEach((key) => {
    updatedObj[key] = key === trueKey;
  });
  return updatedObj;
};

const getPartnerCode = (bookingSteps: BookingStepsContextState): string => {
  if (bookingSteps[BookingStepValueEnum.AffiliateId]) {
    return bookingSteps[BookingStepValueEnum.AffiliateId];
  }
  if (bookingSteps[BookingStepValueEnum.AggregatorId]) {
    return bookingSteps[BookingStepValueEnum.AggregatorId];
  }
  return '';
};

const prepareDestinationCountryList = (
  options: BookingSubStepOptionType[],
): string =>
  options.map((option: BookingSubStepOptionType) => option.value).join(',');

export {
  insertOptionsIntoObject,
  insertAddonsIntoObject,
  prepareAddons,
  prepareOptions,
  prepareDestinationCountryList,
  findTrueKey,
  setTrueForKey,
  getPartnerCode,
};
