import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  ClickAwayListener,
  Collapse,
  Divider,
  Typography,
  useTheme,
} from '@mui/material';

import useMediaQuery from '@mui/material/useMediaQuery';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { BookingContext } from '../../context/bookingContext/bookingContext';
import '../QuotationsBlockShare/QuotationSummaryBlock.scss';
import './QuotationSummaryBlock.scss';

import { BookingQuoteContext } from '../../context/quoteContext/quoteContext';
import { CacheDataContext } from '../../context/cacheContext/cacheContext';
import { BookingQuoteResponseEnum } from '../BookingWidget/BookingQuoteManagment/bookingQuoteResponse.interface';
import useFlowSpecificFactory from '../QuotationRecapDeclarations/helpers/useFlowSpecificFactory';
import getUrlSearchParam from '../../shared/helpers/urlSearchParams.helper';
import { useDiscountDialog } from '../../context/discountContext/discountContext';
import QuoteCopy from './QuoteCopy/QuoteCopy';
import PriceSummarySection from './PriceSummarySection/PriceSummarySection';
import DiscountDialog from './DiscountDialog/DiscountDialog';

export interface QuotationSummaryBlockProps {
  nextStep: (step?: number) => void;
}

// eslint-disable-next-line react/function-component-definition
const QuotationSummaryBlock: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(!isMobile);
  const { bookingQuoteResponse } = useContext(BookingQuoteContext);
  const { store } = useContext(CacheDataContext);
  const { summaryBlockData } = useFlowSpecificFactory();

  const urlParams: URLSearchParams = new URLSearchParams(
    window.location.search,
  );
  const bookingType: string | null = getUrlSearchParam(
    'bookingType',
    urlParams,
  );

  const shouldShowQuoteCopy = useMemo(
    () =>
      (summaryBlockData.data ||
        bookingQuoteResponse?.[BookingQuoteResponseEnum.Quotes]?.[0]) &&
      (!bookingType || bookingType !== 'simplified') &&
      store?.currentStep !== 2,
    [
      summaryBlockData.data,
      bookingQuoteResponse,
      bookingType,
      store?.currentStep,
    ],
  );

  const handleCollapseClick = (
    event: React.MouseEvent<HTMLDivElement>,
  ): void => {
    if (isMobile) {
      setOpen(!open);
    }
  };

  const handleCollapseKey = (
    event: React.KeyboardEvent<HTMLDivElement>,
  ): void => {
    if (isMobile) {
      if (event.key === 'ArrowUp') {
        setOpen(false);
      } else if (event.key === 'ArrowDown') {
        setOpen(true);
      }
    }
  };

  return (
    <div data-testid="QuotationSummaryBlock" data-cy="bookingW_Quote_section">
      <Card className={isMobile ? 'summary-mobile' : 'summary-desktop'}>
        <CardContent
          sx={{
            padding: '0.2rem 0.4rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '.2rem',
          }}
        >
          <div
            className="summary-mobile-title"
            role="button"
            tabIndex={0}
            onClick={handleCollapseClick}
            onKeyDown={handleCollapseKey}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '1.25rem',
                lineHeight: '1.5rem !important',
              }}
              gutterBottom
            >
              {summaryBlockData.label}
            </Typography>
          </div>
          {shouldShowQuoteCopy && <QuoteCopy />}
          <Divider sx={{ mb: 1 }} />
          <PriceSummarySection />
        </CardContent>
      </Card>
      <DiscountDialog />
    </div>
  );
};

export default QuotationSummaryBlock;
