import React, { createContext, useContext, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import { BookingDataResponse } from '../../components/BookingWidget/BookingManagment/bookingManagment.interface';
import { BookingDataContextState } from './bookingDataContext.types';
import { BookingConfigEnum } from '../../components/BookingWidget/bookingSteps.interface';
import { BookingConfigContext } from '../bookingConfigContext/bookingConfigContext';
import useApi from '../../shared/services/api.service';
import { useSessionContext } from '../sessionContext/sessionContext';

const contextDefaultValues: BookingDataContextState = {
  bookingDataResponse: null,
  updateBookingData: (bookingDataResponse: BookingDataResponse): void =>
    undefined,
  reloadBooking: () => null,
};
export const BookingDataContext =
  createContext<BookingDataContextState>(contextDefaultValues);

export const BookingDataContextProvider: React.FC<
  React.PropsWithChildren<object>
> = ({ children }) => {
  const { bookingConfigData } = useContext(BookingConfigContext);
  const { sessionParams } = useSessionContext();
  const API = useApi(bookingConfigData, sessionParams);
  const [bookingDataResponse, setBookingData] =
    useState<BookingDataResponse | null>(
      contextDefaultValues.bookingDataResponse,
    );

  const handleUpdate = (bookingData: BookingDataResponse): void => {
    setBookingData(bookingData);
  };

  const reloadBooking = (): void => {
    const apiCM360Url: string =
      bookingConfigData[BookingConfigEnum.DataCenter]?.cm360Endpoint;
    const url = `${apiCM360Url}/booking/${bookingDataResponse?.bookingData.id}`;

    API.get(url).then((bookingRes: AxiosResponse) => {
      if (bookingRes.status === 200) {
        handleUpdate(bookingRes.data);
      }
    });
  };

  const bookingData: BookingDataContextState = useMemo(
    () => ({
      bookingDataResponse,
      updateBookingData: handleUpdate,
      reloadBooking,
    }),
    [bookingDataResponse],
  );

  return (
    <BookingDataContext.Provider value={bookingData}>
      {children}
    </BookingDataContext.Provider>
  );
};
