import { EventEmitter } from 'events';

const eventEmitter = new EventEmitter();

export enum AppEvents {
  SAVE_PERSONAL_INFORMATION_FORM = 'SAVE_PERSONAL_INFORMATION_FORM',
  PERSONAL_INFORMATION_SAVED = 'PERSONAL_INFORMATION_SAVED',
  ON_NAVIGATION_BACK = 'ON_NAVIGATION_BACK',
  ON_NAVIGATION_NEXT = 'ON_NAVIGATION_NEXT',
  ON_HELIO_BTN_CLICKED = 'ON_HELIO_CLICKED',
  ON_PERSONAL_FORM_VALIDATED = 'ON_PERSONAL_FORM_VALIDATED',
}

type func<TData> = (data?: TData) => void;

export interface AppEventHandlerBlueprint<TData = object> {
  subscribe: (cb: func<TData>) => EventEmitter;
  emit: (data?: TData) => boolean;
  unsubscribe: (cb: func<TData>) => EventEmitter;
}
export const subscribe$ = <TData = object>(
  eventName: AppEvents,
  callback: func<TData>,
): EventEmitter => eventEmitter.on(eventName, callback);

export const emit$ = <TData = object>(
  eventName: AppEvents,
  data?: TData,
): boolean => eventEmitter.emit(eventName, data);

export const unsubscribe$ = <TData = object>(
  eventName: AppEvents,
  callback: func<TData>,
): EventEmitter => eventEmitter.off(eventName, callback);

export const createAppEventHandler = <TData = object>(
  eventName: AppEvents,
): AppEventHandlerBlueprint<TData> => ({
  subscribe: (callback: func<TData>) => subscribe$(eventName, callback),
  emit: (data?: TData) => emit$(eventName, data),
  unsubscribe: (callback: func<TData>) => unsubscribe$(eventName, callback),
});
