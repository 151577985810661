import React, { FC, useContext } from 'react';
import { Box, Step, StepLabel, Stepper, StepConnector } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  BookingConfigEnum,
  BookingStepEnum,
} from '../BookingWidget/bookingSteps.interface';
import { BookingConfigContext } from '../../context/bookingConfigContext/bookingConfigContext';
import './ProgressStepper.scss';
import { StepperContext } from '../../context/stepperContext/stepperContext';
import getStepName from './getStepName';
import isWhitelabel from '../../shared/helpers/whitelabel';

export interface ProgressStepperProps {
  keyName: string;
  onBeforeStepChanged?: () => void;
}

const ProgressStepper: FC<ProgressStepperProps> = (props) => {
  const { keyName, onBeforeStepChanged } = props;
  const { bookingConfigData } = useContext(BookingConfigContext);
  const { goToStep } = useContext(StepperContext);
  const { t } = useTranslation();
  const whiteLabel = isWhitelabel(bookingConfigData);
  const activeStep = bookingConfigData[
    BookingConfigEnum.BookingSteps
  ].findIndex((s) => s[BookingStepEnum.KeyName] === keyName);
  const isLastStep =
    activeStep + 1 === bookingConfigData[BookingConfigEnum.BookingSteps].length;

  const activeStepIndex = activeStep - 1;

  const clickHandler = (index: number): void => {
    // +1 because BookingWidget start steps numeration from 1
    const newIndex = index + 1;

    if (onBeforeStepChanged) onBeforeStepChanged();
    goToStep(newIndex);
  };

  return (
    <Box
      sx={{ width: '100%', marginTop: 3 }}
      className={`progress-stepper ${whiteLabel ? 'whitelabel' : ''}`}
      data-cy="bookingW_ProgressBar"
    >
      <Stepper
        activeStep={activeStepIndex}
        alternativeLabel
        connector={whiteLabel ? null : <StepConnector />}
      >
        {bookingConfigData[BookingConfigEnum.BookingSteps]
          .slice(1)
          .map((step, i) => {
            const isPossibleGoToStep = i < activeStepIndex && !isLastStep;
            return isPossibleGoToStep ? (
              <Step
                key={step[BookingStepEnum.KeyName]}
                data-cy={`bookingW_ProgressBar_${i + 1}`}
                onClick={() => clickHandler(i)}
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                <StepLabel>{step[BookingStepEnum.Label]}</StepLabel>
              </Step>
            ) : (
              <Step
                key={step[BookingStepEnum.KeyName]}
                data-cy={`bookingW_ProgressBar_${i + 1}`}
              >
                <StepLabel>
                  {getStepName(
                    step[BookingStepEnum.KeyName],
                    t,
                    bookingConfigData,
                  )}
                </StepLabel>
              </Step>
            );
          })}
      </Stepper>
    </Box>
  );
};

ProgressStepper.defaultProps = {
  onBeforeStepChanged: undefined,
};

export default ProgressStepper;
