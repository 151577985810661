import {
  PricingPayload,
  PricingPayloadEnum,
} from '../../components/QuotationSummaryBlock/pricingPayload.interfaces';
import { InsuranceEnum } from '../interfaces/insurance.interface';

const compareBookingHelper = (
  booking: PricingPayload,
  bookingToCompare: PricingPayload,
): boolean => {
  if (!booking || !bookingToCompare) return false;
  const clonedBooking = JSON.parse(JSON.stringify(booking));
  const clonedBookingToCompare = { ...bookingToCompare };
  delete clonedBooking[PricingPayloadEnum.Insurance][InsuranceEnum.BookingDate];
  delete clonedBookingToCompare[PricingPayloadEnum.Insurance][
    InsuranceEnum.BookingDate
  ];
  return (
    JSON.stringify(clonedBooking) === JSON.stringify(clonedBookingToCompare)
  );
};

export default compareBookingHelper;
