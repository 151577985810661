import { useCallback, useContext, useEffect, useRef } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
// context
import { BookingConfigContext } from '../../../context/bookingConfigContext/bookingConfigContext';
import { BookingQuoteContext } from '../../../context/quoteContext/quoteContext';
import { BookingContext } from '../../../context/bookingContext/bookingContext';
import { BookingDataContext } from '../../../context/bookingDataContext/bookingDataContext';
import { NotificationContext } from '../../../shared/components/Notification/NotificationContext';
import { LoadingContext } from '../../../context/loadingContext/loadingContext';
import contextDefaultValues from '../../../context/bookingContext/bookingContext.const';

// interface
import {
  BookingDataEnum,
  BookingDataResponse,
  BookingManagementEnum,
} from '../BookingManagment/bookingManagment.interface';
import mapToCreateQuoteResponse from '../BookingQuoteManagment/bookingQuoteMaper';
import {
  BookingCatalog,
  BookingConfigEnum,
  BookingStepValueEnum,
} from '../bookingSteps.interface';
import { BookingStepsContextState } from '../../../context/bookingContext/bookingContext.types';
// util
import useApi from '../../../shared/services/api.service';
import getUrlSearchParam from '../../../shared/helpers/urlSearchParams.helper';
import useQuoteDataService from '../../../shared/services/quoteData.service';
import useBookingProductSelection from './useBookingProductSelection';
import {
  BookingQuoteResponseQuoteProduct,
  BookingQuoteResponseQuoteProductEnum,
} from '../../QuotationProposals/components/quotationProposal.interface';
import ErrorLoggingService from '../../../shared/services/errorlogging.service';
import axiosErrorToTranslationKey from '../../../shared/helpers/axiosErrorToTranslationKey.helper';
import { useSessionContext } from '../../../context/sessionContext/sessionContext';
import { isDateBeforeNow } from '../../../shared/helpers/date.helper';

type UseBookingResume = {
  resumeBooking: () => void;
};

type UseBookingResumeProps = {
  setResumedStep: (stepName: string) => void;
};

const useBookingResume = ({
  setResumedStep,
}: UseBookingResumeProps): UseBookingResume => {
  // context
  const { bookingConfigData } = useContext(BookingConfigContext);
  const { bookingSteps } = useContext(BookingContext);
  const { loadQuote, loadBooking, update } = useContext(BookingContext);
  const { bookingQuoteResponse, updateQuote } = useContext(BookingQuoteContext);
  const { updateBookingData } = useContext(BookingDataContext);
  const { showNotification } = useContext(NotificationContext);
  const { setIsLoading } = useContext(LoadingContext);
  // ref
  const latestBookingSteps = useRef<BookingStepsContextState>(
    contextDefaultValues.bookingSteps,
  );
  // service
  const { getQuote } = useQuoteDataService({
    bookingConfigData,
    bookingQuoteResponse,
  });
  // util
  const { sessionParams } = useSessionContext();
  const API = useApi(bookingConfigData, sessionParams);
  const { selectProduct } = useBookingProductSelection();
  const errorService: ErrorLoggingService = ErrorLoggingService.getInstance();
  // data
  const urlParams: URLSearchParams = new URLSearchParams(
    window.location.search,
  );
  const productCode: string | null = getUrlSearchParam(
    'productCode',
    urlParams,
  );
  let quoteId: string | null = getUrlSearchParam('quoteId', urlParams);
  const externalBookingId: string | null = getUrlSearchParam(
    'externalBookingId',
    urlParams,
  );
  const bookingDataId: string | null = getUrlSearchParam(
    'bookingDataId',
    urlParams,
  );
  const accessTokenParam: string | null = getUrlSearchParam('token', urlParams);
  const apiCM360Url: string =
    bookingConfigData[BookingConfigEnum.DataCenter]?.cm360Endpoint;

  useEffect((): void => {
    latestBookingSteps.current = bookingSteps;
  }, [bookingSteps]);

  const getExternalBooking = async (id: string): Promise<any> => {
    // @TODO mock data, should be from different endpoint - currently not known
    const contextToBeUpdated = {
      tripDetails: {
        value: 'FCTG',
        label: '',
      },
      geographicalZone: {
        value: 'DOMESTIC',
        label: 'Domestic',
      },
      departureDate: '2024-06-29T13:25:48.000Z',
      returnDate: '2024-07-31T13:25:48.000Z',
      travellersAge: ['33'],
      maxTripDuration: {
        value: '',
        label: '',
      },
    };
    return contextToBeUpdated;
  };

  const loadExternalBookingData = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    const bookingResponse = await getExternalBooking(externalBookingId!);
    // @TODO skip for now, we need to know how get this booking
    // update(bookingResponse, BookingStepValueEnum.QuotationForm);
    setResumedStep('quotationProposals');
    setIsLoading(false);
    // handleNext();
  }, []);

  const getBooking = useCallback(
    async (
      id: string,
    ): Promise<{
      bookingResponse: BookingDataResponse;
      quoteToken: string;
    } | void> => {
      try {
        let url = `${apiCM360Url}/booking/${id}`;
        const secret: string | null = getUrlSearchParam('secret', urlParams);

        if (secret) {
          url = url.concat(`?secret=${secret}`);
        }

        const bookingRes: AxiosResponse = await API.get(url);
        if (bookingRes.status !== 200) {
          showNotification('unexpectedError', 'error', false);
        }
        if (bookingRes.headers['x-amzn-remapped-authorization']) {
          localStorage.setItem(
            'token',
            bookingRes.headers['x-amzn-remapped-authorization'].replace(
              'Bearer ',
              '',
            ),
          );
        }
        const { catalog: bookingCatalog, startDate } =
          bookingRes.data?.[BookingManagementEnum.BookingData] || {};

        if (bookingCatalog) {
          const catalogExists = bookingConfigData[
            BookingConfigEnum.Catalogs
          ].filter(
            (catalog: BookingCatalog) => catalog.code === bookingCatalog,
          );
          if (catalogExists.length === 0) {
            throw new Error('configurationMismatch');
          }
        }

        return {
          bookingResponse: bookingRes.data,
          quoteToken: bookingRes.headers['x-amzn-remapped-authorization'],
        };
      } catch (e) {
        setIsLoading(false);

        if (e instanceof AxiosError && !!e.response) {
          if (e.response.status === 404) {
            return showNotification('expiredLink', 'error', false);
          }
        }
        if (e instanceof Error && e.message === 'configurationMismatch') {
          return showNotification('configurationMismatch', 'error', false);
        }
        return showNotification('unexpectedError', 'error', false);
      }
    },
    [API, apiCM360Url, setIsLoading, showNotification],
  );

  const loadSavedData = useCallback(async () => {
    setIsLoading(true);
    const id = quoteId || bookingDataId;
    const bookingResponseData = await getBooking(id!);
    if (bookingResponseData && bookingResponseData.bookingResponse) {
      const { bookingResponse, quoteToken } = bookingResponseData;
      if (
        bookingResponse[BookingManagementEnum.BookingData][
          BookingDataEnum.CurrentStep
        ] === 'emptyBooking'
      ) {
        if (productCode) {
          const response: BookingQuoteResponseQuoteProduct =
            await selectProduct(id!, productCode, true);
          quoteId =
            response[BookingQuoteResponseQuoteProductEnum.PSResponsePayload]
              .quoteId;
          update(
            {
              ...bookingSteps[BookingStepValueEnum.QuotationProposals],
              proposal: response[BookingQuoteResponseQuoteProductEnum.Product],
            },
            BookingStepValueEnum.QuotationProposals,
          );
        }
        bookingResponse[BookingManagementEnum.BookingData][
          BookingDataEnum.CurrentStep
        ] = 'quotationProposals';
      }
      updateBookingData(bookingResponse);
      const latestQuoteId =
        bookingResponse[BookingManagementEnum.BookingData][
          BookingDataEnum.Proxy
        ]?.quoteId ?? quoteId;
      if (latestQuoteId) {
        let quote;

        try {
          quote = await getQuote({
            id: latestQuoteId,
            bookingResponse,
            quoteToken,
          });
        } catch (error: any) {
          if (axios.isAxiosError(error)) {
            const translationKey = axiosErrorToTranslationKey(error);
            showNotification(translationKey, 'error', false);
          } else {
            showNotification(error.message, 'error', true);
          }
          setIsLoading(false);
        }

        if (!quote) return;

        const { quoteResult, medicalConditionsResult } = quote;

        if (
          bookingResponse[BookingManagementEnum.Companions]?.length !==
          quoteResult.beneficiaries.length
        ) {
          errorService.log(
            `[useBookingResume] Mismatch: Companions count (${bookingResponse[
              BookingManagementEnum.Companions
            ]?.length}) does not match beneficiaries count (${
              quoteResult.beneficiaries.length
            })`,
          );
        }
        updateQuote(
          mapToCreateQuoteResponse(
            latestQuoteId,
            bookingResponse[BookingManagementEnum.BookingData][
              BookingDataEnum.Proxy
            ]?.policyId ?? '',
            quoteResult,
            accessTokenParam,
          ),
        );

        try {
          loadQuote({
            quote: quoteResult,
            medicalConditions: medicalConditionsResult,
            consents: bookingResponse.consents,
            bookingResponse,
            latestBookingSteps: latestBookingSteps.current,
          });
        } catch (error: any) {
          errorService.log('[useBookingResume] Error loading quote:', error);
          showNotification('unexpectedError', 'error', false);
          setIsLoading(false);
          return;
        }
      } else {
        loadBooking(bookingResponse);
      }
      setResumedStep(bookingResponse.bookingData.currentStep);
      setIsLoading(false);
    }
  }, [
    accessTokenParam,
    bookingDataId,
    getBooking,
    getQuote,
    loadBooking,
    loadQuote,
    quoteId,
    setIsLoading,
    setResumedStep,
    updateBookingData,
    updateQuote,
  ]);

  const resumeBooking = useCallback(() => {
    if (bookingConfigData.bookingSteps.length) {
      if (externalBookingId) {
        loadExternalBookingData();
      } else if (quoteId || bookingDataId) {
        loadSavedData();
      } else {
        setResumedStep(bookingSteps[BookingStepValueEnum.CurrentStep]);
      }
    }
  }, [
    bookingConfigData.bookingSteps.length,
    bookingDataId,
    bookingSteps,
    externalBookingId,
    loadExternalBookingData,
    loadSavedData,
    quoteId,
    setResumedStep,
  ]);

  return {
    resumeBooking,
  };
};

export default useBookingResume;
