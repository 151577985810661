import getUrlSearchParam from './urlSearchParams.helper';
import {
  BookingConfig,
  BookingConfigEnum,
} from '../../components/BookingWidget/bookingSteps.interface';

export const getUrlPlatformAndCatalog = (
  bookingConfigData: BookingConfig,
): string => {
  const { psPlatform, catalogGroup } =
    bookingConfigData[BookingConfigEnum.Channel];
  if (!psPlatform || !catalogGroup) {
    throw new Error('Platform or catalog group not found');
  }
  return `platforms/${psPlatform}/catalogs/${catalogGroup}`;
};

export const getUrlPsClient = (bookingConfigData: BookingConfig): string => {
  const { psClient } = bookingConfigData[BookingConfigEnum.Channel];
  if (!psClient) {
    throw new Error('Client not found');
  }
  return psClient;
};
