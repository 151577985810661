import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';
import QuotationElementBlockPrice from '../../QuotationsBlockShare/QuotationPriceElement/QuotationElementBlockPrice';
import {
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from '../../BookingWidget/bookingSteps.interface';
import { FormQuotationInformationTravellerList } from '../../QuotationInformationForm/quoation-information.interface';
import { usePriceSummaryLoading } from '../../../context/priceSummaryContext/priceSummaryContext';
import { BookingContext } from '../../../context/bookingContext/bookingContext';
import { TravellerAgeAndBirthdate } from '../../QuotationFormWeb/quotationFormWeb.types';
import QuotationElementPriceRow from '../../QuotationsBlockShare/QuotationPriceElement/QuotationElementPriceRow';
import useFinishMedicalScreening from '../../QuotationInformationForm/MedicalScreeningInformation/useFinishMedicalScreening';

interface PriceSummaryTraveller {
  name: string;
  medicalCost: string | null;
  isExcluded: boolean;
}

const PriceSummaryMedical: FC = () => {
  const { t } = useTranslation();
  const { bookingSteps } = useContext(BookingContext);
  const { isMedicalScreeningComplete } = useFinishMedicalScreening();
  const { medicalBreakdown, medicalConditionsCovered } =
    usePriceSummaryLoading();
  const [summaryTravelers, setSummaryTravelers] = useState<
    PriceSummaryTraveller[]
  >([]);
  const buildTravelerListWithMam = (
    travellers: TravellerAgeAndBirthdate[],
    travellerInfoList: FormQuotationInformationTravellerList,
  ): PriceSummaryTraveller[] =>
    travellers.map((traveller, index) => {
      const firstNameKey =
        `travellerFirstName${index}` as keyof FormQuotationInformationTravellerList;
      const lastNameKey =
        `travellerLastName${index}` as keyof FormQuotationInformationTravellerList;

      const firstname = travellerInfoList?.[firstNameKey] || '';
      const lastname = travellerInfoList?.[lastNameKey] || '';

      let name = `Traveler ${index + 1}`;
      if (firstname && lastname) {
        name = `${firstname} ${lastname.charAt(0)}.`;
      }
      const medicalCost = medicalBreakdown[index] || null;

      const isExcluded = medicalConditionsCovered[index] === 'S';

      return {
        name,
        medicalCost,
        isExcluded,
      };
    });

  useEffect(() => {
    const travellersAge: TravellerAgeAndBirthdate[] =
      bookingSteps[BookingStepValueEnum.QuotationForm][
        BookingSubStepValueEnum.TravellersAge
      ];
    const travellersInfoList: FormQuotationInformationTravellerList =
      bookingSteps[BookingStepValueEnum.QuotationInformation][
        BookingSubStepValueEnum.InformationTravellers
      ];
    if (medicalBreakdown.length && travellersAge.length > 0) {
      const travelerList: PriceSummaryTraveller[] = buildTravelerListWithMam(
        travellersAge,
        travellersInfoList,
      );
      setSummaryTravelers(travelerList);
    }
  }, [medicalBreakdown, bookingSteps, medicalConditionsCovered]);

  return (
    summaryTravelers.length > 0 &&
    isMedicalScreeningComplete && (
      <>
        <QuotationElementPriceRow
          label={t('BOOKING.QUOTATION_SUMMARY_BLOCK.medicalIncrease')}
          fontWeightLabel="bold"
          fontSizeLabel="0.875rem"
        />
        {summaryTravelers.map((traveler, index) => (
          <QuotationElementBlockPrice
            key={index}
            product={[
              {
                productName: traveler.name,
                amount: traveler.medicalCost || '0',
                isExcluded: traveler.isExcluded,
              },
            ]}
            fontWeightLabel="normal"
          />
        ))}
        <Divider sx={{ margin: '0.5rem 0' }} />
      </>
    )
  );
};

export default PriceSummaryMedical;
