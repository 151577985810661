import {
  BookingConfig,
  BookingConfigEnum,
  CurrencyEnum,
} from '../../../components/BookingWidget/bookingSteps.interface';

const getDecimalPlaces = (value: number): number => {
  const price = value.toFixed(20).toString().split('.')[1]?.length || 20;
  return Math.min(price, 20);
};

export default function getCurrencySymbol(
  bookingConfigData: BookingConfig,
  value: number,
): string {
  return new Intl.NumberFormat(
    bookingConfigData[BookingConfigEnum.Currency][CurrencyEnum.Locale],
    {
      style: 'currency',
      currency:
        bookingConfigData[BookingConfigEnum.Currency][CurrencyEnum.Currency],
      ...bookingConfigData[BookingConfigEnum.Currency][
        CurrencyEnum.FormatOptions
      ],
      minimumFractionDigits: 2,
      maximumFractionDigits: getDecimalPlaces(value),
    },
  ).format(value);
}
