const axiosErrorToTranslationKey = (error: any): string => {
  if (error.response) {
    switch (error.response.status) {
      case 400:
        return 'invalidRequestBody';
      case 401:
        return 'unauthorized';
      case 403:
        return 'forbidden';
      case 404:
        return 'notFound';
      case 405:
        return 'notAllowed';
      case 500:
        return 'internalServerError';
      default:
        return 'unexpectedError';
    }
  }
  return 'unexpectedError';
};

export default axiosErrorToTranslationKey;
