/* eslint-disable react/require-default-props */
import React from 'react';
import { Divider } from '@mui/material';
import {
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from '../../BookingWidget/bookingSteps.interface';
import { ProductEnum } from '../../QuotationProposals/useFetchProposalsConf';
import PromotionAppliedPrice from './PromotionAppliedPrice';
import PromotionNotAppliedPrice from './PromotionNotAppliedPrice';

export interface PromotionPriceProps {
  promoCode: string;
  price?: number;
  promoRate?: string;
  bookingSteps: any;
  t: (key: string) => string;
}

const PromotionPriceFactory: React.FC<PromotionPriceProps> = (props) => {
  const { promoCode, bookingSteps } = props;
  const promotion =
    bookingSteps[BookingStepValueEnum.QuotationProposals][
      BookingSubStepValueEnum.Proposal
    ]?.[ProductEnum.Price]?.promotion;
  return promoCode ? <PromotionAppliedPrice {...props} /> : '';
};

const PromotionPrice: React.FC<PromotionPriceProps> = (props) => {
  const { price, promoRate } = props;

  if (price === undefined || promoRate === undefined) return null;

  return <PromotionPriceFactory {...props} />;
};

export default PromotionPrice;
