import React from 'react';
import '../QuotationSummaryElements.scss';

export type QuotationElementInformationVariant = 'summary' | 'trip';

export interface QuotationElementInformationProps
  extends React.PropsWithChildren {
  label: string;
  elements?: string[];
  variant: QuotationElementInformationVariant;
}

const QuotationElementInformation: React.FC<
  QuotationElementInformationProps
> = ({ children, elements: rawElements, label, variant }) => {
  function parseElement(el: string): React.JSX.Element {
    return (
      <div key={el}>
        <span className="description">{el}</span>
      </div>
    );
  }

  function parseElements(): React.JSX.Element[] | undefined {
    return rawElements?.map((element) => parseElement(element));
  }

  return (
    <div className="summary-element-wrapper">
      <span className={['label', variant].join(' ')}>{label}</span>
      {children || parseElements()}
    </div>
  );
};

QuotationElementInformation.defaultProps = {
  elements: [],
};

export default QuotationElementInformation;
