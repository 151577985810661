import React, { createContext, useMemo, useState } from 'react';

type StepperContextProps = { children: React.ReactNode };
type goToStepFunction = (targetStep: number) => void;

export type StepperContextState = {
  goToStep: goToStepFunction;
  setGoToStep: (goToStep: goToStepFunction) => void;
};

const defaultValues: StepperContextState = {
  goToStep: (targetStep: number) => undefined,
  setGoToStep: (goToStep: goToStepFunction) => undefined,
};

export const StepperContext = createContext<StepperContextState>(defaultValues);

export const StepperContextProvider: React.FC<StepperContextProps> = ({
  children,
}: StepperContextProps) => {
  const [goToStep, setGoToStep] = useState<goToStepFunction>(
    () => defaultValues.goToStep,
  );

  const setGoToStepHandler = (goToStepFunc: goToStepFunction): void => {
    setGoToStep(() => goToStepFunc);
  };

  const value = useMemo(
    () => ({
      goToStep,
      setGoToStep: setGoToStepHandler,
    }),
    [goToStep],
  );
  return (
    <StepperContext.Provider value={value}>{children}</StepperContext.Provider>
  );
};
