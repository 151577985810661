import { createTheme } from '@mui/material/styles';
import { Theme } from '@mui/material';
import getFontName from './theme.helper';
import { BookingStyleColorsEnum } from '../shared/interfaces/bookingStyles.interface';

export const createAppThemeAPI = (paletteColors: any, brand: string): Theme => {
  const buttonPrimaryTextColor =
    brand !== 'igo'
      ? paletteColors[BookingStyleColorsEnum.LightPrimary]
      : paletteColors[BookingStyleColorsEnum.DarkPrimary];
  return createTheme({
    typography: {
      fontFamily: getFontName(brand),
    },
    palette: {
      primary: {
        main: paletteColors[BookingStyleColorsEnum.Primary],
      },
      secondary: {
        main: '#11cb5f',
      },
      info: {
        main: paletteColors[BookingStyleColorsEnum.DarkMiddle],
      },
      error: {
        main: '#ff0000',
      },
      text: {
        primary: paletteColors[BookingStyleColorsEnum.DarkPrimary],
        secondary: paletteColors[BookingStyleColorsEnum.DarkSecondary],
        disabled: '#b7b7b7',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            '&.MuiButton-containedPrimary': {
              backgroundColor: paletteColors[BookingStyleColorsEnum.Secondary], // your custom primary color for the button background
              color: buttonPrimaryTextColor, // your custom color for the button text
              zIndex: 20,
              '&:hover': {
                backgroundColor: paletteColors[BookingStyleColorsEnum.Primary], // your custom hover color for the button background
                color: buttonPrimaryTextColor, // your custom hover color for the button text
              },
              '&.contrast': {
                color: paletteColors[BookingStyleColorsEnum.ContrastPrimary],
              },
            },
            '&.MuiButton-containedSecondary': {
              backgroundColor: paletteColors[BookingStyleColorsEnum.Secondary], // your custom primary color for the button background
              color: buttonPrimaryTextColor, // your custom color for the button text
              '&:hover': {
                backgroundColor: paletteColors[BookingStyleColorsEnum.Primary], // your custom hover color for the button background
                color: buttonPrimaryTextColor, // your custom hover color for the button text
              },
            },
            // '&.MuiButton-outlined': {
            //   color: paletteColors.textPrimary, // your custom primary color for the button text
            // },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: paletteColors[BookingStyleColorsEnum.Secondary],
              color: paletteColors[BookingStyleColorsEnum.DarkPrimary],
            },
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              // Make sure to target .Mui-selected class for selected state
              backgroundColor: `${
                paletteColors[BookingStyleColorsEnum.Primary]
              } `,
              color: paletteColors[BookingStyleColorsEnum.DarkPrimary],
            },
            '&.root-MuiPickersDay-root.Mui-selected': {
              backgroundColor: '#d30000 ',
            },
            '&.Mui-disabled': {
              opacity: 0.4,
            },
            '&.MuiPickersDay': {
              root: {
                '&.Mui-selected': {
                  backgroundColor:
                    paletteColors[BookingStyleColorsEnum.Primary],
                  color: paletteColors[BookingStyleColorsEnum.LightPrimary],
                },
              },
            },
            '&.MuiToggleButton': {
              root: {
                '&.Mui-selected': {
                  backgroundColor:
                    paletteColors[BookingStyleColorsEnum.Primary],
                  color: paletteColors[BookingStyleColorsEnum.LightPrimary],
                },
              },
            },
          },
        },
      },
      // MuiPickersDay: {
      //   styleOverrides: {
      //     root: {
      //       '&.Mui-selected': {
      //         backgroundColor: 'red', // Your custom color for selected date
      //         color: 'white', // Text color for selected date
      //         '&:hover': {
      //           backgroundColor: 'darkred', // Your custom color for selected date on hover
      //         },
      //       },
      //     },
      //   },
      // },
      MuiLinearProgress: {
        styleOverrides: {
          bar: {
            backgroundColor: paletteColors[BookingStyleColorsEnum.Primary],
          },
          colorPrimary: {
            backgroundColor:
              paletteColors[BookingStyleColorsEnum.LightSecondary],
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: `${buttonPrimaryTextColor} `,
            },
          },
        },
      },
      MuiStepper: {
        styleOverrides: {
          root: {
            'text.MuiStepIcon-text': {
              fill: buttonPrimaryTextColor,
            },
            '.MuiSvgIcon-root.Mui-completed': {
              background: `radial-gradient(circle at 50%, ${buttonPrimaryTextColor} 40%, transparent 60%)`,
            },
          },
        },
      },
    },
  });
};
const createAppTheme = (paletteColors: any, brand: string): Theme => {
  const buttonPrimaryTextColor =
    brand !== 'igo' ? paletteColors.lightPrimary : paletteColors.darkPrimary;
  const theme = createTheme({
    typography: {
      fontFamily: getFontName(brand),
      body2: {
        fontWeight: 400,
        fontSize: '1rem',
      },
    },
    palette: {
      background: {
        default: 'transparent',
      },
      primary: {
        main: paletteColors.primary,
      },
      secondary: {
        main: '#11cb5f',
      },
      error: {
        main: '#ff0000',
      },
      text: {
        primary: paletteColors.darkPrimary,
        secondary: paletteColors.darkSecondary,
        disabled: '#b7b7b7',
      },
    },
  });
  return createTheme({
    typography: {
      fontFamily: getFontName(brand),
      body2: {
        fontWeight: 400,
        fontSize: '1rem',
      },
    },
    palette: {
      background: {
        default: 'transparent',
      },
      primary: {
        main: paletteColors.primary,
      },
      secondary: {
        main: '#11cb5f',
      },
      error: {
        main: '#ff0000',
      },
      text: {
        primary: paletteColors.darkPrimary,
        secondary: paletteColors.darkSecondary,
        disabled: '#b7b7b7',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              backgroundColor: paletteColors.darkSecondary,
              color: paletteColors.darkPrimary,
            },
            '&.Mui-disabled.alternate-disabled': {
              backgroundColor: '#cdcdcd',
              color: '#000',
            },
            borderRadius: '10px',
            '&.MuiButton-containedPrimary': {
              backgroundColor: paletteColors.primary, // your custom primary color for the button background
              color: buttonPrimaryTextColor, // your custom color for the button text
              '&:hover': {
                backgroundColor: paletteColors.primary, // your custom hover color for the button background
                color: buttonPrimaryTextColor, // your custom hover color for the button text
              },
            },
            '&.MuiButton-containedSecondary': {
              backgroundColor: paletteColors.secondary, // your custom primary color for the button background
              color: buttonPrimaryTextColor, // your custom color for the button text
              '&:hover': {
                backgroundColor: paletteColors.secondary, // your custom hover color for the button background
                color: buttonPrimaryTextColor, // your custom hover color for the button text
              },
            },
            '&.MuiButton-outlinedPrimary:hover': {
              borderColor: 'var(--primary)',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: paletteColors.secondary,
              color: paletteColors.lightPrimary,
            },
            '& input::placeholder': {
              fontSize: '1rem',
              fontWeight: 400,
              lineHeight: '1.5rem',
              opacity: 1,
            },
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              // Make sure to target .Mui-selected class for selected state
              backgroundColor: `${paletteColors.primary} `,
              color: paletteColors.lightPrimary,
            },
            '&.root-MuiPickersDay-root.Mui-selected': {
              backgroundColor: '#d30000 ',
            },
            '&.Mui-disabled': {
              opacity: 0.4,
            },
            '&.MuiPickersDay': {
              root: {
                '&.Mui-selected': {
                  backgroundColor: paletteColors.primary,
                  color: paletteColors.lightPrimary,
                },
              },
            },
            '&.MuiToggleButton': {
              root: {
                '&.Mui-selected': {
                  backgroundColor: paletteColors.primary,
                  color: paletteColors.lightPrimary,
                },
              },
            },
          },
        },
      },
      // MuiPickersDay: {
      //   styleOverrides: {
      //     root: {
      //       '&.Mui-selected': {
      //         backgroundColor: 'red', // Your custom color for selected date
      //         color: 'white', // Text color for selected date
      //         '&:hover': {
      //           backgroundColor: 'darkred', // Your custom color for selected date on hover
      //         },
      //       },
      //     },
      //   },
      // },
      MuiLinearProgress: {
        styleOverrides: {
          bar: {
            backgroundColor: paletteColors.primary,
          },
          colorPrimary: {
            backgroundColor: paletteColors.lightSecondary,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: paletteColors.darkPrimary,
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            [theme.breakpoints.up('sm')]: {
              borderRadius: '0.75rem',
              '&.Mui-selected': {
                color: `${buttonPrimaryTextColor}`,
              },
              '&.Mui-selected.Mui-disabled': {
                opacity: '0.6',
              },
              '&.Mui-disabled': {
                opacity: '0.9',
              },
              '&.MuiToggleButtonGroup-grouped': {
                margin: '0 0.5rem',
                '&:not(:first-of-type), &:not(:last-of-type)': {
                  borderRadius: '0.75rem',
                  borderColor: 'var(--dark-light) !important',
                },
              },
            },
            [theme.breakpoints.down('sm')]: {
              '&.MuiToggleButtonGroup-grouped': {
                margin: '0 0.5rem',
                fontSize: '1rem',
                fontWeight: 400,
                color: '#4D4D4D',
                '&:not(:first-of-type), &:not(:last-of-type)': {
                  borderColor: 'var(--dark-light)',
                },
              },
            },
            '&.MuiToggleButton-primary': {
              '&.Mui-selected': {
                backgroundColor: paletteColors.secondary, // your custom primary color for the button background
                color: buttonPrimaryTextColor, // your custom color for the button text
                '&:hover': {
                  backgroundColor: paletteColors.secondary, // your custom hover color for the button background
                  color: buttonPrimaryTextColor, // your custom hover color for the button text
                },
              },
            },
            '&:not(.Mui-selected):hover': {
              borderColor: `${paletteColors.primary} !important`,
            },
            '&.MuiToggleButton-secondary': {
              '&.Mui-selected': {
                backgroundColor: paletteColors.secondary, // your custom primary color for the button background
                color: buttonPrimaryTextColor, // your custom color for the button text
                '&:hover': {
                  backgroundColor: paletteColors.secondary, // your custom hover color for the button background
                  color: buttonPrimaryTextColor, // your custom hover color for the button text
                },
              },
            },
          },
        },
      },
      MuiStepper: {
        styleOverrides: {
          root: {
            'text.MuiStepIcon-text': {
              fill: buttonPrimaryTextColor,
            },
            '.MuiSvgIcon-root.Mui-completed': {
              background: `radial-gradient(circle at 50%, ${buttonPrimaryTextColor} 40%, transparent 60%)`,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            height: '3rem',
            borderRadius: '1rem',
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${paletteColors.primary} !important`,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${paletteColors.primary} !important`,
            },
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            height: '3rem',
            borderRadius: '1rem',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              height: '3rem',
              borderRadius: '0.7rem',
              '& input': {
                fontSize: '1rem',
                fontWeight: 400,
                lineHeight: '1.5rem',
              },
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '& input': {
              fontSize: '1rem',
              fontWeight: 400,
              lineHeight: '1.5rem',
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            height: '3rem',
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '1.5rem',
            '& .MuiSelect-select': {
              height: '3rem',
              display: 'flex',
              alignItems: 'center',
            },
            '& .MuiSelect-placeholder': {
              fontSize: '1rem',
              fontWeight: 400,
              lineHeight: '1.5rem',
            },
          },
          select: {
            '&:focus': {
              background: 'transparent',
            },
          },
        },
      },
    },
  });
};

export default createAppTheme;
