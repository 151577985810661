import { useMediaQuery } from '@mui/material';
import React, { useContext } from 'react';
import { t } from 'i18next';
import './Whitelabel.scss';
import { BookingConfigContext } from '../../../context/bookingConfigContext/bookingConfigContext';
import isWhitelabel from '../../../shared/helpers/whitelabel';
import { CacheDataContext } from '../../../context/cacheContext/cacheContext';

const WhiteLabelTitle: React.FC = () => {
  const isMobileView = useMediaQuery('(max-width:600px)');
  const { bookingConfigData } = useContext(BookingConfigContext);
  const whiteLabel = isWhitelabel(bookingConfigData);
  const { store } = useContext(CacheDataContext);

  if (!whiteLabel || !isMobileView || store.currentStep !== 1) return null;

  return (
    <h5 className="whitelabel-title">
      {t('QUOTATIONINFORMATIONFORM.whitelabel_title')}
    </h5>
  );
};

export default WhiteLabelTitle;
