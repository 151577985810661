import React, { createContext, useContext, useState } from 'react';
import dayjs from 'dayjs';
import {
  BookingContextState,
  BookingStepsContextState,
} from './bookingContext.types';
import { getToken } from '../../shared/helpers/auth.helper';
import {
  BookingConfig,
  BookingConfigEnum,
  BookingStep,
  BookingStepEnum,
  BookingStepValueEnum,
  BookingSubStep,
  BookingSubStepEnum,
  BookingSubStepOption,
  BookingSubStepValueEnum,
  PricingData,
} from '../../components/BookingWidget/bookingSteps.interface';
import {
  getDefaultValuesForAddonsAndOptions,
  getSelectedCountries,
} from './bookingContext.helper';
import saveBooking from '../../shared/services/bookingData.service';
import {
  BookingQuoteResponse,
  BookingQuoteResponseEnum,
  BookingQuoteResponseQuoteEnum,
} from '../../components/BookingWidget/BookingQuoteManagment/bookingQuoteResponse.interface';
import contextDefaultValues from './bookingContext.const';
import { BookingConfigContext } from '../bookingConfigContext/bookingConfigContext';
import { BookingQuoteContext } from '../quoteContext/quoteContext';
import useQuoteDataService from '../../shared/services/quoteData.service';
import { BookingDataContext } from '../bookingDataContext/bookingDataContext';
import preparePricingPayload, {
  prepareCapturePricingPayload,
} from '../../components/QuotationSummaryBlock/preparePricingPayload';
import deepMerge from '../../shared/helpers/deepMergeObjects';
import { getCatalogName } from '../../components/QuotationForm/components/tripDetails.mapper';
import { FormQuotationInformationEnum } from '../../components/QuotationInformationForm/quoation-information.interface';
import useApi from '../../shared/services/api.service';
import { insertAddonsIntoObject } from '../../shared/helpers/insuranceOptions';
import ErrorLoggingService from '../../shared/services/errorlogging.service';
import { BookingDataResponse } from '../../components/BookingWidget/BookingManagment/bookingManagment.interface';
import { LoadingContext } from '../loadingContext/loadingContext';
import { NotificationContext } from '../../shared/components/Notification/NotificationContext';
import useSendQuoteEmail from '../../shared/helpers/useSendQuoteEmail';
import { getUrlPlatformAndCatalog } from '../../shared/helpers/url.helper';
import { CacheDataContext } from '../cacheContext/cacheContext';
import { Option } from '../../components/QuotationProposals/components/quotationProposal.interface';
import {
  getAgeByDayOfBirth,
  getDateOfBirth,
} from '../../shared/helpers/getTravellersAge';
import { useSessionContext } from '../sessionContext/sessionContext';
import {
  prepareInitInformationPersonal,
  prepareInitInformationTravellers,
} from '../../components/QuotationInformationForm/quotation-information.helper';

export const BookingContext =
  createContext<BookingContextState>(contextDefaultValues);

export const BookingContextProvider: React.FC<
  React.PropsWithChildren<object>
> = ({ children }) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const errorService: ErrorLoggingService = ErrorLoggingService.getInstance();
  const [bookingSteps, setBookingSteps] = useState<BookingStepsContextState>(
    contextDefaultValues.bookingSteps,
  );
  const [bookingReferenceId, setBookingReferenceId] = useState<string | null>(
    null,
  );
  const { bookingConfigData } = useContext(BookingConfigContext);
  const { bookingDataResponse, updateBookingData } =
    useContext(BookingDataContext);
  const { bookingQuoteResponse, updateQuote } = useContext(BookingQuoteContext);
  const { setCacheValue } = useContext(CacheDataContext);
  const { sessionParams } = useSessionContext();
  const API = useApi(bookingConfigData, sessionParams);
  const { showNotification } = useContext(NotificationContext);
  const { createQuote } = useQuoteDataService({
    bookingConfigData,
    bookingQuoteResponse,
  });
  const { sendQuote } = useSendQuoteEmail();
  const { decodedSessionToken } = useSessionContext();

  const isWithPriceCapturing = (subStep?: BookingSubStepValueEnum): boolean =>
    subStep === BookingSubStepValueEnum.MedicalScreening;

  const getPricingData = async (
    bookingStepsData: BookingStepsContextState,
    withPriceCapturing = false,
  ): Promise<PricingData> => {
    const apiCM360Url: string =
      bookingConfigData?.[BookingConfigEnum.DataCenter]?.cm360Endpoint;
    const { psClient } = bookingConfigData[BookingConfigEnum.Channel];
    const url = `${apiCM360Url}/ui-proxy/ws-partners/api/${getUrlPlatformAndCatalog(
      bookingConfigData,
    )}/pricing${withPriceCapturing ? '-for-quote' : ''}`;

    const preparePricingPayloadMethod = withPriceCapturing
      ? prepareCapturePricingPayload
      : preparePricingPayload;

    return API.post(
      url,
      preparePricingPayloadMethod(
        bookingStepsData,
        bookingConfigData,
        bookingDataResponse,
        bookingQuoteResponse,
        decodedSessionToken,
      ),
      {
        headers: {
          'Client-Id': psClient,
        },
      },
    )
      .then((res: any) => res.data)
      .then((res: any) => {
        if (!res.products[0].errorMessage) {
          const { currency, netPrice, grossPremium } =
            res.products[0].totalPrice;
          return { priceNet: netPrice, grossPremium, currency };
        }
        return { priceNet: 0, grossPremium: 0, currency: 'AUD' };
      });
  };

  async function saveBookingData(
    bookingStepsData: BookingStepsContextState,
  ): Promise<BookingDataResponse> {
    const apiCM360Url: string =
      bookingConfigData?.[BookingConfigEnum.DataCenter]?.cm360Endpoint;
    try {
      const bookingData = await saveBooking(
        apiCM360Url,
        bookingStepsData,
        bookingConfigData,
        bookingQuoteResponse as BookingQuoteResponse,
        bookingDataResponse,
        bookingReferenceId,
        decodedSessionToken,
        sessionParams,
      );
      updateBookingData(bookingData);
      return bookingData;
    } catch (error) {
      showNotification('updatingData', 'error', false);
      errorService.log('Update context error', error);
    }
    return undefined as unknown as BookingDataResponse;
  }

  const sendQuoteEmailAutomatically = (
    bookingStepsData: BookingStepsContextState,
    quoteId: string,
    currentStep?: string,
  ): void => {
    const shouldSendEmail = bookingConfigData[
      BookingConfigEnum.BookingSteps
    ].find(({ keyName }) => keyName === currentStep)?.sendQuoteOnEnterStep;

    if (shouldSendEmail) {
      const { email } =
        bookingStepsData.quotationInformationForm.informationPersonal;
      sendQuote(email, bookingStepsData, quoteId);
    }
  };

  async function saveQuoteAndBookingData(
    bookingStepsData: BookingStepsContextState,
    substep?: BookingSubStepValueEnum,
    currentStep?: BookingStepValueEnum,
    nextStep?: (step?: number | undefined) => void,
  ): Promise<BookingDataResponse> {
    const apiCM360Url: string =
      bookingConfigData?.[BookingConfigEnum.DataCenter]?.cm360Endpoint;
    setCacheValue('isSaveQuoteAndBookingDataLoading', true);
    try {
      // @TODO temporary fix, to make sure that async requests are done
      if (currentStep === BookingStepValueEnum.QuotationRecapDeclaration) {
        setIsLoading(true);
      }

      const { priceNet, grossPremium, currency } = await getPricingData(
        bookingStepsData,
        isWithPriceCapturing(substep),
      );
      const bookingStepsToSave = {
        ...bookingStepsData,
        ...(currentStep && { currentStep }),
        [BookingStepValueEnum.QuotationProposals]: {
          ...bookingStepsData[BookingStepValueEnum.QuotationProposals],
          [BookingSubStepValueEnum.TotalPrice]: +priceNet,
          [BookingSubStepValueEnum.Currency]: currency,
        },
      };

      const decodedToken = getToken();

      // @TODO temporary fix, to make sure that async requests are done
      if (currentStep === BookingStepValueEnum.QuotationRecapDeclaration) {
        setIsLoading(true);
      }

      const quoteResponse: BookingQuoteResponse = await createQuote({
        bookingSteps: bookingStepsToSave,
        agentEmail: decodedToken?.email,
        bookingDataResponse,
        bookingQuoteResponse,
        bookingReferenceId,
      });
      const mergedQuoteResponse = { ...bookingQuoteResponse, ...quoteResponse };
      updateQuote(mergedQuoteResponse);
      const bookingData = await saveBooking(
        apiCM360Url,
        bookingStepsToSave,
        bookingConfigData,
        mergedQuoteResponse,
        bookingDataResponse,
        bookingReferenceId,
        decodedSessionToken,
        sessionParams,
      );
      // @TODO temporary fix, to make sure that async requests are done
      const quoteId =
        quoteResponse[BookingQuoteResponseEnum.Quotes][0][
          BookingQuoteResponseQuoteEnum.QuoteId
        ];
      sendQuoteEmailAutomatically(bookingStepsData, quoteId, currentStep);

      if (currentStep === BookingStepValueEnum.QuotationRecapDeclaration) {
        setIsLoading(true);
      }
      updateBookingData(bookingData);
      if (nextStep) {
        nextStep();
      }
      return bookingData;
    } catch (error) {
      showNotification('updatingData', 'error', false);
      errorService.log('Update context error', error);
    } finally {
      setCacheValue('isSaveQuoteAndBookingDataLoading', false);
      setIsLoading(false);
    }
    return undefined as unknown as BookingDataResponse;
  }

  async function saveQuoteAndBookingDataAction(): Promise<BookingDataResponse> {
    return saveQuoteAndBookingData(bookingSteps);
  }

  const shallowUpdate = (
    callback: (data: BookingStepsContextState) => BookingStepsContextState,
  ): void => {
    const callbackResult = callback({ ...bookingSteps });
    const merged = { ...bookingSteps, ...callbackResult };
    setBookingSteps(merged);
  };

  const handleUpdate = (
    value: any,
    step: BookingStepValueEnum,
    subStep?: BookingSubStepValueEnum,
    updateBookingAndQuote?: boolean,
    currentStep?: BookingStepValueEnum,
    updateOnlyBookingData?: boolean,
    nextStep?: (step?: number | undefined) => void,
    onSuccess: (bookingData: BookingDataResponse) => void = () => null,
  ): void => {
    if (!step && !subStep) {
      setBookingSteps({ ...value });
      return;
    }
    if (subStep) {
      setBookingSteps((prevState) => {
        const updatedState = {
          ...prevState,
          [step]: { ...prevState[step], [subStep]: value },
        };
        updatedState[BookingStepValueEnum.CurrentStep] = step;
        if (updateBookingAndQuote) {
          saveQuoteAndBookingData(
            updatedState,
            subStep,
            currentStep,
            nextStep,
          ).then((bookingData: BookingDataResponse) => onSuccess(bookingData));
        } else if (updateOnlyBookingData) {
          saveBookingData(updatedState).then(
            (bookingData: BookingDataResponse) => onSuccess(bookingData),
          );
        }
        return updatedState;
      });
    } else {
      setBookingSteps((prevState) => {
        const updatedState = {
          ...prevState,
          [step]: { ...prevState[step], ...value },
        };
        updatedState[BookingStepValueEnum.CurrentStep] = step;
        if (updateBookingAndQuote) {
          saveQuoteAndBookingData(
            updatedState,
            subStep,
            currentStep,
            nextStep,
          ).then((bookingData: BookingDataResponse) => onSuccess(bookingData));
        } else if (updateOnlyBookingData) {
          saveBookingData(updatedState).then(
            (bookingData: BookingDataResponse) => onSuccess(bookingData),
          );
        }
        return updatedState;
      });
    }
  };

  const handleInitDefaultValues = (bookingConfig: BookingConfig): void => {
    const currentSteps = { ...bookingSteps };
    try {
      const quotationProposals = bookingConfig[
        BookingConfigEnum.BookingSteps
      ].filter(
        (step: BookingStep): boolean =>
          step[BookingStepEnum.KeyName] === 'quotationProposals',
      )[0];
      const addonsCard = quotationProposals[BookingStepEnum.Cards].filter(
        (card: BookingSubStep): boolean =>
          card[BookingSubStepEnum.KeyName] === BookingSubStepValueEnum.Addons,
      )[0];
      if (addonsCard) {
        currentSteps[BookingStepValueEnum.QuotationProposals][
          BookingSubStepValueEnum.Addons
        ] = getDefaultValuesForAddonsAndOptions(
          addonsCard[BookingSubStepEnum.Options] as BookingSubStepOption[],
        );
      }
      setBookingSteps({ ...currentSteps });
    } catch (error) {
      errorService.log('Error initial default values', error);
    }
  };

  const loadBooking = (booking: any): void => {
    const { bookingData, companions, customer, consents } = booking;
    const quotationsProposalsConfig = bookingConfigData.bookingSteps?.find(
      (s) => s.keyName === 'quotationProposals',
    );
    const quotationProposalsOptionsConfig =
      quotationsProposalsConfig?.cards?.find((c) => c.keyName === 'excess')
        ?.options;
    const quotationsFormConfig = bookingConfigData.bookingSteps?.find(
      (s) => s.keyName === 'quotationForm',
    );

    const geographicalZoneSelectedOptions = (
      quotationsFormConfig?.cards.find(
        (card: BookingSubStep) =>
          card.keyName === BookingSubStepValueEnum.GeographicalZone,
      )?.options as Option[]
    ).filter((o) => bookingData.destination.includes(o.value));
    const updatedBookingSteps = {
      quotationPreliminaryDeclarations: {
        [BookingSubStepValueEnum.InformationPersonal]: {
          [FormQuotationInformationEnum.Title]: customer.title,
          [FormQuotationInformationEnum.Firstname]: customer.firstName,
          [FormQuotationInformationEnum.Lastname]: customer.lastName,
        },
        [BookingSubStepValueEnum.PreflightConsents]: consents
          .map((c: any) =>
            (
              bookingConfigData.bookingSteps[0].cards.find(
                (card) =>
                  card.keyName === BookingSubStepValueEnum.PreflightConsents,
              )?.options as any
            ).find((o: any) => o.usageType === c.usages[0].type),
          )
          .filter((o: any) => !!o),
      },
      quotationForm: {
        tripDetails: {
          value: bookingData.catalog,
          label: '',
        },
        geographicalZone: geographicalZoneSelectedOptions.map(
          (option: Option) => ({
            label: option?.label,
            value: option?.value,
          }),
        ),
        travellersAge: (companions ?? []).map((b: any) =>
          getAgeByDayOfBirth(b.age, b.dateOfBirth),
        ),
        promoCode: bookingData.promoCode,
        tripCost: bookingData.tripCost ?? undefined,
      },
      quotationProposals: {
        options: getDefaultValuesForAddonsAndOptions(
          quotationProposalsOptionsConfig as any,
        ),
      },
      quotationInformationForm: {
        informationPersonal: prepareInitInformationPersonal(customer),
        informationTravellers: prepareInitInformationTravellers(companions),
      },
    };

    const merged = deepMerge(bookingSteps, updatedBookingSteps);
    merged.quotationForm.departureDate = dayjs(bookingData.startDate);
    merged.quotationForm.returnDate = dayjs(bookingData.endDate);

    setBookingSteps({ ...merged });
  };

  const setCurrentStep = (step: BookingStepValueEnum): void => {
    setBookingSteps({
      ...bookingSteps,
      [BookingStepValueEnum.CurrentStep]: step,
    });
  };

  const loadQuote = (dataToLoad: {
    quote: any;
    medicalConditions: any;
    consents: any[];
    bookingResponse: BookingDataResponse;
    latestBookingSteps: BookingStepsContextState;
  }): void => {
    const { quote, insurance, subscriber, beneficiaries } = dataToLoad.quote;
    const { companions } = dataToLoad.bookingResponse;
    const { medicalConditions, consents, bookingResponse, latestBookingSteps } =
      dataToLoad;
    const quotationsFormConfig = bookingConfigData.bookingSteps?.find(
      (s) => s.keyName === 'quotationForm',
    );
    const proposalsAddons = insertAddonsIntoObject(
      quote?.genericData?.insuranceAddons,
    );

    const formattedCustomer = subscriber && {
      [FormQuotationInformationEnum.Title]: subscriber.civility,
      [FormQuotationInformationEnum.Firstname]: subscriber.firstName,
      [FormQuotationInformationEnum.Lastname]: subscriber.lastName,
      [FormQuotationInformationEnum.Email]: subscriber.email,
      [FormQuotationInformationEnum.ReenterEmail]: subscriber.email,
      [FormQuotationInformationEnum.Phone]: subscriber.phone,
      [FormQuotationInformationEnum.State]:
        insurance.insuranceData?.state ||
        bookingResponse.customer?.addressLine4 ||
        bookingResponse.bookingData.region,
      [FormQuotationInformationEnum.Birthdate]: subscriber.birthDate,
    };

    const catalog = bookingResponse?.bookingData?.catalog
      ? bookingResponse.bookingData.catalog
      : `${quote.product.iac}`;

    const informationMedical: any = {};

    const medicalScreening = medicalConditions?.map((medicalData: any) =>
      medicalData
        ? {
            conditions: medicalData.conditions,
            isCovered: medicalData.isCovered,
          }
        : null,
    );
    const medicalDeclarations = medicalConditions?.find(
      (m: any) => m && m.declarations,
    )?.declarations;

    medicalDeclarations?.forEach((d: any, i: number) => {
      if (d.level === 1) {
        informationMedical[`medicalCondition${i + 1}`] = d.answer;
      } else {
        informationMedical[
          `extraQuestion${
            i +
            1 -
            medicalDeclarations.findIndex(
              (declaration: any) => declaration.level === 2,
            )
          }`
        ] = d.answer;
      }
    });

    let geographicalZoneSelectedOption = (
      quotationsFormConfig?.cards.find(
        (card) => card.keyName === BookingSubStepValueEnum.GeographicalZone,
      )?.options as any
    ).find((o: any) => o.value === quote.genericData.geographicalZone);
    const destinationArray: string[] =
      bookingResponse.bookingData?.destination?.split(',');
    if (destinationArray.length > 0) {
      geographicalZoneSelectedOption = (
        quotationsFormConfig?.cards.find(
          (card) => card.keyName === BookingSubStepValueEnum.GeographicalZone,
        )?.options as any
      ).filter((o: any) => destinationArray.includes(o.value));
    }
    const { maxTripDuration, isFamilyOrCouple } = quote.genericData;
    const maxTripDurationSelectedOption =
      maxTripDuration &&
      (
        quotationsFormConfig?.cards.find(
          (card) => card.keyName === BookingSubStepValueEnum.MaxTripDuration,
        )?.options as any
      ).find((o: any) => o.value === maxTripDuration);

    const medicalDisclaimerConsent = consents?.find(
      (c) =>
        c.usages &&
        c.usages.length > 0 &&
        c.usages[0].type === 'MEDICAL_DISCLAIMER_CONSENT',
    );
    const additionalValues = quote.product?.additionalValues?.[0]?.data;
    const updatedBookingSteps = {
      affiliateId: quote.genericData.partnerCode,
      quotationPreliminaryDeclarations: {
        [BookingSubStepValueEnum.InformationPersonal]: {
          [FormQuotationInformationEnum.Title]:
            formattedCustomer[FormQuotationInformationEnum.Title],
          [FormQuotationInformationEnum.Firstname]:
            formattedCustomer[FormQuotationInformationEnum.Firstname],
          [FormQuotationInformationEnum.Lastname]:
            formattedCustomer[FormQuotationInformationEnum.Lastname],
        },
        [BookingSubStepValueEnum.PreflightConsents]: consents
          .map((c) =>
            (
              bookingConfigData.bookingSteps[0].cards.find(
                (card) =>
                  card.keyName === BookingSubStepValueEnum.PreflightConsents,
              )?.options as any
            ).find((o: any) => o.usageType === c.usages?.[0]?.type),
          )
          .filter((o: any) => !!o),
      },
      quotationForm: {
        tripDetails: {
          value: getCatalogName(
            catalog,
            insurance.startDate,
            insurance.endDate,
          ),
          label: '',
        },
        geographicalZone: getSelectedCountries(geographicalZoneSelectedOption),
        ...(maxTripDurationSelectedOption
          ? {
              maxTripDuration: {
                label: maxTripDurationSelectedOption.label,
                value: maxTripDurationSelectedOption.value,
              },
            }
          : {}),
        travellersAge: (companions ?? []).map((b: any) =>
          getAgeByDayOfBirth(b.age, b.dateOfBirth),
        ),
        promoCode: quote.product.price.promotionCode,
        tripCost:
          bookingResponse?.bookingData?.tripCost ??
          quote?.genericData?.amountToInsure,
      },
      quotationProposals: {
        proposal: {
          code: quote.product.code,
          description: bookingResponse?.bookingData?.productName,
        },
        addons: proposalsAddons,
        cancellationLimit: quote.genericData.cancellationLimit,
        maxTripDuration: quote.genericData.maxTripDuration,
        excess: quote.genericData?.excess ?? '',
        ...((quote.genericData.dd_amount || quote.genericData.dd_rate) && {
          agentsDiscount: {
            discountAmount:
              quote.genericData.dd_amount ||
              `${quote.genericData.dd_rate * 100}`,
            isPercentageDiscount: !!quote.genericData.dd_rate,

            discountStatus: 'APPROVED',
            selectedDiscountReason: quote.genericData.dd_reason,
          },
        }),
        [BookingSubStepValueEnum.PromoRate]: additionalValues?.promo_rate,
        [BookingSubStepValueEnum.FamCoupleRate]:
          additionalValues?.fam_couple_rate,
      },
      quotationInformationForm: {
        ...(medicalDisclaimerConsent
          ? {
              [BookingSubStepValueEnum.MedicalDisclaimerConsent]: {
                usageType: 'MEDICAL_DISCLAIMER_CONSENT',
                agentExlusive: false,
                version: 1,
              },
            }
          : {}),
        [BookingSubStepValueEnum.MedicalPriceBreakdown]:
          additionalValues?.medical_breakdown,
        [BookingSubStepValueEnum.InformationPersonal]: formattedCustomer,
        [BookingSubStepValueEnum.InformationMedical]: informationMedical,
        [BookingSubStepValueEnum.MedicalScreening]: medicalScreening,
      },
    };
    const merged = deepMerge(latestBookingSteps, updatedBookingSteps);
    merged.quotationForm.departureDate = dayjs(insurance.startDate);
    merged.quotationForm.returnDate = dayjs(insurance.endDate);
    const informationTravellers: any = {};

    if (companions?.length) {
      bookingResponse.companions?.forEach((c, index) => {
        informationTravellers[`travellerFirstName${index}`] = c.firstName;
        informationTravellers[`travellerLastName${index}`] = c.lastName;
        informationTravellers[`travellerAge${index}`] = c.dateOfBirth
          ? dayjs(c.dateOfBirth)
          : dayjs(getDateOfBirth((c.age ?? 0)?.toString()));
      });
    } else {
      beneficiaries.forEach((b: any, index: number) => {
        if (index > 0) {
          informationTravellers[`travellerFirstName${index}`] = b.firstName;
          informationTravellers[`travellerLastName${index}`] = b.lastName;
          informationTravellers[`travellerAge${index}`] = dayjs().diff(
            dayjs(b.birthDate),
            'year',
          );
        } else {
          informationTravellers[`travellerAge${index}`] = dayjs(b.birthDate);
        }
      });
    }

    merged.quotationInformationForm[
      BookingSubStepValueEnum.InformationTravellers
    ] = informationTravellers;
    setBookingSteps({ ...merged });
  };

  return (
    <BookingContext.Provider // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        bookingSteps,
        update: handleUpdate,
        updateDefault: handleInitDefaultValues,
        loadQuote,
        loadBooking,
        bookingReferenceId,
        setBookingReferenceId,
        saveQuoteAndBookingDataAction,
        shallowUpdate,
        setCurrentStep,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};
