import {
  BookingQuoteResponse,
  BookingQuoteResponseEnum,
  BookingQuoteResponseQuoteEnum,
} from '../../components/BookingWidget/BookingQuoteManagment/bookingQuoteResponse.interface';

const getQuoteId = (bookingQuote: BookingQuoteResponse | null): string => {
  if (!bookingQuote) {
    return '';
  }
  return (
    bookingQuote[BookingQuoteResponseEnum.Quotes]?.[0]?.quoteId ||
    bookingQuote[BookingQuoteResponseEnum.Quote]?.[
      BookingQuoteResponseQuoteEnum.QuoteId
    ] // TODO bookingQuote.quote is outdated, bookingQuote.quotes[0] is new. This needs to be merged and refactored
  );
};

const hasQuoteId = (bookingQuote: BookingQuoteResponse | null): boolean => {
  if (!bookingQuote) {
    return false;
  }
  return !!getQuoteId(bookingQuote);
};

export { hasQuoteId, getQuoteId };
