enum SubscriberEnum {
  CountryCode = 'countryCode',
  Civility = 'civility',
  Birthdate = 'birthdate',
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  MobilePhone = 'mobilePhone',
  Phone = 'phone',
  Address1 = 'address1',
  Address2 = 'address2',
  PostalCode = 'postalCode',
  City = 'city',
  SendEmail = 'sendEmail',
}
interface Subscriber {
  [SubscriberEnum.CountryCode]: string;
  [SubscriberEnum.Civility]: string;
  [SubscriberEnum.Birthdate]: string;
  [SubscriberEnum.FirstName]: string;
  [SubscriberEnum.LastName]: string;
  [SubscriberEnum.Email]: string;
  [SubscriberEnum.MobilePhone]: string;
  [SubscriberEnum.Phone]: string;
  [SubscriberEnum.Address1]: string;
  [SubscriberEnum.Address2]: string;
  [SubscriberEnum.PostalCode]: string;
  [SubscriberEnum.City]: string;
  [SubscriberEnum.SendEmail]: boolean;
}

export { SubscriberEnum, Subscriber };
