import React from 'react';
import './QuotationElementPriceRow.scss';
import {
  PriceColorBlack,
  PriceFontDefault,
} from '../../QuotationSummaryBlock/PriceSummarySection/priceSummary.const';
import Currency from '../../../shared/components/Currency/Currency';

export interface QuotationElementPriceRowProps {
  label?: string | null;
  value?: string;
  isValueCurrency?: boolean;
  fontSizeLabel?: string;
  fontSizeValue?: string;
  fontColorLabel?: string;
  fontColorValue?: string;
  fontWeightLabel?: 'lighter' | 'normal' | 'bold' | 'bolder';
  fontWeightValue?: 'lighter' | 'normal' | 'bold' | 'bolder';
}

const QuotationElementPriceRow: React.FC<QuotationElementPriceRowProps> = ({
  label,
  value,
  isValueCurrency,
  fontSizeLabel,
  fontSizeValue,
  fontColorLabel,
  fontColorValue,
  fontWeightLabel,
  fontWeightValue,
}) => {
  const labelElement = label && (
    <span
      className="label summary"
      style={{
        fontSize: fontSizeLabel,
        fontWeight: fontWeightLabel,
        color: fontColorLabel,
      }}
    >
      {label}
    </span>
  );
  return (
    <div className="summary-element-row">
      {labelElement}
      <span
        className="label value"
        style={{
          fontSize: fontSizeValue,
          fontWeight: fontWeightValue,
          color: fontColorValue,
        }}
      >
        {isValueCurrency && value ? <Currency value={+value} /> : value}
      </span>
    </div>
  );
};

export default QuotationElementPriceRow;

QuotationElementPriceRow.defaultProps = {
  label: '',
  value: '',
  isValueCurrency: false,
  fontSizeLabel: PriceFontDefault,
  fontSizeValue: PriceFontDefault,
  fontColorLabel: PriceColorBlack,
  fontColorValue: PriceColorBlack,
  fontWeightLabel: 'normal',
  fontWeightValue: 'normal',
};
