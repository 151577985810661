import {
  ProgressSteps,
  BookingConfig,
  BookingConfigEnum,
  ConfigTypeEnum,
} from '../BookingWidget/bookingSteps.interface';

const getStepName = (
  label: string,
  t: (key: string, options?: object) => string,
  bookingConfigData?: BookingConfig,
): string => {
  switch (label) {
    case ProgressSteps.QuotationForm:
      if (
        bookingConfigData &&
        bookingConfigData[BookingConfigEnum.Type] === ConfigTypeEnum.WHITE_LABEL
      ) {
        return t('STEPPER.getAQuote');
      }
      return t('STEPPER.details');
    case ProgressSteps.QuotationProposals:
      if (
        bookingConfigData &&
        bookingConfigData[BookingConfigEnum.Type] === ConfigTypeEnum.WHITE_LABEL
      ) {
        return t('STEPPER.plansCover');
      }
      return t('STEPPER.quoteAddons');
    case ProgressSteps.quotationInformationForm:
      return t('STEPPER.travellers');
    case ProgressSteps.QuotationRecapDeclaration:
      return t('STEPPER.payment');
    case ProgressSteps.QuotationConfirmation:
      if (
        bookingConfigData &&
        bookingConfigData[BookingConfigEnum.Type] === ConfigTypeEnum.WHITE_LABEL
      ) {
        return t('STEPPER.confirmation');
      }
      return t('STEPPER.issuePolicy');
    default:
      return label;
  }
};

export default getStepName;
