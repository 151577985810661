import { useCallback, useContext } from 'react';
import { BookingConfigContext } from '../../context/bookingConfigContext/bookingConfigContext';
import { BookingStepsContextState } from '../../context/bookingContext/bookingContext.types';
import { BookingQuoteContext } from '../../context/quoteContext/quoteContext';
import { getLanguage } from '../../i18n';
import useApi from '../services/api.service';
import { BookingQuote } from '../../components/BookingWidget/BookingQuoteManagment/bookingQuote.interface';
import parseDataToQuote from '../../components/BookingWidget/BookingQuoteManagment/bookingQuoteManagment.mapper';
import {
  BookingConfigEnum,
  BookingStepValueEnum,
  BookingSubStepOptionEnum,
  BookingSubStepValueEnum,
} from '../../components/BookingWidget/bookingSteps.interface';
import { SendQuoteHook } from '../interfaces/useSendQuoteEmail.interface';
import { getUrlPlatformAndCatalog } from './url.helper';
import { useSessionContext } from '../../context/sessionContext/sessionContext';

const useSendQuoteEmail = (): SendQuoteHook => {
  const { bookingQuoteResponse } = useContext(BookingQuoteContext);
  const { bookingConfigData } = useContext(BookingConfigContext);

  const { sessionParams } = useSessionContext();
  const API = useApi(bookingConfigData, sessionParams);

  const prepareUrl = (bookingSteps: BookingStepsContextState): string => {
    const { cm360Endpoint } = bookingConfigData[BookingConfigEnum.DataCenter];
    return `${cm360Endpoint}/ui-proxy/ws-partners/api/documents/${getUrlPlatformAndCatalog(
      bookingConfigData,
    )}/products?locale=${getLanguage()}`;
  };
  const { decodedSessionToken } = useSessionContext();
  const preparePayload = (
    email: string,
    bookingSteps: BookingStepsContextState,
    quoteId: string,
  ): BookingQuote =>
    parseDataToQuote(
      bookingSteps,
      bookingConfigData,
      {
        email,
        quoteId,
      },
      decodedSessionToken,
    );

  const sendQuote = useCallback(
    async (
      email: string,
      bookingSteps: BookingStepsContextState,
      quoteId: string,
    ) => {
      const { psClient } = bookingConfigData[BookingConfigEnum.Channel];
      const payload = preparePayload(email, bookingSteps, quoteId);
      const url = prepareUrl(bookingSteps);
      return API.post(url, payload, {
        headers: {
          'Client-Id': psClient,
        },
      });
    },
    [API, bookingConfigData, bookingQuoteResponse, preparePayload, prepareUrl],
  );

  return {
    sendQuote,
  };
};

export default useSendQuoteEmail;
