import dayjs from 'dayjs';
import { BookingStepsContextState } from '../../../context/bookingContext/bookingContext.types';
import {
  GTMTripType,
  PageViewEventPayload,
} from '../../interfaces/gtm.interface';
import isAMTTrip from '../../helpers/isAMTTrip';
import { ProductEnum } from '../../../components/QuotationProposals/useFetchProposalsConf';
import {
  TotalPrice,
  usePriceSummaryLoading,
} from '../../../context/priceSummaryContext/priceSummaryContext';

function getTripType(catalogName?: string): GTMTripType {
  if (!catalogName) return '';
  if (isAMTTrip(catalogName)) return 'Multi';
  return 'Single';
}

export default function preparePageViewEventPayload(
  bookingSteps: BookingStepsContextState,
  totalPrice: TotalPrice | null,
): PageViewEventPayload {
  const {
    departureDate: startDate,
    returnDate: endDate,
    travellersAge,
    geographicalZone,
    tripDetails,
  } = bookingSteps.quotationForm;
  const { excess, addons, cancellationLimit, proposal } =
    bookingSteps.quotationProposals;
  return {
    planId: proposal?.code ?? '',
    productName: proposal?.description ?? '',
    productPrice:
      totalPrice?.grossPremium?.toString() ||
      proposal?.[ProductEnum.Price]?.grossPremium.toString(),
    destination: geographicalZone.map(({ label }) => label),
    destinationIsoCodes: geographicalZone.map(({ value }) => value),
    tripType: getTripType(tripDetails.value),
    selectedExcess: excess,
    startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
    endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
    tripDuration:
      startDate && endDate
        ? dayjs(endDate).diff(startDate, 'days').toString()
        : '',
    leadTime: startDate
      ? dayjs(startDate).diff(dayjs(), 'days').toString()
      : '',
    currencyCode: proposal?.price?.currency ?? '',
    cancellationLimit,
    addOns: {
      selection: Object.keys(addons).filter((keyName) => addons[keyName]),
    },
    totalTravellers: travellersAge.length.toString(),
    travellers: {
      ages: travellersAge.map(({ age }) => age),
    },

    policyId: bookingSteps.quotationRecapDeclarations?.policyId ?? '',
  };
}
