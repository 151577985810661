/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React, { useContext } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  BookingQuoteResponseEnum,
  BookingQuoteResponseQuoteEnum,
} from '../../BookingWidget/BookingQuoteManagment/bookingQuoteResponse.interface';
import useFlowSpecificFactory from '../../QuotationRecapDeclarations/helpers/useFlowSpecificFactory';
import { BookingQuoteContext } from '../../../context/quoteContext/quoteContext';
import { NotificationContext } from '../../../shared/components/Notification/NotificationContext';

import './QuoteCopy.scss';

interface QuoteCopyProps {
  textSize?: 'sm' | 'md' | 'lg';
  subLabel?: string | null;
  textId?: string | null;
}

const QuoteCopy = ({
  textSize,
  subLabel,
  textId,
}: QuoteCopyProps): React.ReactElement<QuoteCopyProps> => {
  // util
  const { summaryBlockData } = useFlowSpecificFactory();
  const { showNotification } = useContext(NotificationContext);
  // context
  const { bookingQuoteResponse } = useContext(BookingQuoteContext);

  const copyId = (): void => {
    const quoteId =
      summaryBlockData.data ||
      bookingQuoteResponse?.[BookingQuoteResponseEnum.Quotes]?.[0]?.[
        BookingQuoteResponseQuoteEnum.QuoteId
      ];
    navigator.clipboard.writeText(textId || (quoteId as string));
    showNotification('copied', 'success', false);
  };

  return (
    <div className="quote-copy">
      <div className={`quote-id text-${textSize}`}>
        <span className="quote-id-sub">
          {subLabel || summaryBlockData.subLabel}
        </span>
        <span className="quote-id-data">
          {textId ||
            (summaryBlockData.data
              ? summaryBlockData.data
              : bookingQuoteResponse?.[BookingQuoteResponseEnum.Quotes]?.[0]?.[
                  BookingQuoteResponseQuoteEnum.QuoteId
                ])}
        </span>
        <ContentCopyIcon onClick={copyId} />
      </div>
    </div>
  );
};

QuoteCopy.defaultProps = {
  textSize: 'sm',
};

export default QuoteCopy;
