import React, { useContext } from 'react';
import './Currency.scss';
import { Typography } from '@mui/material';
import { BookingConfigContext } from '../../../context/bookingConfigContext/bookingConfigContext';
import {
  BookingConfigEnum,
  CurrencyEnum,
} from '../../../components/BookingWidget/bookingSteps.interface';
import getCurrencySymbol from './currency.utils';

interface CurrencyProps {
  value: number;
  typographyStyle?: React.CSSProperties;
}

const CurrencyComponent: React.FC<CurrencyProps> = ({
  value,
  typographyStyle,
}) => {
  const { bookingConfigData } = useContext(BookingConfigContext);
  if (bookingConfigData[BookingConfigEnum.Currency]) {
    const formattedCurrency = getCurrencySymbol(bookingConfigData, value);
    return <Typography style={typographyStyle}>{formattedCurrency}</Typography>;
  }
  return <Typography style={typographyStyle}>{value}</Typography>;
};

CurrencyComponent.defaultProps = {
  typographyStyle: undefined,
};

export default CurrencyComponent;
