import React, {
  Context,
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  SessionContextProps,
  SessionContextState,
} from './sessionContext.interface';

const defaultValues: SessionContextState = {
  sessionParams: {
    token: null,
    sessionToken: null,
    bookingType: null,
    bookingDataId: null,
  },
  decodedSessionToken: null,
};

const SessionContext: Context<SessionContextState> =
  createContext<SessionContextState>(defaultValues);

export const useSessionContext: () => SessionContextState = () =>
  useContext(SessionContext);

export const SessionProvider: FC<SessionContextProps> = ({ children }) => {
  const [sessionParams, setSessionParams] = useState(
    defaultValues.sessionParams,
  );
  const [decodedSessionToken, setDecodedSessionToken] = useState(
    defaultValues.decodedSessionToken,
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    const sessionToken = queryParams.get('sessionToken');
    const bookingType = queryParams.get('bookingType');
    const bookingDataId = queryParams.get('bookingDataId');

    if (sessionToken) {
      try {
        const decodedToken = atob(sessionToken);
        setDecodedSessionToken(JSON.parse(decodedToken));
      } catch (error) {
        console.error('Invalid sessionToken:', error);
      }
    }

    setSessionParams({
      token: token || null,
      sessionToken: sessionToken || null,
      bookingType: bookingType || null,
      bookingDataId: bookingDataId || null,
    });
  }, []);

  const contextValue = useMemo(
    () => ({ sessionParams, decodedSessionToken }),
    [sessionParams, decodedSessionToken],
  );

  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  );
};
