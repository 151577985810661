import React, { FC, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import PromotionPrice from '../PromotionPrice/PromotionPrice';
import CurrencyComponent from '../../../shared/components/Currency/Currency';
import QuotationElementBlockPrice from '../../QuotationsBlockShare/QuotationPriceElement/QuotationElementBlockPrice';
import { usePriceSummaryLoading } from '../../../context/priceSummaryContext/priceSummaryContext';
import {
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from '../../BookingWidget/bookingSteps.interface';
import { BookingContext } from '../../../context/bookingContext/bookingContext';
import { useDiscountDialog } from '../../../context/discountContext/discountContext';
import QuotationElementPriceRow from '../../QuotationsBlockShare/QuotationPriceElement/QuotationElementPriceRow';

const PriceSummaryPromo: FC = () => {
  const { t } = useTranslation();
  const { bookingSteps } = useContext(BookingContext);
  const { getPricingData, price, promoRate, totalPrice, additionalPrices } =
    usePriceSummaryLoading();
  // memo
  const creditAmount =
    bookingSteps[BookingStepValueEnum.QuotationProposals][
      BookingSubStepValueEnum.CreditAmount
    ];
  const promoCode =
    bookingSteps[BookingStepValueEnum.QuotationForm][
      BookingSubStepValueEnum.PromoCode
    ];
  const {
    discountStatus,
    setDiscountStatus,
    isPercentageDiscount,
    discountAmount,
    removeDiscount,
  } = useDiscountDialog();
  const onRemoveDiscount = (): void => {
    setDiscountStatus('');
    removeDiscount();
  };

  const showPromotionSection =
    bookingSteps.currentStep !== 'quotationRecapDeclarations';

  // @TODO - remove this asap, there is too many pricing requests!
  useEffect(() => {
    if (
      !bookingSteps[BookingStepValueEnum.QuotationProposals][
        BookingSubStepValueEnum.Proposal
      ]
    ) {
      return;
    }
    getPricingData();
  }, [bookingSteps, discountStatus]);

  const isSectionVisible = useMemo(
    () =>
      totalPrice !== null &&
      totalPrice?.grossPremiumBeforePromotion !== undefined &&
      totalPrice?.grossPremiumBeforePromotion > 0 &&
      (discountStatus || promoCode),
    [totalPrice, discountStatus, promoCode],
  );

  return (
    isSectionVisible && (
      <>
        {totalPrice?.grossPremiumBeforePromotion && (
          <QuotationElementPriceRow
            label={t('BOOKING.QUOTATION_SUMMARY_BLOCK.grossPrice')}
            value={totalPrice?.grossPremiumBeforePromotion?.toString() || '0'}
            fontWeightLabel="bold"
            isValueCurrency
          />
        )}
        <PromotionPrice
          bookingSteps={bookingSteps}
          t={t}
          promoCode={promoCode}
          price={price}
          promoRate={promoRate}
        />

        {discountStatus && (
          <div className="summary-box">
            <div className="row-price">
              <Typography className="summary-title">
                {t('BOOKING.QUOTATION_SUMMARY_BLOCK.discretionaryDiscount')}
              </Typography>
              <span>
                {showPromotionSection && (
                  <ClearIcon
                    fontSize="small"
                    className="clearDiscountPromotion"
                    onClick={onRemoveDiscount}
                  />
                )}
              </span>
            </div>

            <div className="row-price">
              {isPercentageDiscount ? (
                <Typography>{discountAmount}%</Typography>
              ) : (
                <CurrencyComponent value={+discountAmount} />
              )}
              <span>
                <CurrencyComponent
                  value={+(additionalPrices?.discount_amount ?? 0)}
                />
              </span>
            </div>
          </div>
        )}
        {!!creditAmount && (
          <Box className="summary-box">
            <QuotationElementBlockPrice
              product={[
                {
                  productName:
                    t('BOOKING.QUOTATION_SUMMARY_BLOCK.creditAmount') ||
                    'Credit Amount',
                  amount: creditAmount.toString(),
                },
              ]}
            />
          </Box>
        )}
        <Divider sx={{ margin: '0.5rem 0' }} />
      </>
    )
  );
};

export default PriceSummaryPromo;
