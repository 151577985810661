import React, { memo, useContext } from 'react';
import QuotationSummaryHelioBlock from '../QuotationSummaryHelioBlock/QuotationSummaryHelioBlock';

import './QuotationSummaryMobile.scss';
import QuotationTripSummaryBlock from '../QuotationTripSummaryBlock/QuotationTripSummaryBlock';
import QuotationSummaryBlock from '../QuotationSummaryBlock/QuotationSummaryBlock';
import { BookingContext } from '../../context/bookingContext/bookingContext';
import {
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from '../BookingWidget/bookingSteps.interface';
import { getQuoteIdFromBookingDataConfig } from '../QuotationSummaryHelioBlock/helio-utils';
import { BookingQuoteContext } from '../../context/quoteContext/quoteContext';
import getUrlSearchParam from '../../shared/helpers/urlSearchParams.helper';
import { AppEvents, emit$ } from '../../shared/app-events';

const QuotationSummaryMobile: React.FC = () => {
  const { bookingSteps } = useContext(BookingContext);

  const onNextStep = (step?: number): void => {
    emit$(AppEvents.ON_NAVIGATION_NEXT, step);
  };

  const summaryBlockVisible =
    bookingSteps[BookingStepValueEnum.QuotationProposals][
      BookingSubStepValueEnum.Proposal
    ];
  const { bookingQuoteResponse: bookingQuote } =
    useContext(BookingQuoteContext);

  const hasQuoteId =
    bookingQuote && !!getQuoteIdFromBookingDataConfig(bookingQuote);

  const urlParams: URLSearchParams = new URLSearchParams(
    window.location.search,
  );

  const bookingType: string | null = getUrlSearchParam(
    'bookingType',
    urlParams,
  );
  return (
    <div className="quotation-summary-mobile">
      {summaryBlockVisible && <QuotationSummaryBlock />}
      <QuotationTripSummaryBlock nextStep={onNextStep} />
      {hasQuoteId && bookingType === 'simplified' && (
        <QuotationSummaryHelioBlock />
      )}
    </div>
  );
};

export default memo(QuotationSummaryMobile);
