import {
  BookingConfig,
  BookingConfigEnum,
  ConfigTypeEnum,
} from '../bookingSteps.interface';

export const BookingConfigIgo: any = {
  brand: 'FCTG',
  locale: 'en',
  dateFormat: 'dd/MM/yyyy',
  dateTimeFormat: 'dd/MM/yyyy HH:mm',
  birthdayFormat: 'DD/MM/YYYY',
  b2cUrl: 'https://b2c.dev.aws.ea-eshop.com',
  pdsUrl: 'https://b2c.dev.aws.ea-eshop.com/pds',
  drupalUrl: 'https://uat.fctg-web-s0.au.europ-assistance.com',
  country: {
    countries: 'AU',
    id: 1,
  },
  channel: {
    psPlatform: 'au',
    psClient: 'b2c-au.ws',
    id: 1,
  },
  currency: {
    currency: 'AUD',
    locale: 'en-AU',
    id: 1,
  },
  dataCenter: {
    code: 'AU_DATA_CENTER',
    cm360Endpoint: 'https://api.dev.aws.ea-eshop.com/',
    psPartnerEndpoint: 'https://ws-partners-prelive.europ-assistance.com',
    psPlatform: 'au',
    cm360Key: 'sg0RvHRSb46BWodxAMSMK1jqkYEuFzYt3M9l7jDM',
    psClient: 'b2c-au.ws',
    id: 1,
  },
  paymentProvider: 'BRAINTREE',
  catalogs: [
    {
      code: 'B2C-AUS-CRUISE.ESHOP',
      isAnnual: false,
      id: 2,
    },
    {
      code: 'B2C-AUS-ST.ESHOP',
      isAnnual: false,
      id: 1,
    },
  ],
  bookingSteps: [
    {
      label: 'Quotation Preliminary Declarations',
      keyName: 'quotationPreliminaryDeclarations',
      stepNameApi: 'DETAIL',
      component: 'QuotationPreliminaryDeclarations',
      sendQuoteEmailEnabled: false,
      locale: 'en',
      cards: [
        {
          label: 'Preflight Consents',
          keyName: 'preflightConsents',
          locale: 'en',
          options: [
            {
              label: 'ABBREVIATED_PRODUCT_INFO_CONSENT',
              locale: 'en',
              description:
                'Is it ok with you that we will provide you with abbreviated information about our travel insurance products so you can choose a suitable policy?',
              usageType: 'ABBREVIATED_PRODUCT_INFO_CONSENT',
              agentExclusive: false,
              version: 1,
              group: false,
              isOptional: false,
              channel: 'Portal',
              id: 16,
            },
            {
              label: 'PERSONAL_HEALTH_INFO_CALL_CONSENT',
              locale: 'en',
              description:
                'During the call, I will be asking you for personal information including details about your health. Do you give your consent to proceed?',
              usageType: 'PERSONAL_HEALTH_INFO_CALL_CONSENT',
              agentExclusive: false,
              version: 1,
              group: false,
              isOptional: false,
              channel: 'Portal',
              id: 17,
            },
            {
              label: 'OTHERS_PERSONAL_HEALTH_INFO_CONSENT',
              locale: 'en',
              description:
                'Where you will have other on your policy, do you have their consent or the legal right to disclose their personal information, including health information, to us?',
              usageType: 'OTHERS_PERSONAL_HEALTH_INFO_CONSENT',
              agentExclusive: false,
              version: 1,
              group: false,
              isOptional: false,
              channel: 'Portal',
              id: 18,
            },
          ],
          agentScripts: null,
          id: 7,
        },
        {
          label: 'Preflight Declarations',
          keyName: 'preflightChecks',
          locale: 'en',
          options: [
            {
              label: 'are aged 100 or under',
              value: 'CHECK_1',
              locale: 'en',
              id: 28,
            },
            {
              label:
                'are permanent residents or citizens of Australia or non-permanent residents with valid private health insurance',
              value: 'CHECK_2',
              locale: 'en',
              id: 29,
            },
            {
              label: 'are currently in Australia',
              value: 'CHECK_3',
              locale: 'en',
              id: 30,
            },
          ],
          agentScripts: null,
          id: 8,
        },
      ],
      agentScripts: {
        texts: {
          checks:
            'Before I proceed can you please confirm that all members of your party:',
          details:
            "To begin, I'll need a few details to get things rolling smoothly. May I start by getting your title, first name, and last name, please?",
          consents: 'Also we need some important consents from you to proceed:',
        },
        id: 1,
      },
      id: 1,
    },
    {
      label: 'Quotation Form',
      keyName: 'quotationForm',
      stepNameApi: 'DETAIL',
      component: 'QuotationForm',
      sendQuoteEmailEnabled: false,
      locale: 'en',
      cards: [
        {
          label: 'Trip Details',
          keyName: 'tripDetails',
          component: 'QuotationFormTripDetails',
          locale: 'en',
          options: [],
          agentScripts: {
            text: "Hello, thank you for choosing our company for your travel insurance. I'll be assisting you in filling out the form to ensure you have the right coverage for your upcoming trip. Let's get started! \\\\nNow, let's gather some information about your travel plans. Are you planning a single trip or a cruise trip?",
            id: 2,
          },
          id: 1,
        },
        {
          label: 'Departure Date',
          keyName: 'departureDate',
          component: 'QuotationFormStartCalendar',
          locale: 'en',
          options: [],
          agentScripts: {
            text: 'When do you plan to start your journey?',
            id: 3,
          },
          id: 2,
        },
        {
          label: 'Return Date',
          keyName: 'returnDate',
          component: 'QuotationFormEndCalendar',
          locale: 'en',
          options: [],
          agentScripts: {
            text: 'When do you plan to return?',
            id: 4,
          },
          id: 3,
        },
        {
          label: 'Destination',
          keyName: 'geographicalZone',
          component: 'QuotationFormDestination',
          locale: 'en',
          options: [
            {
              label: 'Domestic',
              value: 'domestic',
              icon: 'edit',
              locale: 'en',
              description: 'Including Bali and Lombok',
              id: 11,
            },
            {
              label: 'New-Zealand and Pacific',
              value: 'NZ & Pacific',
              icon: 'edit',
              locale: 'en',
              description: 'A single return journey',
              id: 12,
            },
            {
              label: 'Asia',
              value: 'Asia',
              icon: 'edit',
              locale: 'en',
              description: 'Including Bali and Lombok',
              id: 13,
            },
            {
              label: 'Excluding North and South America',
              value: 'World excl. USA & Canada',
              icon: 'edit',
              locale: 'en',
              description: 'Excluding North and South America',
              id: 14,
            },
            {
              label: 'Worldwide including America',
              value: 'World incl. USA & Canada',
              icon: 'edit',
              locale: 'en',
              description: 'Excludes Domestic Trips',
              id: 15,
            },
          ],
          agentScripts: {
            text: 'What is the destination of your trip?',
            id: 5,
          },
          id: 4,
        },
        {
          label: 'Travellers',
          keyName: 'travellersType',
          component: 'QuotationFormTravellers',
          locale: 'en',
          options: [
            {
              label: 'individual',
              value: 'Individual',
              icon: 'person',
              locale: 'en',
              id: 8,
            },
            {
              label: 'Group',
              value: 'Group',
              icon: 'groups',
              locale: 'en',
              id: 9,
            },
            {
              label: 'Couple or Family',
              value: 'couple-or-family',
              icon: 'people',
              locale: 'en',
              id: 10,
            },
          ],
          agentScripts: {
            text: 'Are you traveling alone, or will there be other family members or friends accompanying you?',
            id: 6,
          },
          id: 5,
        },
        {
          label: 'Travellers Age',
          keyName: 'travellersAge',
          component: 'QuotationFormTravellersAge',
          locale: 'en',
          options: [],
          agentScripts: {
            text: 'May I know the age of the the travellers?',
            id: 7,
          },
          id: 6,
        },
      ],
      agentScripts: null,
      id: 2,
    },
    {
      label: 'Quotation Proposals',
      keyName: 'quotationProposals',
      stepNameApi: 'DETAIL',
      component: 'QuotationProposals',
      sendQuoteEmailEnabled: false,
      locale: 'en',
      cards: [
        {
          label: 'Tables',
          keyName: 'tables',
          component: 'QuotationProposalsTables',
          locale: 'en',
          options: [],
          agentScripts: {
            text: 'You can choose one of our products',
            id: 8,
          },
          id: 9,
        },
        {
          label: 'Addons',
          keyName: 'addons',
          component: 'QuotationProposalsAddons',
          description:
            "Most claims will be subject to an excess but you can choose the level that’s right for you from a choice of standard or double. Or you can even choose to not pay an excess. By selecting Excess Eliminator the price of your policy will go up, however you won't have to pay any excess at all should you make a claim. By selecting Double your excess the price of your policy will decrease, however you will have to pay a higher excess should you make a claim",
          locale: 'en',
          options: [
            {
              label: 'Eliminator excess ($0)',
              value: 'Ad1',
              locale: 'en',
              id: 1,
            },
            {
              label: 'Standard excess ($100)',
              value: 'Ad2',
              locale: 'en',
              id: 2,
            },
            {
              label: 'Premium excess ($200)',
              value: 'Ad3',
              locale: 'en',
              id: 3,
            },
          ],
          agentScripts: {
            text: 'We have additional features for the product.',
            id: 9,
          },
          id: 10,
        },
        {
          label: 'Options',
          keyName: 'options',
          component: 'QuotationProposalsOptions',
          locale: 'en',
          options: [
            {
              label: 'Natural disaster',
              value: 'Op1',
              locale: 'en',
              description:
                'The Natural Disaster add-on will not provide cover for events known prior to purchasing a policy. The below events are currently impacting travellers. Please make sure you check our Travel Alerts for more details about other known events. Covid-19 is not a Natural Disaster as per the policy wording. Limited cover for COVID-19 is now available in Domestic and One-Trip International Policies (Silver and Gold only).',
              id: 4,
            },
            {
              label: 'Winter sports',
              value: 'Op2',
              locale: 'en',
              description:
                'To have cover for any injuries incurred whilst taking part in winter sports activities (e.g. skiing, snowboarding, ice skating etc) you must have purchased this option. In addition, this option provides cover if your winter sports equipment is lost, damaged or stolen or if your enjoyment is disrupted due to a Piste Closure or Avalanche. Please see details of included winter sports activities as well as coverage terms and limits in your PDS. There is no cover for medical expenses incurred in Australia whilst you are undertaking any of the covered winter sport activities.',
              id: 5,
            },
            {
              label: 'Business',
              value: 'Op3',
              locale: 'en',
              description:
                'This option provides cover for various losses that may arise when travelling on business, including; lost, stolen or damaged business equipment or money. Please see full details of coverage terms and limits in your PDS.',
              id: 6,
            },
            {
              label: 'Golf',
              value: 'Op4',
              locale: 'en',
              description:
                'This option provides cover for various losses that may arise when travelling on a golf trip, including; lost stole or damaged golf equipment, and losses of any pre-paid green fees or tuition fees. Please see full details of coverage terms and limits in your PDS.',
              id: 7,
            },
          ],
          agentScripts: {
            text: 'Also there are some options. You can choose any',
            id: 10,
          },
          id: 11,
        },
      ],
      agentScripts: null,
      id: 3,
    },
    {
      label: 'Personal Information',
      keyName: 'quotationInformationForm',
      stepNameApi: 'DETAIL',
      component: 'QuotationInformationForm',
      sendQuoteEmailEnabled: true,
      headerText:
        'You have a <a href=\\"https://www.insureandgo.com.au/duty-care-misrepresentation/\\" target=\\"_blank\\">duty to take reasonable care</a> not to make a misrepresentation when entering into this contract of insurance. It’s important you understand you’re answering our questions for yourself and anyone else that you want to be covered by the contract and that the insurance we offer is based on your answers. When you answer the questions you must take reasonable care to give a true and accurate answer and fully answer the question. If you do make a misrepresentation to us, we may cancel your contract or reduce the amount we will pay you if you make a claim, or both. If you make a fraudulent misrepresentation to us, we may refuse to pay a claim and treat the contract as if it never existed.',
      locale: 'en',
      cards: [
        {
          label: 'Information Data',
          keyName: 'informationData',
          locale: 'en',
          options: [
            {
              label: 'title',
              value: 'title',
              locale: 'en',
              id: 31,
            },
            {
              label: 'firstname',
              value: 'firstname',
              locale: 'en',
              id: 32,
            },
            {
              label: 'lastname',
              value: 'lastname',
              locale: 'en',
              id: 33,
            },
            {
              label: 'email',
              value: 'email',
              locale: 'en',
              id: 34,
            },
            {
              label: 'reenterEmail',
              value: 'reenterEmail',
              locale: 'en',
              id: 35,
            },
            {
              label: 'phone',
              value: 'phone',
              locale: 'en',
              id: 36,
            },
            {
              label: 'address',
              value: 'address',
              locale: 'en',
              id: 37,
            },
            {
              label: 'address2',
              value: 'address2',
              locale: 'en',
              id: 38,
            },
            {
              label: 'city',
              value: 'city',
              locale: 'en',
              id: 39,
            },
            {
              label: 'state',
              value: 'state',
              locale: 'en',
              id: 40,
            },
            {
              label: 'postcode',
              value: 'postcode',
              locale: 'en',
              id: 41,
            },
            {
              label: 'birthdate',
              value: 'birthdate',
              locale: 'en',
              id: 42,
            },
            {
              label: 'travellers',
              value: 'travellers',
              locale: 'en',
              id: 43,
            },
            {
              label: 'medicalCondition1',
              value: 'medicalCondition1',
              locale: 'en',
              description:
                'Do you have any Pre-Existing Medical Conditions to disclose?',
              id: 44,
            },
            {
              label: 'medicalCondition2',
              value: 'medicalCondition2',
              locale: 'en',
              description:
                'Are you waiting for hospital investigation or treatment for a condition or set of symptoms that are currently undiagnosed, or traveling to get treatment abroad?',
              id: 45,
            },
            {
              label: 'medicalCondition3',
              value: 'medicalCondition3',
              locale: 'en',
              description:
                'Are you traveling against the advice of a medical practitioner or have you been given a terminal diagnosis and the terminal prognosis is expected prior to the trip end date?',
              id: 46,
            },
            {
              label: 'extraQuestion1',
              value: 'extraQuestion1',
              locale: 'en',
              description: 'any heart related condition?',
              id: 47,
            },
            {
              label: 'extraQuestion2',
              value: 'extraQuestion2',
              locale: 'en',
              description: 'any blood circulatory condition?',
              id: 48,
            },
            {
              label: 'extraQuestion3',
              value: 'extraQuestion3',
              locale: 'en',
              description: 'any diabetic condition?',
              id: 49,
            },
            {
              label: 'extraQuestion4',
              value: 'extraQuestion4',
              locale: 'en',
              description: 'any breathing condition?',
              id: 50,
            },
            {
              label: 'extraQuestion5',
              value: 'extraQuestion5',
              locale: 'en',
              description:
                'any neurological condition, (including stroke, brain haemorrhage or epilepsy)?',
              id: 51,
            },
            {
              label: 'extraQuestion6',
              value: 'extraQuestion6',
              locale: 'en',
              description: 'any arthritic condition?',
              id: 52,
            },
            {
              label: 'extraQuestion7',
              value: 'extraQuestion7',
              locale: 'en',
              description: 'any back condition?',
              id: 53,
            },
            {
              label: 'extraQuestion8',
              value: 'extraQuestion8',
              locale: 'en',
              description: 'osteoporosis?',
              id: 54,
            },
            {
              label: 'extraQuestion9',
              value: 'extraQuestion9',
              locale: 'en',
              description:
                "any bowel condition (including Crohn's disease or IBS)?",
              id: 55,
            },
            {
              label: 'extraQuestion10',
              value: 'extraQuestion10',
              locale: 'en',
              description:
                'any psychiatric or psychological conditions (including anxiety or depression)?',
              id: 56,
            },
            {
              label: 'extraQuestion11',
              value: 'extraQuestion11',
              locale: 'en',
              description: 'any cancerous condition?',
              id: 57,
            },
            {
              label: 'extraQuestion12',
              value: 'extraQuestion12',
              locale: 'en',
              description:
                'At any time in the past 12 months, have you or any person insured on the policy been referred to or seen by a hospital doctor, specialist or surgeon (other than an A&E doctor), or needed in-patient treatment in hospital?',
              id: 58,
            },
          ],
          agentScripts: null,
          id: 12,
        },
      ],
      additionalData: {
        statesConfig: {
          states: [
            {
              code: 'ACT',
              label: 'Australian Capital Territory',
            },
            {
              code: 'NSW',
              label: 'New South Wales',
            },
            {
              code: 'NT',
              label: 'Northern Territory',
            },
            {
              code: 'QLD',
              label: 'Queensland',
            },
            {
              code: 'SA',
              label: 'South Australia',
            },
            {
              code: 'TAS',
              label: 'Tasmania',
            },
            {
              code: 'VIC',
              label: 'Victoria',
            },
            {
              code: 'WA',
              label: 'Western Australia',
            },
          ],
          isEnabled: true,
        },
      },
      agentScripts: {
        texts: {
          city: 'What city are you currently residing in?',
          email: 'And your email address?',
          intro:
            "I'm going to take some personal information for this quotation which will be deleted in 30 days if you do not purchase this quote",
          phone: 'To stay in touch, may I have your phone number?',
          state: 'Do you have a specific state or province?',
          title: 'May I have your title, please?',
          address: 'Please provide your address.',
          lastname: 'What is your last name?',
          postcode: 'Finally, what is your postcode?',
          birthdate:
            'Great! What is your birthdate? Please provide it in MM/DD/YYYY format.',
          firstname:
            "Thank you, [Prospect's Title]. Could you provide your first name?",
          addressLine2: 'If applicable, provide your address line 2.',
          ageOtherTraveller: 'Great! What is his/her age?',
          addMedicalConditions:
            'Please ensure you declare your pre-existing medical conditions. We cannot provide cover for your pre-existing medical conditions unless you declare these for assessment.',
          lastnameOtherTraveller: 'What is his/her last name?',
          firstnameOtherTraveller:
            "Could you provide name of the [Traveller's number] traveller?",
          preexistingMedicalConditions1:
            'Do you have any Pre-Existing Medical Conditions to disclose?',
          preexistingMedicalConditions2:
            'Are you waiting for hospital investigation or treatment for a condition or set of symptoms that are currently undiagnosed, or traveling to get treatment abroad?',
          preexistingMedicalConditions3:
            'Are you traveling against the advice of a medical practitioner or have you been given a terminal diagnosis and the terminal prognosis is expected prior to the trip end date?',
          preexistingMedicalConditions4: 'Any heart related condition?',
          preexistingMedicalConditions5: 'Any blood circulatory condition?',
          preexistingMedicalConditions6: 'Any diabetic condition?',
          preexistingMedicalConditions7: 'Any breathing condition?',
          preexistingMedicalConditions8:
            'Any neurological condition, (including stroke, brain haemorrhage or epilepsy)?',
          preexistingMedicalConditions9: 'Any arthritic condition?',
          preexistingMedicalConditions10: 'Any back condition?',
          preexistingMedicalConditions11: 'Osteoporosis?',
          preexistingMedicalConditions12:
            'Any bowel condition (including Crohns disease or IBS)?',
          preexistingMedicalConditions13:
            'Any psychiatric or psychological conditions (including anxiety or depression)?',
          preexistingMedicalConditions14: 'Any cancerous condition?',
          preexistingMedicalConditions15:
            'At any time in the past 12 months, have you or any person insured on the policy been referred to or seen by a hospital doctor, specialist or surgeon (other than an A&E doctor), or needed in-patient treatment in hospital?',
        },
        id: 11,
      },
      id: 4,
    },
    {
      label: 'Quotation Recap Declarations',
      keyName: 'quotationRecapDeclarations',
      stepNameApi: 'DETAIL',
      component: 'QuotationRecapDeclarations',
      sendQuoteEmailEnabled: true,
      locale: 'en',
      cards: [
        {
          label: 'Consents',
          keyName: 'consents',
          locale: 'en',
          options: [
            {
              label: 'COVID19_POLICY_ACKNOWLEDGMENT',
              locale: 'en',
              description:
                'When buying a Silver and Gold policy, I acknowledge these policies include certain coverage for Covid-19 related expenses. However, this coverage is limited to the \\"Covid-19 expenses\\" sections in the Combined Product Disclosure Statement and Financial Service Guide and all other coverage for Covid-19 related events will be excluded. No coverage for Covid-19 related events is available on a Bare Essentials policy.',
              usageType: 'COVID19_POLICY_ACKNOWLEDGMENT',
              agentExclusive: false,
              version: 1,
              group: true,
              isOptional: false,
              channel: 'Web',
              id: 19,
            },
            {
              label: 'MEDICAL_CONDITION_DECLARATION',
              locale: 'en',
              description:
                'I/we have declared all pre-existing medical conditions and understood the terms and conditions regarding medical conditions.',
              usageType: 'MEDICAL_CONDITION_DECLARATION',
              agentExclusive: false,
              version: 1,
              group: true,
              isOptional: false,
              channel: 'Web',
              id: 20,
            },
            {
              label: 'DOCUMENTS_ACCEPTANCE',
              locale: 'en',
              description:
                "I/we considered and accept InsureandGo Australia's Combined Product Disclosure Statement, Financial Services Guide and Policy Wording as well as the Target Market Determination Documents.",
              usageType: 'DOCUMENTS_ACCEPTANCE',
              agentExclusive: false,
              version: 1,
              group: true,
              isOptional: false,
              channel: 'Web',
              id: 21,
            },
            {
              label: 'RESIDENCY_DECLARATION',
              locale: 'en',
              description:
                'I/we are residents of Australia or non-permanent residents who have Medicare, Private Health Fund or Overseas Student travel insurance.',
              usageType: 'RESIDENCY_DECLARATION',
              agentExclusive: false,
              version: 1,
              group: true,
              isOptional: false,
              channel: 'Web',
              id: 22,
            },
            {
              label: 'JOURNEY_STATUS_DECLARATION',
              locale: 'en',
              description:
                'I/we are currently in Australia and have not commenced our journey.',
              usageType: 'JOURNEY_STATUS_DECLARATION',
              agentExclusive: false,
              version: 1,
              group: true,
              isOptional: false,
              channel: 'Web',
              id: 23,
            },
            {
              label: 'TRAVEL_RESTRICTIONS_DECLARATION',
              locale: 'en',
              description:
                'I/we will not travel to or in Afghanistan, Antarctica, Belarus, Chad, Crimea, Cuba, Democratic Republic of Congo, East Timor, Haiti, Iran, Iraq, Lebanon, Liberia, Myanmar, North Korea, Pakistan, Russia, Somalia, Sudan, Syria, Uganda, Ukraine, Venezuela, Yemen, and Zimbabwe.',
              usageType: 'TRAVEL_RESTRICTIONS_DECLARATION',
              agentExclusive: false,
              version: 1,
              group: true,
              isOptional: false,
              channel: 'Web',
              id: 24,
            },
            {
              label: 'PERSONAL_DATA_CONSENT',
              locale: 'en',
              description:
                'I/we consent to InsureandGo Australia collecting, holding, using, and disclosing my/our personal information (including using my/our personal information to send marketing communications to me/us for an indefinite period) in accordance with the terms of InsureandGo Australia’s Privacy Statement.',
              usageType: 'PERSONAL_DATA_CONSENT',
              agentExclusive: false,
              version: 1,
              group: true,
              isOptional: false,
              channel: 'Web',
              id: 25,
            },
            {
              label: 'COMM_PROMOTIONS_ACCEPTANCE',
              locale: 'en',
              description:
                'Are you happy to receive further communications and promotions from InsureandGo Australia?',
              usageType: 'COMM_PROMOTIONS_ACCEPTANCE',
              agentExclusive: false,
              version: 1,
              group: false,
              isOptional: false,
              channel: 'Portal',
              id: 26,
            },
            {
              label: 'SUMMARY_BOX_CONFIRMATION',
              locale: 'en',
              description:
                'Please check the information contained in the Summary box on the right-hand side before proceeding.',
              usageType: 'SUMMARY_BOX_CONFIRMATION',
              agentExclusive: true,
              version: 1,
              group: false,
              isOptional: false,
              channel: 'Portal',
              id: 27,
            },
          ],
          agentScripts: null,
          id: 13,
        },
      ],
      agentScripts: {
        text: "As part of our travel insurance process, I'll quickly go over some important consents we need from you. These consents ensure you're fully informed and protected. Please let me know if you have any questions or concerns as we go through them.",
        id: 12,
      },
      id: 6,
    },
    {
      label: 'Quotation Confirmation',
      keyName: 'quotationConfirmation',
      stepNameApi: 'DETAIL',
      component: 'QuotationConfirmation',
      sendQuoteEmailEnabled: false,
      locale: 'en',
      additionalData: {
        text: {
          title: 'Thank you for purchasing your travel insurance from us',
          subtitle1: 'Your documents',
          subtitle2: 'Financial Hardship Support',
          description1:
            'To download your policy documents, please click the links below:',
          description2:
            'We recognize that you may face unexpected situations where you may have difficulty in meeting your financial obligations. Please find here our Internal Financial Hardship section where you can understand the options available and apply for further support.',
          onlineClaimsUrl: 'https://example.com',
          policyWordingUrl: 'https://example.com',
          onlineClaimsTitle: 'Online claims',
          policyWordingTitle: 'Policy Wording Booklet',
          onlineClaimsManagement:
            'Click here to create or manage your claims online:',
          medicalDeclarationTitle: 'Pre-Screening Medical Declaration',
          insuranceCertificateTitle: 'Insurance certificate',
        },
      },
      cards: [],
      agentScripts: null,
      id: 5,
    },
  ],
  validation: {
    age: [
      {
        catalog: 'B2C-AUS-ST.ESHOP',
        firstTravellersAgeValidation: {
          max: '100',
          min: '18',
        },
        otherTravellersAgeValidation: {
          max: '100',
          min: '0',
        },
      },
      {
        catalog: 'B2C-AUS-CRUISE.ESHOP',
        firstTravellersAgeValidation: {
          max: '79',
          min: '18',
        },
        otherTravellersAgeValidation: {
          max: '100',
          min: '0',
        },
      },
    ],
    phoneValidation: [
      {
        length: '10',
        catalog: 'B2C-AUS-ST.ESHOP',
      },
      {
        length: '10',
        catalog: 'B2C-AUS-CRUISE.ESHOP',
      },
    ],
    startDateValidation: [
      {
        catalog: 'B2C-AUS-ST.ESHOP',
        ageLimit: '100',
        maxIfYoungerThanAgeLimit: '270',
        maxIfOlderThanAgeLimit: '270',
      },
      {
        catalog: 'B2C-AUS-CRUISE.ESHOP',
        ageLimit: '75',
        maxIfYoungerThanAgeLimit: '270',
        maxIfOlderThanAgeLimit: '120',
      },
    ],
    postalCodeValidation: [
      {
        length: '4',
        catalog: 'B2C-AUS-ST.ESHOP',
      },
      {
        length: '4',
        catalog: 'B2C-AUS-CRUISE.ESHOP',
      },
    ],
    medicalScoreValidation: [
      {
        max: '15',
        catalog: 'B2C-AUS-ST.ESHOP',
      },
      {
        max: '15',
        catalog: 'B2C-AUS-CRUISE.ESHOP',
      },
    ],
    tripDurationValidation: [
      {
        min: '3',
        catalog: 'B2C-AUS-ST.ESHOP',
        ageLimit: '65',
        maxIfOlderThanAgeLimit: '90',
        maxIfYoungerThanAgeLimit: '365',
      },
      {
        min: '3',
        catalog: 'B2C-AUS-CRUISE.ESHOP',
        ageLimit: '65',
        maxIfOlderThanAgeLimit: '90',
        maxIfYoungerThanAgeLimit: '365',
      },
    ],
    ageOfMajorityValidation: [
      {
        age: '18',
        catalog: 'B2C-AUS-ST.ESHOP',
      },
      {
        age: '18',
        catalog: 'B2C-AUS-CRUISE.ESHOP',
      },
    ],
    numberOfTravellersValidation: [
      {
        max: '10',
        catalog: 'B2C-AUS-ST.ESHOP',
      },
      {
        max: '10',
        catalog: 'B2C-AUS-CRUISE.ESHOP',
      },
    ],
  },
  abandonReasons: [
    {
      abandonReasonId: 'Quote - Agent Error/Mistake',
      label: 'Quote - Agent Error/Mistake',
      locale: 'en',
      id: 1,
    },
    {
      abandonReasonId: 'Quote - Too expensive',
      label: 'Quote - Too expensive',
      locale: 'en',
      id: 23,
    },
    {
      abandonReasonId: 'Quote - Cheaper online',
      label: 'Quote - Cheaper online',
      locale: 'en',
      id: 39,
    },
    {
      abandonReasonId: 'Cover - Not covered for more than 31 days (OAP)',
      label: 'Cover - Not covered for more than 31 days (OAP)',
      locale: 'en',
      id: 38,
    },
    {
      abandonReasonId: 'Cover - Over 75',
      label: 'Cover - Over 75',
      locale: 'en',
      id: 37,
    },
    {
      abandonReasonId: 'Cover - Will not cover scheduled/connecting flights',
      label: 'Cover - Will not cover scheduled/connecting flights',
      locale: 'en',
      id: 36,
    },
    {
      abandonReasonId: 'Cover - Will not cover pregnancy over 26 weeks',
      label: 'Cover - Will not cover pregnancy over 26 weeks',
      locale: 'en',
      id: 35,
    },
    {
      abandonReasonId: 'Cover - Cover not sufficient',
      label: 'Cover - Cover not sufficient',
      locale: 'en',
      id: 34,
    },
    {
      abandonReasonId: 'Cover - Hazardous Activity not covered',
      label: 'Cover - Hazardous Activity not covered',
      locale: 'en',
      id: 33,
    },
    {
      abandonReasonId: 'Cover - Medical conditions not covered',
      label: 'Cover - Medical conditions not covered',
      locale: 'en',
      id: 32,
    },
    {
      abandonReasonId: 'Cover - Exceeds max trip duration',
      label: 'Cover - Exceeds max trip duration',
      locale: 'en',
      id: 31,
    },
    {
      abandonReasonId: 'Cover - Cannot cover age brackets',
      label: 'Cover - Cannot cover age brackets',
      locale: 'en',
      id: 30,
    },
    {
      abandonReasonId: 'Price - Medical charge too high',
      label: 'Price - Medical charge too high',
      locale: 'en',
      id: 29,
    },
    {
      abandonReasonId: 'Price - Unhappy with admin fees',
      label: 'Price - Unhappy with admin fees',
      locale: 'en',
      id: 28,
    },
    {
      abandonReasonId: 'Price - Unable to get discounts',
      label: 'Price - Unable to get discounts',
      locale: 'en',
      id: 27,
    },
    {
      abandonReasonId: 'Price - Change in age bracket',
      label: 'Price - Change in age bracket',
      locale: 'en',
      id: 26,
    },
    {
      abandonReasonId: 'Price - Endorsements too expensive',
      label: 'Price - Endorsements too expensive',
      locale: 'en',
      id: 25,
    },
    {
      abandonReasonId: 'Other - Residency/Not in UK',
      label: 'Other - Residency/Not in UK',
      locale: 'en',
      id: 24,
    },
    {
      abandonReasonId: 'Other - Shopping around for quotes',
      label: 'Other - Shopping around for quotes',
      locale: 'en',
      id: 2,
    },
    {
      abandonReasonId:
        'Other - Need to confirm with other party members/partner',
      label: 'Other - Need to confirm with other party members/partner',
      locale: 'en',
      id: 3,
    },
    {
      abandonReasonId: 'Other - Not travelling or not travelling enough',
      label: 'Other - Not travelling or not travelling enough',
      locale: 'en',
      id: 4,
    },
    {
      abandonReasonId:
        "Other - Free insurance with bank / work / partner's policy",
      label: "Other - Free insurance with bank / work / partner's policy",
      locale: 'en',
      id: 5,
    },
    {
      abandonReasonId: 'Other - Wants to book online only',
      label: 'Other - Wants to book online only',
      locale: 'en',
      id: 6,
    },
    {
      abandonReasonId: 'Other - Does not have payment details to hand',
      label: 'Other - Does not have payment details to hand',
      locale: 'en',
      id: 7,
    },
    {
      abandonReasonId: 'Other - Other reason not listed',
      label: 'Other - Other reason not listed',
      locale: 'en',
      id: 8,
    },
    {
      abandonReasonId: 'Outbound - Wrong contact details',
      label: 'Outbound - Wrong contact details',
      locale: 'en',
      id: 9,
    },
    {
      abandonReasonId: 'Outbound - Already booked insurance (call centre)',
      label: 'Outbound - Already booked insurance (call centre)',
      locale: 'en',
      id: 10,
    },
    {
      abandonReasonId: 'Outbound - Already booked insurance (competitor)',
      label: 'Outbound - Already booked insurance (competitor)',
      locale: 'en',
      id: 11,
    },
    {
      abandonReasonId: 'Service - Not happy with service',
      label: 'Service - Not happy with service',
      locale: 'en',
      id: 12,
    },
    {
      abandonReasonId: 'Service - Not happy with claims',
      label: 'Service - Not happy with claims',
      locale: 'en',
      id: 13,
    },
    {
      abandonReasonId: 'Service - Not happy with auto renewal',
      label: 'Service - Not happy with auto renewal',
      locale: 'en',
      id: 14,
    },
    {
      abandonReasonId: 'Service - Not happy with website',
      label: 'Service - Not happy with website',
      locale: 'en',
      id: 15,
    },
    {
      abandonReasonId: 'Service - Not happy with postal delivery time',
      label: 'Service - Not happy with postal delivery time',
      locale: 'en',
      id: 16,
    },
    {
      abandonReasonId: 'Other - Wants to think about it',
      label: 'Other - Wants to think about it',
      locale: 'en',
      id: 17,
    },
    {
      abandonReasonId: 'Other - Dummy Quote',
      label: 'Other - Dummy Quote',
      locale: 'en',
      id: 18,
    },
    {
      abandonReasonId: 'Payment - Payment Failed (Call Centre Only)',
      label: 'Payment - Payment Failed (Call Centre Only)',
      locale: 'en',
      id: 19,
    },
    {
      abandonReasonId: 'Payment - Payment Failed (Auto Renewals Only)',
      label: 'Payment - Payment Failed (Auto Renewals Only)',
      locale: 'en',
      id: 20,
    },
    {
      abandonReasonId: 'Quote - Cannot answer all medical questions',
      label: 'Quote - Cannot answer all medical questions',
      locale: 'en',
      id: 21,
    },
    {
      abandonReasonId: 'Other - Awaiting EA Referral Decision',
      label: 'Other - Awaiting EA Referral Decision',
      locale: 'en',
      id: 22,
    },
  ],
  promotionCodes: [
    {
      name: 'Captain5 - 5% discount',
      value: 'captain5',
      discount: 5,
      id: 2,
    },
    {
      name: 'Captain’s Pack 10%',
      value: 'captain10',
      discount: 5,
      id: 1,
    },
  ],
  discountReasons: [
    {
      label: 'Staff Discount',
      value: 'StaffDiscount',
      isActive: true,
      id: 6,
    },
    {
      label: 'Price Match',
      value: 'PriceMatch',
      isActive: true,
      id: 1,
    },
    {
      label: 'Outbound Sale',
      value: 'OutboundSale',
      isActive: true,
      id: 3,
    },
    {
      label: 'Goodwill Gesture',
      value: 'GoodwillGesture',
      isActive: true,
      id: 4,
    },
    {
      label: "Friends and Family'",
      value: 'FriendsAndFam',
      isActive: true,
      id: 2,
    },
    {
      label: 'Auto Renewals Discount',
      value: 'AutoRenewalsDisc',
      isActive: true,
      id: 5,
    },
    {
      label: 'Other Reason',
      value: 'OtherReason',
      isActive: true,
      id: 7,
    },
  ],
  layout: 'CARD_UX_WEB',
  id: 1,
};

export const bookingConfigInit: BookingConfig = {
  [BookingConfigEnum.Brand]: '',
  [BookingConfigEnum.BrandCode]: '',
  [BookingConfigEnum.Locale]: '',
  [BookingConfigEnum.DateFormat]: '',
  [BookingConfigEnum.DateTimeFormat]: '',
  [BookingConfigEnum.BirthdayFormat]: '',
  [BookingConfigEnum.B2cUrl]: '',
  [BookingConfigEnum.PdsUrl]: '',
  [BookingConfigEnum.DrupalUrl]: '',
  [BookingConfigEnum.Country]: null!,
  [BookingConfigEnum.Channel]: null!,
  [BookingConfigEnum.Currency]: null!,
  [BookingConfigEnum.DataCenter]: null!,
  [BookingConfigEnum.ClientId]: '',
  [BookingConfigEnum.ClientSecret]: '',
  [BookingConfigEnum.Catalogs]: [],
  [BookingConfigEnum.Type]: ConfigTypeEnum.RETAIL,
  [BookingConfigEnum.BookingSteps]: [],
  [BookingConfigEnum.BookingFieldsApi]: [],
  [BookingConfigEnum.Validation]: BookingConfigIgo.validation,
  [BookingConfigEnum.AbandonReasons]: BookingConfigIgo.abandonReasons,
  [BookingConfigEnum.PromotionCodes]: BookingConfigIgo.promotionCodes,
  [BookingConfigEnum.DiscountReasons]: BookingConfigIgo.discountReasons,
  [BookingConfigEnum.Layout]: BookingConfigIgo.layout,
  [BookingConfigEnum.PaymentProvider]: '',
  [BookingConfigEnum.PaymentConfig]: null!,
};
