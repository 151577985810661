import React, { useEffect, useState } from 'react';
import './App.scss';
import { CssBaseline, Theme, ThemeProvider } from '@mui/material';
import BookingWidget from './components/BookingWidget/BookingWidget';
import { BookingConfigContextProvider } from './context/bookingConfigContext/bookingConfigContext';
import createAppTheme from './themes/theme';
import defaultBrand from './themes/brand-default.scss';
import { BookingContextProvider } from './context/bookingContext/bookingContext';
import Spinner from './shared/components/Loading/Spinner';
import { LoadingProvider } from './context/loadingContext/loadingContext';
import { NotificationProvider } from './shared/components/Notification/NotificationContext';
import { AgentConfigContextProvider } from './context/agentContext/agentConfigContext';
import { BookingQuoteContextProvider } from './context/quoteContext/quoteContext';
import { BookingDataContextProvider } from './context/bookingDataContext/bookingDataContext';
import { HomeMedicalProvider } from './context/homeMedicalContext/homeMedicalContext';
import { DiscountProvider } from './context/discountContext/discountContext';
import '@fontsource/roboto';
import useFetchBookingStyles from './shared/services/useFetchStylesConf';
import { AppConfigurationContextProvider } from './context/appConfigurationContext/appConfigurationContext';
import getUrlSearchParam from './shared/helpers/urlSearchParams.helper';
import { StepperContextProvider } from './context/stepperContext/stepperContext';
import { CacheDataProvider } from './context/cacheContext/cacheContext';
import { PriceSummaryProvider } from './context/priceSummaryContext/priceSummaryContext';
import { SessionProvider } from './context/sessionContext/sessionContext';

export const ThemeContext = React.createContext(null);
interface ParentScrolledEvent extends Event {
  data: {
    scrollPosition: number;
    topDistance?: number;
  };
}

const setStyleColors = (colors: any): void => {
  const root = document.documentElement;
  root.style.setProperty('--primary', colors.default.primary);
  root.style.setProperty('--secondary-light', colors.default.secondaryLight);
  root.style.setProperty('--text-primary', colors.default.textPrimary);
  root.style.setProperty('--dark-primary', colors.default.darkPrimary);
  root.style.setProperty('--dark-middle', colors.default.darkMiddle);
  root.style.setProperty('--dark-secondary', colors.default.darkSecondary);
  root.style.setProperty('--light-primary', colors.default.lightPrimary);
  root.style.setProperty('--light-secondary', colors.default.lightSecondary);
  root.style.setProperty('--contrast-primary', colors.default.contrastPrimary);
  root.style.setProperty('--selected-card', colors.default.selectedCard);
  root.style.setProperty('--secondary', colors.default.secondary);
  root.style.setProperty('--red-dark-primary', colors.default.rdp);
  root.style.setProperty(
    '--contrast-secondary',
    colors.default.contrastSecondary,
  );
};
const setStyleColorsApi = (colors: any): void => {
  const root = document.documentElement;
  Object.entries(colors).forEach(([key, value]) => {
    root.style.setProperty(`--${key}`, value as string);
  });
};

const setFontStyle = (brand: string): void => {
  import(`./themes/fonts/${brand}/fonts.scss`);
};

function App(): JSX.Element {
  const [theme, setTheme] = useState<Theme>(createAppTheme(defaultBrand, ''));
  const { styleConfig } = useFetchBookingStyles();
  useEffect((): void => {
    const urlParams: URLSearchParams = new URLSearchParams(
      window.location.search,
    );
    const brand: string | null = getUrlSearchParam('brand', urlParams);
    localStorage.setItem('language', 'en');
    // TODO: Fix the Strapi styles config for each brand. Ensure createAppThemeAPI returns the correct theme.
    // based on the brand
    import('./themes/brand-default.scss').then((colors): void => {
      setTheme(createAppTheme(colors.default, ''));
      setStyleColors(colors);
    });
    // if (brand && styleConfig && !isAgent() && !brand.includes('wl')) {
    //   setFontStyle(brand);
    //   setTheme(
    //     createAppThemeAPI(
    //       styleConfig[BookingStylesEnum.Styles][BookingStylesEnum.Colors],
    //       brand,
    //     ),
    //   );
    //   setStyleColorsApi(
    //     styleConfig[BookingStylesEnum.Styles][BookingStylesEnum.Colors],
    //   );
    // } else {
    //   import('./themes/brand-default.scss').then((colors): void => {
    //     setTheme(createAppTheme(colors.default, ''));
    //     setStyleColors(colors);
    //   });
    // }
  }, [styleConfig]);

  useEffect(() => {
    const listenerFunction = (event: ParentScrolledEvent): void => {
      const { scrollPosition, topDistance } = event.data;
      const rightPanelStickyContainer =
        document.getElementsByClassName('sticky-container')[0];

      if (
        scrollPosition === undefined ||
        rightPanelStickyContainer === undefined
      ) {
        return;
      }

      if (scrollPosition === 0) {
        rightPanelStickyContainer.removeAttribute('style');
        return;
      }

      const marginTop = scrollPosition - (topDistance ?? 0);
      rightPanelStickyContainer.setAttribute(
        'style',
        `top: ${marginTop}px !important; transition: top .5s .1s`,
      );
    };

    window.addEventListener('message', listenerFunction);

    return () => {
      window.removeEventListener('message', listenerFunction);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LoadingProvider>
        <CacheDataProvider>
          <SessionProvider>
            <BookingConfigContextProvider>
              <BookingQuoteContextProvider>
                <BookingDataContextProvider>
                  <NotificationProvider>
                    <BookingContextProvider>
                      <DiscountProvider>
                        <PriceSummaryProvider>
                          <HomeMedicalProvider>
                            <AgentConfigContextProvider>
                              <AppConfigurationContextProvider>
                                <StepperContextProvider>
                                  <CssBaseline enableColorScheme />
                                  <Spinner />
                                  <BookingWidget />
                                </StepperContextProvider>
                              </AppConfigurationContextProvider>
                            </AgentConfigContextProvider>
                          </HomeMedicalProvider>
                        </PriceSummaryProvider>
                      </DiscountProvider>
                    </BookingContextProvider>
                  </NotificationProvider>
                </BookingDataContextProvider>
              </BookingQuoteContextProvider>
            </BookingConfigContextProvider>
          </SessionProvider>
        </CacheDataProvider>
      </LoadingProvider>
    </ThemeProvider>
  );
}

export default App;
