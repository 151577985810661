import {
  BookingSubStep,
  BookingSubStepEnum,
  BookingSubStepOption,
} from '../../components/BookingWidget/bookingSteps.interface';
import { BookingStepsContextState } from './bookingContext.types';

export const getDefaultValuesForAddonsAndOptions = (
  options: BookingSubStepOption[],
  bookingSteps?: BookingStepsContextState,
): { [key: string]: boolean | undefined } => {
  if (!options) {
    return {};
  }

  const result: { [key: string]: boolean | undefined } = {};
  const addons = bookingSteps?.quotationProposals?.addons || {};

  options.forEach((o) => {
    // If the value exists in addons and is true, don't modify it
    if (addons[o.value] === true) {
      result[o.value] = true;
    } else {
      result[o.value] = o.isDefault ? true : undefined;
    }
  });

  return result;
};

export const getSelectedCountries = (
  countries: BookingSubStepOption[],
): { label: string; value: string }[] =>
  countries.map((option: BookingSubStepOption) => ({
    label: option.label,
    value: option.value,
  }));
