import { MedicalCondition } from './medicalScreening.interface';
import { GetQuoteResponse } from '../../../shared/interfaces/getQuote.interface';
import { QuotePlugAndSell } from '../../../shared/interfaces/plugAndSell/quote.interface';
import { BookingQuoteInsurance } from './bookingQuote.interface';

export enum BookingQuoteResponseQuoteProductPriceEnum {
  Currency = 'currency',
  Tax = 'tax',
  GrossPremium = 'grossPremium',
  NetPrice = 'netPrice',
  Commission = 'commission',
  Promotion = 'promotion',
  GrossPremiumBeforePromotion = 'grossPremiumBeforePromotion',
}

export interface BookingQuoteResponseQuoteProductPrice {
  [BookingQuoteResponseQuoteProductPriceEnum.Currency]: string;
  [BookingQuoteResponseQuoteProductPriceEnum.Tax]: number;
  [BookingQuoteResponseQuoteProductPriceEnum.GrossPremium]: number;
  [BookingQuoteResponseQuoteProductPriceEnum.NetPrice]: number;
  [BookingQuoteResponseQuoteProductPriceEnum.Commission]: number;
  [BookingQuoteResponseQuoteProductPriceEnum.Promotion]: boolean;
  [BookingQuoteResponseQuoteProductPriceEnum.GrossPremiumBeforePromotion]: number;
}

export enum BookingQuoteResponseQuoteProductEnum {
  Price = 'price',
  Code = 'code',
}

export interface BookingQuoteResponseQuoteProduct {
  [BookingQuoteResponseQuoteProductEnum.Price]: BookingQuoteResponseQuoteProductPrice;
  [BookingQuoteResponseQuoteProductEnum.Code]: string;
}
export enum BookingQuoteResponseQuoteGenericDataEnum {
  Brand = 'brand',
  CancellationLimit = 'cancellationLimit',
  DestinationCountry = 'destinationCountry',
  InsuranceAddons = 'insuranceAddons',
  InsuranceOptions = 'insuranceOptions',
  PartnerCode = 'partnerCode',
}
export interface BookingQuoteResponseQuoteGenericData {
  [BookingQuoteResponseQuoteGenericDataEnum.Brand]: string;
  [BookingQuoteResponseQuoteGenericDataEnum.CancellationLimit]: string;
  [BookingQuoteResponseQuoteGenericDataEnum.DestinationCountry]: string;
  [BookingQuoteResponseQuoteGenericDataEnum.InsuranceAddons]: string;
  [BookingQuoteResponseQuoteGenericDataEnum.InsuranceOptions]: string;
  [BookingQuoteResponseQuoteGenericDataEnum.PartnerCode]: string;
}

export enum BookingQuoteResponseQuoteEnum {
  QuoteId = 'quoteId',
  PolicyId = 'policyId',
  QuotationStep = 'quotationStep',
  Product = 'product',
  GenericData = 'genericData',
}

export interface BookingQuoteResponseQuote {
  [BookingQuoteResponseQuoteEnum.QuoteId]: string;
  [BookingQuoteResponseQuoteEnum.PolicyId]: string;
  [BookingQuoteResponseQuoteEnum.QuotationStep]: string;
  [BookingQuoteResponseQuoteEnum.Product]: BookingQuoteResponseQuoteProduct;
  [BookingQuoteResponseQuoteEnum.GenericData]: BookingQuoteResponseQuoteGenericData;
}

export enum BookingQuoteResponseEnum {
  RequestId = 'requestId',
  Quotes = 'quotes',
  Quote = 'quote',
  Insurance = 'insurance',
  AccessToken = 'accessToken',
}

export interface BookingQuoteResponse {
  [BookingQuoteResponseEnum.RequestId]: string;
  [BookingQuoteResponseEnum.AccessToken]: string;
  [BookingQuoteResponseEnum.Quotes]: BookingQuoteResponseQuote[];
  [BookingQuoteResponseEnum.Quote]: QuotePlugAndSell;
  [BookingQuoteResponseEnum.Insurance]: BookingQuoteInsurance;
}

export enum BookingQuoteWithMedicalResponseEnum {
  QuoteResult = 'quoteResult',
  MedicalConditionsResult = 'medicalConditionsResult',
}

export interface BookingQuoteWithMedicalResponse {
  [BookingQuoteWithMedicalResponseEnum.QuoteResult]: GetQuoteResponse;
  [BookingQuoteWithMedicalResponseEnum.MedicalConditionsResult]: (MedicalCondition | null)[];
}
