import { format } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import { BookingStepsContextState } from '../../context/bookingContext/bookingContext.types';
import {
  AdditionalDataEnum,
  BookingConfig,
  BookingConfigEnum,
  BookingStepEnum,
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from '../../components/BookingWidget/bookingSteps.interface';
import {
  BookingQuoteBeneficiaries,
  BookingQuoteBeneficiariesEnum,
} from '../../components/BookingWidget/BookingQuoteManagment/bookingQuote.interface';
import {
  FormQuotationInformationEnum,
  FormQuotationInformationPersonalData,
  FormQuotationInformationTraveller,
  FormQuotationInformationTravellerEnum,
  FormQuotationInformationTravellerList,
} from '../../components/QuotationInformationForm/quoation-information.interface';
import getTravellersAge, {
  getAgeForTraveller,
  getBirthdateForTraveller,
  getBirthdateFromAge,
} from './getTravellersAge';
import parseStringToDate from './date.helper';
import { ApiDateFormat } from '../consts/app.const';
import { BookingDataResponse } from '../../components/BookingWidget/BookingManagment/bookingManagment.interface';
import getBookingStepIndex from './getBookingStepIndex.helper';

const ageString = 'travellerAge';
const firstNameString = 'travellerFirstName';
const lastNameString = 'travellerLastName';

const getBeneficiariesArray = (
  bookingStepsContext: BookingStepsContextState,
  bookingConfig: BookingConfig,
  bookingDataResponse: BookingDataResponse | null = null,
): BookingQuoteBeneficiaries[] => {
  const quotationInformation =
    bookingStepsContext[BookingStepValueEnum.QuotationInformation];
  const quotationForm = bookingStepsContext[BookingStepValueEnum.QuotationForm];
  const travellersList =
    quotationInformation[BookingSubStepValueEnum.InformationTravellers];
  const informationPersonal =
    quotationInformation[BookingSubStepValueEnum.InformationPersonal];
  const travellersAge = getTravellersAge(
    quotationForm[BookingSubStepValueEnum.TravellersAge],
  );
  const companions = bookingDataResponse?.companions;
  const isPolicyHolderTravellingValue =
    bookingStepsContext[BookingStepValueEnum.QuotationInformation][
      BookingSubStepValueEnum.IsPolicyHolderTravelling
    ];

  if (
    (!informationPersonal ||
      !informationPersonal[FormQuotationInformationEnum.Birthdate]) &&
    travellersList === null
  ) {
    return travellersAge.map((t, i) => ({
      ...t,
      ...(companions &&
        companions[i]?.id && {
          [BookingQuoteBeneficiariesEnum.IdNumber]: companions[i].id,
        }),
    }));
  }
  const beneficiaries: BookingQuoteBeneficiaries[] = [];

  const QuotationInformationStepIndex = getBookingStepIndex(
    bookingConfig,
    BookingStepValueEnum.QuotationInformation,
  );

  const isPolicyHolderAsFirstTraveller =
    isPolicyHolderTravellingValue ||
    (bookingConfig[BookingConfigEnum.BookingSteps][
      QuotationInformationStepIndex
    ][BookingStepEnum.AdditionalData]?.[
      AdditionalDataEnum.PolicyHolderIsTraveller
    ] ??
      true);

  const hasRequiredField = (
    field: keyof FormQuotationInformationPersonalData,
  ): boolean => !!informationPersonal[field];

  const getFieldValueOrDefault = (
    fieldType:
      | typeof firstNameString
      | typeof lastNameString
      | typeof ageString,
    index: number,
  ): string => {
    const fieldKey: keyof FormQuotationInformationTravellerList =
      `${fieldType}${index}` as keyof FormQuotationInformationTravellerList;
    return travellersList[fieldKey] || '';
  };

  let i = 1;
  beneficiaries.push({
    [BookingQuoteBeneficiariesEnum.BirthDate]:
      isPolicyHolderAsFirstTraveller &&
      hasRequiredField(FormQuotationInformationEnum.Birthdate)
        ? format(
            parseStringToDate(
              informationPersonal[FormQuotationInformationEnum.Birthdate],
            ),
            ApiDateFormat,
          )
        : getBirthdateForTraveller(
            travellersList[
              `${ageString}${0}`.toString() as keyof FormQuotationInformationTravellerList
            ],
          ),
    [BookingQuoteBeneficiariesEnum.Firstname]:
      isPolicyHolderAsFirstTraveller &&
      hasRequiredField(FormQuotationInformationEnum.Firstname)
        ? informationPersonal[FormQuotationInformationEnum.Firstname]
        : getFieldValueOrDefault(firstNameString, 0),
    [BookingQuoteBeneficiariesEnum.Lastname]:
      isPolicyHolderAsFirstTraveller &&
      hasRequiredField(FormQuotationInformationEnum.Lastname)
        ? informationPersonal[FormQuotationInformationEnum.Lastname]
        : getFieldValueOrDefault(lastNameString, 0),
    [BookingQuoteBeneficiariesEnum.Subscriber]:
      !!isPolicyHolderAsFirstTraveller,
    ...(companions &&
      companions[0].id && {
        [BookingQuoteBeneficiariesEnum.IdNumber]: companions[0].id,
      }),
  });
  while (i < 11) {
    const keyAge: string = `${ageString}${i}`.toString();
    const keyFirstName: string = `${firstNameString}${i}`.toString();
    const keyLastName: string = `${lastNameString}${i}`.toString();
    if (
      travellersList[keyAge as keyof FormQuotationInformationTravellerList] &&
      travellersAge[i]
    ) {
      beneficiaries.push({
        [BookingQuoteBeneficiariesEnum.BirthDate]: getBirthdateForTraveller(
          travellersList[keyAge as keyof FormQuotationInformationTravellerList],
        ),
        [BookingQuoteBeneficiariesEnum.Firstname]: getFieldValueOrDefault(
          firstNameString,
          i,
        ),
        [BookingQuoteBeneficiariesEnum.Lastname]: getFieldValueOrDefault(
          lastNameString,
          i,
        ),
        [BookingQuoteBeneficiariesEnum.Subscriber]: false,
        ...(companions &&
          companions[i]?.id && {
            [BookingQuoteBeneficiariesEnum.IdNumber]: companions[i]?.id,
          }),
      });
    } else if (travellersAge[i]) {
      beneficiaries.push({
        ...travellersAge[i],
        ...(companions &&
          companions[i]?.id && {
            [BookingQuoteBeneficiariesEnum.IdNumber]: companions[i].id,
          }),
      });
    }
    // eslint-disable-next-line no-plusplus
    i++;
  }
  return beneficiaries;
};

export const getBeneficiariesListFromTravellersArray = (
  travelers: FormQuotationInformationTraveller[],
  travellerList: FormQuotationInformationTravellerList,
  policyHolderIsTraveller: boolean,
): FormQuotationInformationTravellerList => {
  const newTravellerList: FormQuotationInformationTravellerList = {};
  travelers.forEach(
    (traveller: FormQuotationInformationTraveller, index: number) => {
      const travellerIndex = policyHolderIsTraveller ? index + 1 : index;
      newTravellerList[
        `travellerFirstName${travellerIndex}` as keyof FormQuotationInformationTravellerList
      ] = traveller[FormQuotationInformationTravellerEnum.Firstname];
      newTravellerList[
        `travellerLastName${travellerIndex}` as keyof FormQuotationInformationTravellerList
      ] = traveller[FormQuotationInformationTravellerEnum.Lastname];
      if (traveller.birthDate) {
        newTravellerList[
          `travellerAge${travellerIndex}` as keyof FormQuotationInformationTravellerList
        ] = traveller[FormQuotationInformationTravellerEnum.BirthDate];
      } else {
        newTravellerList[
          `travellerAge${travellerIndex}` as keyof FormQuotationInformationTravellerList
        ] = traveller[FormQuotationInformationTravellerEnum.Age];
      }
    },
  );
  return { ...travellerList, ...newTravellerList };
};

export default getBeneficiariesArray;
