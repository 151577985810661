import axios from 'axios';
import {
  BookingConfig,
  BookingConfigEnum,
} from '../../components/BookingWidget/bookingSteps.interface';
import { RequestHeaders } from '../interfaces/requestHeaders.interface';
import { useSessionContext } from '../../context/sessionContext/sessionContext';
import ErrorLoggingService from './errorlogging.service';
import { SessionParams } from '../../context/sessionContext/sessionContext.interface';

const useApi = (
  bookingConfigData: BookingConfig,
  sessionParams: SessionParams | null,
): any => {
  const errorService: ErrorLoggingService = ErrorLoggingService.getInstance();
  const apiCM360Url: string =
    bookingConfigData[BookingConfigEnum.DataCenter]?.cm360Endpoint;
  const apiCM360Key: string =
    bookingConfigData[BookingConfigEnum.DataCenter]?.cm360Key;
  const api = axios.create({
    baseURL: apiCM360Url,
  });

  api.interceptors.request.use(
    async (config) => {
      const modifiedConfig = { ...config };
      const token = localStorage.getItem('token');
      const anonymousToken = localStorage.getItem('anonymousToken') || null;
      if (!modifiedConfig.headers[RequestHeaders.X_API_KEY]) {
        modifiedConfig.headers[RequestHeaders.X_API_KEY] = apiCM360Key;
      }
      if (
        !modifiedConfig.headers[RequestHeaders.X_CURRENT_SESSION_CONTEXT] &&
        sessionParams?.sessionToken
      ) {
        modifiedConfig.headers[RequestHeaders.X_CURRENT_SESSION_CONTEXT] =
          sessionParams.sessionToken;
      }
      modifiedConfig.headers[RequestHeaders.CONTENT_TYPE] = 'application/json';

      if (!modifiedConfig.headers.Authorization && (token || anonymousToken)) {
        modifiedConfig.headers.Authorization = token
          ? `Bearer ${token}`
          : `Bearer ${anonymousToken}`;
      }

      return modifiedConfig;
    },
    (error) => Promise.reject(error),
  );

  api.interceptors.response.use(
    async (response) => response,
    (error) => {
      errorService.log(error.message, error);
      return Promise.reject(error);
    },
  );

  return api;
};

export default useApi;
