import { t } from 'i18next';
import { QuotationSummaryProduct } from './QuotationElementBlockPrice';
import { PriceAdditionalValues } from '../../../context/priceSummaryContext/priceSummaryContext';
import { AddonsEnum } from '../../../shared/consts/optionsAndAddons.const';

function parseAddonsToSummaryElement(
  summary: Record<string, boolean | undefined>,
  additionalProductPrices: PriceAdditionalValues | null,
): QuotationSummaryProduct[] {
  const hasPriceForProduct = (key: keyof PriceAdditionalValues): boolean =>
    !!(key && additionalProductPrices?.[key]);

  const getProductPrice = (key: keyof PriceAdditionalValues): string =>
    additionalProductPrices?.[key] || '--';

  const isPriceNonZero = (key: keyof PriceAdditionalValues): boolean =>
    additionalProductPrices?.[key] !== '0.0';

  const shouldIncludeProduct = (
    key: keyof PriceAdditionalValues,
    value: boolean | undefined,
  ): boolean =>
    Boolean(value) && hasPriceForProduct(key) && isPriceNonZero(key);

  const toQuotationSummaryProduct = (
    key: keyof PriceAdditionalValues,
  ): QuotationSummaryProduct => {
    let productName = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();

    if (productName.toLowerCase() === AddonsEnum.VALUABLES.toLowerCase()) {
      productName = t(
        `BOOKING.QUOTATION_SUMMARY_BLOCK.addonsBlock.${productName.toLowerCase()}`,
      );
    }
    if (productName.toLowerCase() === AddonsEnum.CFAR.toLowerCase()) {
      productName = t(
        `BOOKING.QUOTATION_SUMMARY_BLOCK.addonsBlock.${productName.toLowerCase()}`,
      );
    }

    return {
      productName,
      amount: getProductPrice(key),
    };
  };

  return Object.entries(summary)
    .filter(([key, value]) =>
      shouldIncludeProduct(key as keyof PriceAdditionalValues, value),
    )
    .map(([key]) =>
      toQuotationSummaryProduct(key as keyof PriceAdditionalValues),
    );
}
export default parseAddonsToSummaryElement;
