import { InsurancePayload } from '../../shared/interfaces/insurance.interface';
import {
  BookingQuoteBeneficiaries,
  BookingQuoteInsuranceEnum,
  BookingQuoteSubscriber,
} from '../BookingWidget/BookingQuoteManagment/bookingQuote.interface';

enum ProductsEnum {
  Reference = 'reference',
  ProductGroup = 'productGroup',
  Code = 'code',
}

interface Products {
  [ProductsEnum.Reference]: string;
  [ProductsEnum.ProductGroup]: [
    {
      [ProductsEnum.Code]: string;
    },
  ];
}

enum PricingCapturePayloadEnum {
  PSPayload = 'PSPayload',
  QuoteId = 'quoteId',
}

enum PricingPayloadEnum {
  Products = 'products',
  Insurance = 'insurance',
  Subscriber = 'subscriber',
  Beneficiaries = 'beneficiaries',
  Promotion = 'promotion',
  QuoteId = 'quoteId',
}

interface PricingPayload {
  [PricingPayloadEnum.Products]: Products[];
  [PricingPayloadEnum.Insurance]: InsurancePayload;
  [PricingPayloadEnum.Subscriber]: BookingQuoteSubscriber;
  [PricingPayloadEnum.Beneficiaries]: BookingQuoteBeneficiaries[];
  [PricingPayloadEnum.Promotion]: {
    [BookingQuoteInsuranceEnum.PromotionCode]: string;
  };
}

interface PricingCapturePayload {
  [PricingCapturePayloadEnum.PSPayload]: PricingPayload;
  [PricingCapturePayloadEnum.QuoteId]: string;
}

export {
  ProductsEnum,
  PricingPayloadEnum,
  Products,
  PricingPayload,
  PricingCapturePayloadEnum,
  PricingCapturePayload,
};
