import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BookingDataStatus } from '../../BookingWidget/BookingManagment/bookingManagment.interface';
import { BookingDataContext } from '../../../context/bookingDataContext/bookingDataContext';
import { ITransactionResponse } from '../../shared/braintree/payments';
import { BookingContext } from '../../../context/bookingContext/bookingContext';
import usePolicyDataService from '../../../shared/services/policyData.service';
import { BookingQuoteContext } from '../../../context/quoteContext/quoteContext';
import { LoadingContext } from '../../../context/loadingContext/loadingContext';
import { NotificationContext } from '../../../shared/components/Notification/NotificationContext';
import { getQuoteId } from '../../../shared/helpers/quote.helper';
import { BookingStepValueEnum } from '../../BookingWidget/bookingSteps.interface';
import { Policy } from '../../../shared/interfaces/policyResponse.interface';

interface SummaryBlockData {
  label: string;
  subLabel: string;
  data: string;
}

interface UseFlowSpecificFactory {
  policySaveMethod: (
    transactionResponse: ITransactionResponse,
  ) => Promise<Policy>;
  isPolicyModificationFlow: boolean;
  recapDeclarationsHeader: string;
  submitActionLabel: string;
  summaryBlockData: SummaryBlockData;
}

const useFlowSpecificFactory = (): UseFlowSpecificFactory => {
  const { bookingSteps, bookingReferenceId } = useContext(BookingContext);
  const { bookingQuoteResponse } = useContext(BookingQuoteContext);
  const { bookingDataResponse } = useContext(BookingDataContext);
  const { registerPolicy, modifyPolicy } = usePolicyDataService();
  const { t } = useTranslation();
  const { setIsLoading } = useContext(LoadingContext);
  const { showNotification } = useContext(NotificationContext);

  const isPolicyModificationFlow = useMemo(
    () =>
      bookingDataResponse?.bookingData.status === BookingDataStatus.RECREATED ||
      (!!bookingDataResponse?.bookingData.prevBookingId &&
        bookingDataResponse?.bookingData.status ===
          BookingDataStatus.IN_PROGRESS),
    [bookingDataResponse],
  );

  const handlePolicyRegistration = useCallback(
    async (transactionResponse: ITransactionResponse): Promise<Policy> => {
      setIsLoading(true);
      try {
        return await registerPolicy(
          bookingDataResponse,
          bookingSteps,
          bookingReferenceId,
        );
      } catch (error) {
        showNotification('fetchingData', 'error', false);
        return Promise.reject();
      } finally {
        setIsLoading(false);
      }
    },
    [bookingDataResponse, bookingSteps, registerPolicy, bookingReferenceId],
  );

  const handlePolicyModification = useCallback(
    async (transactionResponse: ITransactionResponse): Promise<Policy> => {
      setIsLoading(true);
      try {
        return await modifyPolicy(
          bookingDataResponse,
          bookingSteps,
          bookingReferenceId,
        );
      } catch (error) {
        showNotification('fetchingData', 'error', false);
        return Promise.reject();
      } finally {
        setIsLoading(false);
      }
    },
    [bookingDataResponse, bookingSteps, modifyPolicy, bookingReferenceId],
  );

  const policySaveMethod = useMemo(
    () =>
      isPolicyModificationFlow
        ? handlePolicyModification
        : handlePolicyRegistration,
    [
      handlePolicyModification,
      handlePolicyRegistration,
      isPolicyModificationFlow,
    ],
  );

  const recapDeclarationsHeader = useMemo(
    () =>
      isPolicyModificationFlow
        ? t('DECLARATIONS.confirmationHeader')
        : t('DECLARATIONS.header'),
    [isPolicyModificationFlow, t],
  );

  const submitActionLabel = useMemo(
    () =>
      isPolicyModificationFlow
        ? t('DECLARATIONS.confirmChanges')
        : t('DECLARATIONS.issuePolicy'),
    [isPolicyModificationFlow, t],
  );

  const summaryBlockData = useMemo(
    () =>
      isPolicyModificationFlow
        ? {
            label: t('BOOKING.POLICY_SUMMARY_BLOCK.title'),
            subLabel: t('BOOKING.POLICY_SUMMARY_BLOCK.policyModification'),
            data:
              bookingSteps[BookingStepValueEnum.QuotationRecapDeclaration]
                ?.policyId ??
              bookingDataResponse?.bookingData?.proxy?.policyId ??
              '',
          }
        : {
            label: t('BOOKING.QUOTATION_SUMMARY_BLOCK.title'),
            subLabel: '',
            data: getQuoteId(bookingQuoteResponse),
          },
    [
      bookingDataResponse?.bookingData?.proxy?.policyId,
      bookingQuoteResponse,
      bookingSteps,
      isPolicyModificationFlow,
    ],
  );

  return {
    isPolicyModificationFlow,
    policySaveMethod,
    recapDeclarationsHeader,
    submitActionLabel,
    summaryBlockData,
  };
};

export default useFlowSpecificFactory;
