import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { AxiosResponse } from 'axios';
import {
  BookingConfigEnum,
  CurrencyEnum,
} from '../../BookingWidget/bookingSteps.interface';
import { LoadingContext } from '../../../context/loadingContext/loadingContext';
import { BookingDataContext } from '../../../context/bookingDataContext/bookingDataContext';
import { BookingConfigContext } from '../../../context/bookingConfigContext/bookingConfigContext';
import { useDiscountDialog } from '../../../context/discountContext/discountContext';
import useApi from '../../../shared/services/api.service';
import { NotificationContext } from '../../../shared/components/Notification/NotificationContext';
import { usePriceSummaryLoading } from '../../../context/priceSummaryContext/priceSummaryContext';
import { useSessionContext } from '../../../context/sessionContext/sessionContext';

const DISCOUNT_LIMIT = 24;

const DiscountDialog = (): React.ReactElement => {
  // context
  const { bookingDataResponse } = useContext(BookingDataContext);
  const { bookingConfigData } = useContext(BookingConfigContext);
  // util
  const { setIsLoading } = useContext(LoadingContext);
  const discountReasons =
    bookingConfigData?.[BookingConfigEnum.DiscountReasons];
  const { showNotification } = useContext(NotificationContext);
  const { t } = useTranslation();
  const { cm360Endpoint } = bookingConfigData[BookingConfigEnum.DataCenter];
  const { sessionParams } = useSessionContext();
  const API = useApi(bookingConfigData, sessionParams);
  const {
    isDiscountDialog,
    setIsDiscountDialog,
    selectedDiscountReason,
    setSelectedDiscountReason,
    setIsPercentageDiscount,
    setDiscountAmount,
    isPercentageDiscount,
    getDiscountStatusHandler,
    discountAmount,
  } = useDiscountDialog();
  const { price } = usePriceSummaryLoading();

  const closeDiscountDialog = (): void => {
    setIsDiscountDialog(false);
  };

  const closeDiscountDialogAndResetForm = (): void => {
    closeDiscountDialog();
    setSelectedDiscountReason('');
    setIsPercentageDiscount(false);
    setDiscountAmount('');
  };

  const discountHandler = (): void => {
    setIsLoading(true);
    if (!bookingDataResponse) {
      setTimeout(() => discountHandler(), 1000);
      return;
    }
    const bookingId = (bookingDataResponse as any).bookingData.id;
    const url = `${cm360Endpoint}/booking/${bookingId}/discount`;
    const payload = {
      reason: selectedDiscountReason,
      countryCode: bookingDataResponse?.bookingData?.countryCode,
      ...(isPercentageDiscount
        ? { percentage: +discountAmount }
        : { fixedAmount: +discountAmount }),
    };
    API.post(url, payload)
      .then((res: AxiosResponse) => {
        if (res.status !== 201) {
          showNotification('unexpectedError', 'error', false);
          setIsLoading(false);
        } else {
          getDiscountStatusHandler();
        }
      })
      .catch(() => {
        showNotification('unexpectedError', 'error', false);
        setIsLoading(false);
      })
      .finally(() => {
        closeDiscountDialog();
      });
  };

  const handleDiscountChange = (event: any): void => {
    const rawValue = event.target.value;

    const cleanedValue = rawValue
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1');

    setDiscountAmount(cleanedValue);
  };

  const validateDiscountAmount = (): string | null => {
    const discountNumber = parseFloat(discountAmount);

    if (isPercentageDiscount && discountNumber > DISCOUNT_LIMIT) {
      return t('DISCOUNT.discountExceedsLimit', { limit: DISCOUNT_LIMIT });
    }

    if (isPercentageDiscount && discountNumber > 100) {
      return t('DISCOUNT.invalidPercentageDiscount');
    }

    if (price && !isPercentageDiscount && discountNumber > +price) {
      return t('DISCOUNT.discountExceedsPrice');
    }

    if (
      price &&
      !isPercentageDiscount &&
      discountNumber > (DISCOUNT_LIMIT / 100) * +price
    ) {
      return t('DISCOUNT.discountExceedsPercentageOfPrice', {
        percentage: DISCOUNT_LIMIT,
      });
    }

    return null; // No errors
  };
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={!!isDiscountDialog}
      onClose={() => {
        closeDiscountDialogAndResetForm();
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          minWidth: '30rem',
          color: 'var(--secondary)',
        }}
      >
        {t('DISCOUNT.header')}
        <IconButton onClick={closeDiscountDialogAndResetForm}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
          <FormControl fullWidth sx={{ minHeight: '64px' }}>
            <TextField
              size="small"
              label={t('DISCOUNT.discountAmountLabel')}
              value={discountAmount}
              onChange={handleDiscountChange}
              variant="outlined"
              error={!!validateDiscountAmount()}
              helperText={validateDiscountAmount()}
            />
          </FormControl>
          <FormControl fullWidth>
            <ToggleButtonGroup
              onChange={(
                event: React.MouseEvent<HTMLElement>,
                value: string,
              ): void => {
                if (typeof value === 'boolean') {
                  setIsPercentageDiscount(value);
                }
              }}
              value={isPercentageDiscount}
              color="primary"
              size="small"
              exclusive
              fullWidth
            >
              <ToggleButton value={false}>
                {
                  bookingConfigData[BookingConfigEnum.Currency][
                    CurrencyEnum.Currency
                  ]
                }
              </ToggleButton>
              <ToggleButton value>%</ToggleButton>
            </ToggleButtonGroup>
          </FormControl>
        </Box>
        <FormControl fullWidth sx={{ mb: 2 }} size="small">
          <InputLabel>{t('DISCOUNT.selectLabel')}</InputLabel>
          <Select
            value={selectedDiscountReason}
            onChange={(e) => setSelectedDiscountReason(e.target.value)}
            label={t('DISCOUNT.selectLabel')}
            defaultValue=""
            required
          >
            {discountReasons
              ?.filter((r) => r.isActive)
              .map((reason, index) => (
                <MenuItem key={index} value={reason.value}>
                  {reason.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Typography variant="body2" color="textSecondary">
          {t('DISCOUNT.discountApprovalThresholdInfo')}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button
          disabled={
            !selectedDiscountReason ||
            !discountAmount ||
            !!validateDiscountAmount()
          }
          onClick={() => discountHandler()}
          color="secondary"
          variant="contained"
        >
          {t('COMMON.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiscountDialog;
