import { Subscriber as SubscriberI } from './subscriber.interfaces';
import { InsurancePayload } from './insurance.interface';
import { BookingQuoteBeneficiaries } from '../../components/BookingWidget/BookingQuoteManagment/bookingQuote.interface';

enum BookingPolicyEnum {
  QuoteId = 'quoteId',
  Beneficiaries = 'beneficiaries',
  Insurance = 'insurance',
  Subscriber = 'subscriber',
  Product = 'product',
  Subscription = 'subscription',
  Promotion = 'promotion',
}

interface BookingPolicy {
  [BookingPolicyEnum.QuoteId]: string;
  [BookingPolicyEnum.Beneficiaries]: BookingQuoteBeneficiaries[];
  [BookingPolicyEnum.Insurance]: InsurancePayload;
  [BookingPolicyEnum.Subscriber]: SubscriberI;
  [BookingPolicyEnum.Product]: Product;
  [BookingPolicyEnum.Subscription]: Subscription;
  [BookingPolicyEnum.Promotion]: Promotion;
}

enum PolicyBeneficiariesEnum {
  CountryCode = 'countryCode',
}
interface PolicyBeneficiaries extends BookingQuoteBeneficiaries {
  [PolicyBeneficiariesEnum.CountryCode]: string;
}

interface Product {
  productGroup: { code: string }[];
}

interface Subscription {
  price: number;
  currency: string;
}

interface Promotion {
  promotionCode: string;
}

export {
  BookingPolicy,
  BookingPolicyEnum,
  Product,
  Subscription,
  PolicyBeneficiariesEnum,
  PolicyBeneficiaries,
  Promotion,
};
