import dayjs, { Dayjs } from 'dayjs';
import { ApiOutputDateFormat } from '../../../shared/consts/app.const';

const formatApiDate = (date: string | Dayjs): string => {
  if (typeof date === 'string') {
    return dayjs(date).format(ApiOutputDateFormat);
  }

  return date.format(ApiOutputDateFormat);
};

export default formatApiDate;
