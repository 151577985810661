import { AxiosResponse } from 'axios';
import { useCallback, useContext } from 'react';
import {
  BookingConfigEnum,
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from '../bookingSteps.interface';
import useApi from '../../../shared/services/api.service';
import { BookingConfigContext } from '../../../context/bookingConfigContext/bookingConfigContext';
import { NotificationContext } from '../../../shared/components/Notification/NotificationContext';
import ErrorLoggingService from '../../../shared/services/errorlogging.service';
import { BookingQuoteResponseQuoteProduct } from '../../QuotationProposals/components/quotationProposal.interface';
import { BookingContext } from '../../../context/bookingContext/bookingContext';
import { useSessionContext } from '../../../context/sessionContext/sessionContext';

interface UseBookingProductSelection {
  selectProduct: (
    bookingId: string,
    productCode: string,
    shouldRedirect?: boolean,
  ) => Promise<BookingQuoteResponseQuoteProduct>;
}

const useBookingProductSelection = (): UseBookingProductSelection => {
  // context
  const { bookingConfigData } = useContext(BookingConfigContext);
  const { update } = useContext(BookingContext);

  // util
  const errorService: ErrorLoggingService = ErrorLoggingService.getInstance();
  const { showNotification } = useContext(NotificationContext);
  const { sessionParams } = useSessionContext();
  const API = useApi(bookingConfigData, sessionParams);
  const apiCM360Url: string =
    bookingConfigData[BookingConfigEnum.DataCenter]?.cm360Endpoint;

  const selectProduct = useCallback(
    async (
      bookingId: string,
      productCode: string,
      shouldRedirect = false,
    ): Promise<BookingQuoteResponseQuoteProduct> => {
      const url = `${apiCM360Url}/booking/${bookingId}/selection`;
      const payload = { productCode };
      const { psClient } = bookingConfigData[BookingConfigEnum.Channel];
      let selectProductResponse: AxiosResponse<BookingQuoteResponseQuoteProduct>;
      try {
        selectProductResponse = await API.post(url, payload, {
          headers: {
            'Client-Id': psClient,
          },
        });
      } catch (e) {
        errorService.log(`Axios error: ${e}`);
        showNotification('unexpectedError', 'error', false);
        return Promise.reject();
      }

      if (selectProductResponse.status !== 201) {
        errorService.log('Axios response is not succeed');
        showNotification('unexpectedError', 'error', false);
      }

      if (shouldRedirect) {
        update(
          1,
          BookingStepValueEnum.QuotationProposals,
          BookingSubStepValueEnum.ActiveSubStep,
        );
      }

      return selectProductResponse.data;
    },
    [API, apiCM360Url, bookingConfigData, showNotification],
  );

  return {
    selectProduct,
  };
};

export default useBookingProductSelection;
