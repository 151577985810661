import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { CacheDataContext } from '../../context/cacheContext/cacheContext';

import './QuotationSummaryMobileBackdrop.scss';
import QuotationSummaryMobile from './QuotationSummaryMobile';

const QuotationSummaryMobileBackdrop: React.FC = () => {
  const { store, setCacheValue } = useContext(CacheDataContext);
  const prevBookingStep = useRef(store.currentStep);

  const attachOverflowHiddenToBody = (): void => {
    document.body.style.overflow = 'hidden';
  };

  const detachOverflowHiddenToBody = (): void => {
    document.body.style.overflow = 'visible';
  };

  const hideBackdrop = useCallback((): void => {
    setCacheValue('mobileSummaryVisible', false);
    setCacheValue('mobileSummaryPosition', null);
  }, [setCacheValue]);

  const destroyBackdrop = useCallback((): void => {
    hideBackdrop();
    detachOverflowHiddenToBody();
  }, [hideBackdrop]);

  const onBackdropClick = ($event: React.MouseEvent): void => {
    $event.stopPropagation();
    hideBackdrop();
  };

  const handleInnerClick = (e: React.MouseEvent | React.TouchEvent): void => {
    e.stopPropagation();
  };

  useEffect(() => {
    attachOverflowHiddenToBody();

    return () => destroyBackdrop();
  }, []);

  useEffect(() => {
    if (store.currentStep !== prevBookingStep.current) {
      hideBackdrop();
    }

    prevBookingStep.current = store.currentStep;
  }, [store.currentStep]);

  const content = useMemo(
    () => (
      <div
        className="backdrop"
        style={{ top: `${store.mobileSummaryPosition}px` }}
        onClick={onBackdropClick}
        role="presentation"
      >
        <div
          className="backdrop-content"
          role="presentation"
          onClick={handleInnerClick}
          onTouchEnd={handleInnerClick}
        >
          <QuotationSummaryMobile />
        </div>
      </div>
    ),
    [onBackdropClick],
  );

  if (!store.mobileSummaryVisible || store.mobileSummaryPosition === null)
    return null;

  return createPortal(content, document.body);
};

export default memo(QuotationSummaryMobileBackdrop);
