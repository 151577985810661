import React, { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Card,
  CardContent,
  ClickAwayListener,
  Typography,
  useTheme,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import LimitedText from '../../shared/components/LimitedText/LimitedText';
import './QuotationSummaryBlock.scss';

export interface QuotationSummaryCardProps extends React.PropsWithChildren {
  mobileTitle?: string | null;
  collapseCustomCssClass?: string;
}

const QuotationSummaryCard: React.FC<QuotationSummaryCardProps> = ({
  children: cardContent,
  mobileTitle,
  collapseCustomCssClass,
}) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [open, setOpen] = useState(!isMobile);

  const handleCollapseClick = (): void => {
    if (isMobile) {
      setOpen(!open);
    }
  };

  const handleCollapseKey = (
    event: React.KeyboardEvent<HTMLDivElement>,
  ): void => {
    if (isMobile) {
      if (event.key === 'ArrowUp') {
        setOpen(false);
      } else if (event.key === 'ArrowDown') {
        setOpen(true);
      }
    }
  };
  return (
    <div>
      <Card className={isMobile ? 'summary-mobile' : 'summary-desktop'}>
        <CardContent
          sx={{
            padding: '0.2rem 0.4rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '.2rem',
          }}
        >
          {cardContent}
        </CardContent>
      </Card>
    </div>
  );
};

QuotationSummaryCard.defaultProps = {
  mobileTitle: '',
  collapseCustomCssClass: '',
};

export default QuotationSummaryCard;
