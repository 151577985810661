import {
  BookingConfig,
  BookingConfigEnum,
  BookingStep,
  BookingStepEnum,
  BookingStepValueEnum,
  BookingSubStep,
  BookingSubStepEnum,
  BookingSubStepValueEnum,
} from '../../components/BookingWidget/bookingSteps.interface';
import { BookingStepsContextState } from '../../context/bookingContext/bookingContext.types';

const getActiveStepConfig = (
  bookingSteps: BookingStepsContextState,
  bookingConfigData: BookingConfig,
  activeStepValue: BookingStepValueEnum = null!,
): BookingStep =>
  bookingConfigData[BookingConfigEnum.BookingSteps].filter(
    (step: BookingStep): boolean => {
      const currentStep =
        activeStepValue || bookingSteps[BookingStepValueEnum.CurrentStep];
      return step[BookingStepEnum.KeyName] === currentStep;
    },
  )[0];

const getActiveCardConfig = (
  bookingSteps: BookingStepsContextState,
  bookingConfigData: BookingConfig,
  cardKey: BookingSubStepValueEnum,
  activeStepValue: BookingStepValueEnum = null!,
): BookingSubStep => {
  const activeStep: BookingStep = getActiveStepConfig(
    bookingSteps,
    bookingConfigData,
    activeStepValue,
  );
  return activeStep[BookingStepEnum.Cards].filter(
    (card: BookingSubStep): boolean =>
      card[BookingSubStepEnum.KeyName] === cardKey,
  )[0];
};

export { getActiveCardConfig, getActiveStepConfig };
