/* eslint-disable react/require-default-props */
import React, { useMemo } from 'react';
import '../QuotationSummaryElements.scss';
import Currency from '../../../shared/components/Currency/Currency';

export interface QuotationSummaryProduct {
  productName?: string;
  isExcluded?: boolean;
  amount?: string;
  currency?: string;
  customTemplate?: React.JSX.Element;
}

export interface QuotationSummaryElementBlockProps {
  label?: string | null;
  product: QuotationSummaryProduct[];
  fontWeightLabel?: 'lighter' | 'normal' | 'bold' | 'bolder';
  fontWeightValue?: 'lighter' | 'normal' | 'bold' | 'bolder';
  color?: string | null;
}

const QuotationElementBlockPrice: React.FC<
  QuotationSummaryElementBlockProps
> = ({
  label,
  product: rawProduct,
  fontWeightLabel,
  fontWeightValue,
  color,
}) => {
  function parseProduct(product: QuotationSummaryProduct): React.JSX.Element {
    const tryParseAmount = (amount: string): [boolean, number] => {
      const formattedAmount = parseFloat(amount);
      const isCorrectValue = !Number.isNaN(formattedAmount);
      return [isCorrectValue, isCorrectValue ? formattedAmount : -1];
    };

    const amountWithCurrency = (): React.JSX.Element | null => {
      if (product.customTemplate) {
        return <span className="description">{product.customTemplate}</span>;
      }

      if (!product.amount) {
        return null;
      }

      const [isValidAmount, amount] = tryParseAmount(product.amount);
      if (product?.isExcluded) {
        return (
          <span>
            Excl. <sup>(1)</sup>
          </span>
        );
      }

      if (!isValidAmount) {
        return <span>--:--</span>;
      }

      const hasOwnCurrencyElement = (
        <span className="description summary">
          {product.currency}
          {amount}
        </span>
      );

      const defaultCurrencyElement = (
        <Currency
          typographyStyle={{
            fontSize: '1rem',
            fontWeight: fontWeightValue,
            color: color || '#161616',
          }}
          value={amount}
        />
      );

      return product.currency ? hasOwnCurrencyElement : defaultCurrencyElement;
    };

    return (
      <div
        className="product-information-wrapper"
        key={(product.productName || '') + (product.amount || 0)}
      >
        <span
          className="description summary"
          style={{ fontWeight: fontWeightLabel, color: color || undefined }}
        >
          {product.productName}
        </span>
        <span style={{ fontWeight: fontWeightValue }}>
          {amountWithCurrency()}
        </span>
      </div>
    );
  }

  const parsedProducts = useMemo(
    (): Array<React.JSX.Element> =>
      rawProduct.map((product: QuotationSummaryProduct) =>
        parseProduct(product),
      ),
    [rawProduct],
  );

  const labelElement = label && <span className="label summary">{label}</span>;

  return (
    <div
      className="summary-element-wrapper"
      style={{ fontWeight: fontWeightLabel }}
    >
      {labelElement}
      {parsedProducts}
    </div>
  );
};

QuotationElementBlockPrice.defaultProps = {
  label: '',
  fontWeightLabel: 'bold',
  fontWeightValue: 'normal',
};

export default QuotationElementBlockPrice;
