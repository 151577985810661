import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { TextField, MenuItem } from '@mui/material';
import {
  BookingConfigEnum,
  BookingConfig,
} from '../BookingWidget/bookingSteps.interface';
import { PromotionPropsBase } from './quotationFormWeb.types';
import './QuotationFormWeb.scss';

interface SelectPromotionProps extends PromotionPropsBase {
  bookingConfigData: BookingConfig;
}

const SelectPromotion: FC<SelectPromotionProps> = ({
  control,
  bookingConfigData,
  t,
  value = '',
  onChange,
  sx = {},
}): JSX.Element => {
  const renderTextField = (fieldProps: any): JSX.Element => (
    <TextField
      {...fieldProps}
      select
      className="promotion"
      SelectProps={{
        displayEmpty: true,
        className: 'destination-select',
        renderValue: (selected: any) => {
          if (!selected) {
            return t('BOOKING.QUOTATION_FORM.web.noDiscount');
          }
          if (bookingConfigData?.[BookingConfigEnum.PromotionCodes]) {
            return (
              bookingConfigData?.[BookingConfigEnum.PromotionCodes].find(
                (promo) => promo.value === selected,
              )?.name ?? selected
            );
          }
          return selected;
        },
      }}
      sx={{ width: '100%', ...sx }}
      inputProps={{
        'data-cy': 'bookingW_PromoCode_input',
      }}
      variant="outlined"
    >
      <MenuItem value="">{t('BOOKING.QUOTATION_FORM.web.noDiscount')}</MenuItem>
      {bookingConfigData?.[BookingConfigEnum.PromotionCodes]
        ?.sort((pc1, pc2) => pc1.discount - pc2.discount)
        .map((item: any) => (
          <MenuItem key={item.value} value={item.value}>
            {item.name}
          </MenuItem>
        ))}
    </TextField>
  );

  return control ? (
    <Controller
      name="promoCode"
      control={control}
      defaultValue=""
      render={({ field }) => renderTextField(field)}
    />
  ) : (
    renderTextField({ value, onChange })
  );
};

export default SelectPromotion;
