import React from 'react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { usePriceSummaryLoading } from '../../../context/priceSummaryContext/priceSummaryContext';

function MedicalDisclaimer(): React.ReactElement | null {
  const { t } = useTranslation();
  const { medicalConditionsCovered } = usePriceSummaryLoading();

  if (!medicalConditionsCovered.some((condition) => condition === 'S')) {
    return null;
  }

  return (
    <Box
      sx={{
        minHeight: '3.2rem',
        marginTop: '1rem',
        marginBottom: 0,
      }}
    >
      <Typography
        sx={{
          color: 'red',
          lineHeight: '1rem !important',
          fontSize: '0.75rem !important',
        }}
        data-cy="bookingW_Quote_totalPrice_label"
      >
        {parse(t('BOOKING.QUOTATION_SUMMARY_BLOCK.travellerExcluded'))}:
      </Typography>
    </Box>
  );
}

export default MedicalDisclaimer;
