export enum RequestHeaders {
  X_API_KEY = 'X-Api-Key',
  X_CURRENT_SESSION_CONTEXT = 'X-Current-Session-Context',
  CONTENT_TYPE = 'Content-Type',
  CLIENT_ID = 'Client-Id',
}

export interface ExtraHeaders {
  [RequestHeaders.X_API_KEY]?: string;
  [RequestHeaders.X_CURRENT_SESSION_CONTEXT]?: string;
  [RequestHeaders.CONTENT_TYPE]?: string;
  [RequestHeaders.CLIENT_ID]?: string;
}
