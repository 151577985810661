import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Typography } from '@mui/material';
import MedicalDisclaimer from './MedicalDisclaimer';
import CurrencyComponent from '../../../shared/components/Currency/Currency';
import { usePriceSummaryLoading } from '../../../context/priceSummaryContext/priceSummaryContext';
import PriceSummaryAddons from './PriceSummaryAddons';
import PriceSummarySelectedCover from './PriceSummarySelectedCover';
import PriceSummaryMedical from './PriceSummaryMedical';
import PriceSummaryTax from './PriceSummaryTax';
import PriceSummaryPromo from './PriceSummaryPromo';

const PriceSummarySection = (): React.ReactElement => {
  // util
  const { t } = useTranslation();

  const { totalPrice, currency, isSummaryValuesLoading } =
    usePriceSummaryLoading();

  return (
    <>
      <PriceSummarySelectedCover />
      <PriceSummaryAddons />
      <PriceSummaryMedical />
      <PriceSummaryPromo />
      <PriceSummaryTax />

      {totalPrice && totalPrice.grossPremium > 0 && (
        <Box
          sx={{
            minHeight: '3.2rem',
            marginTop: '1rem',
            marginBottom: 0,
          }}
          className="summary-box"
        >
          <Typography
            className="summary-title"
            sx={{
              paddingBottom: '1rem',
              fontWeight: 600,
              color: 'var(--dark-secondary)',
              lineHeight: '1rem !important',
              fontSize: '1rem !important',
            }}
            data-cy="bookingW_Quote_totalPrice_label"
          >
            {t('BOOKING.QUOTATION_SUMMARY_BLOCK.totalPrice')}:
          </Typography>
          {isSummaryValuesLoading ? (
            <CircularProgress
              size="1.5rem"
              sx={{ marginTop: '4px', marginLeft: '10px' }}
            />
          ) : (
            <span data-cy="bookingW_Quote_totalPrice_value">
              <Box
                sx={{
                  display: 'flex',
                  gap: '.4rem',
                  fontWeight: '700',
                  fontSize: '1.625rem',
                  lineHeight: '1.5rem',
                  color: 'var(--secondary-light)',
                }}
              >
                <CurrencyComponent
                  typographyStyle={{
                    fontWeight: '700',
                    fontSize: '1.625rem',
                    lineHeight: '1.5rem',
                  }}
                  value={totalPrice.grossPremium}
                />
                {currency}
              </Box>
            </span>
          )}
        </Box>
      )}

      <MedicalDisclaimer />
    </>
  );
};

export default PriceSummarySection;
