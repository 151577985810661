import React, { FC, useContext, useMemo } from 'react';
import { Box, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from '../../BookingWidget/bookingSteps.interface';
import parseAddonsToSummaryElement from '../../QuotationsBlockShare/QuotationPriceElement/quotationSummaryElement.utils';
import { usePriceSummaryLoading } from '../../../context/priceSummaryContext/priceSummaryContext';
import { BookingContext } from '../../../context/bookingContext/bookingContext';

import QuotationElementBlockPrice from '../../QuotationsBlockShare/QuotationPriceElement/QuotationElementBlockPrice';

const PriceSummaryAddons: FC = () => {
  const { t } = useTranslation();
  const { bookingSteps } = useContext(BookingContext);
  const { additionalPrices } = usePriceSummaryLoading();

  const parsedAddonsToSummaryProducts = useMemo(() => {
    const rawAddons =
      bookingSteps[BookingStepValueEnum.QuotationProposals][
        BookingSubStepValueEnum.Addons
      ];
    return parseAddonsToSummaryElement(rawAddons, additionalPrices);
  }, [bookingSteps, additionalPrices]);

  return (
    !!parsedAddonsToSummaryProducts?.length && (
      <>
        <Box>
          <QuotationElementBlockPrice
            label={t('BOOKING.QUOTATION_SUMMARY_BLOCK.addons')}
            product={parsedAddonsToSummaryProducts}
            fontWeightLabel="bold"
            fontWeightValue="normal"
          />
        </Box>
        <Divider sx={{ margin: '0.5rem 0' }} />
      </>
    )
  );
};

export default PriceSummaryAddons;
