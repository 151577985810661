import {
  BookingConfig,
  BookingConfigEnum,
  BrandShortcuts,
} from '../../components/BookingWidget/bookingSteps.interface';

const canUsePromotion = (bookingConfigData: BookingConfig): boolean =>
  bookingConfigData[BookingConfigEnum.Brand].includes(BrandShortcuts.FCB);

export default canUsePromotion;
