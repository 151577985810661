import {
  BookingConfig,
  BookingConfigEnum,
  ConfigTypeEnum,
} from '../../components/BookingWidget/bookingSteps.interface';
import { isAgent } from './auth.helper';

const isWhitelabel = (bookingConfigData: BookingConfig): boolean =>
  !isAgent() &&
  bookingConfigData[BookingConfigEnum.Type] === ConfigTypeEnum.WHITE_LABEL;

export default isWhitelabel;
