import {
  type BookingConfig,
  BookingConfigEnum,
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from '../../components/BookingWidget/bookingSteps.interface';
import { CANCELLATION_LIMIT_UNLIMITED_VALUE } from '../consts/app.const';
import { AddonsEnum } from '../consts/optionsAndAddons.const';
import { BookingStepsContextState } from '../../context/bookingContext/bookingContext.types';

const isTripCostApplicable = (
  bookingSteps: BookingStepsContextState,
  bookingConfig: BookingConfig,
  isPolicyModification = false,
): boolean => {
  const cancellationLimit = +(
    bookingSteps[BookingStepValueEnum.QuotationProposals]?.[
      BookingSubStepValueEnum.CancellationLimit
    ] ?? 0
  );

  const isCFARApplicable =
    !!bookingSteps[BookingStepValueEnum.QuotationProposals]?.[
      BookingSubStepValueEnum.Addons
    ]?.[AddonsEnum.CFAR];

  return (
    !!bookingConfig[BookingConfigEnum.TripCostVisible] &&
    !isPolicyModification &&
    (cancellationLimit === CANCELLATION_LIMIT_UNLIMITED_VALUE ||
      isCFARApplicable)
  );
};

export default isTripCostApplicable;
