import {
  BookingConfig,
  BookingConfigEnum,
} from '../BookingWidget/bookingSteps.interface';
import { BookingQuoteResponse } from '../BookingWidget/BookingQuoteManagment/bookingQuoteResponse.interface';
import {
  BookingDataEnum,
  BookingDataResponse,
  BookingManagementEnum,
} from '../BookingWidget/BookingManagment/bookingManagment.interface';

export interface TravelConnectUrlBuilderBlueprint {
  policyId: string;
  bookingUuid: string;
  ref: string | null;
}

export type TravelConnectUrlBuilderProps =
  Partial<TravelConnectUrlBuilderBlueprint>;

export const getTravelConnectUrlFromBookingConfig = (
  bookingConfig: BookingConfig,
): string | null =>
  bookingConfig[BookingConfigEnum.DataCenter]?.travelConnectUrl || null;

export const getQuoteIdFromBookingDataConfig = (
  bookingQuote: BookingQuoteResponse,
): string | null => bookingQuote?.quotes?.[0]?.quoteId || null;

export const getBookingExternalId = (
  bookingDataContext: BookingDataResponse | null,
): string | null =>
  bookingDataContext?.[BookingManagementEnum.BookingData]?.[
    BookingDataEnum.ExternalId
  ] || null;
