import React, { FC, memo, useContext, useMemo } from 'react';
import { t } from 'i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { BookingConfigContext } from '../../../context/bookingConfigContext/bookingConfigContext';
import { StepperContext } from '../../../context/stepperContext/stepperContext';
import {
  BookingConfigEnum,
  BookingStepEnum,
} from '../../BookingWidget/bookingSteps.interface';

import './progress-stepper-mobile.scss';
import { CacheDataContext } from '../../../context/cacheContext/cacheContext';
import getStepName from '../getStepName';
import { AppEvents, emit$ } from '../../../shared/app-events';

const ProgressStepperMobile: FC = () => {
  const { bookingConfigData } = useContext(BookingConfigContext);
  const { goToStep } = useContext(StepperContext);
  const { store } = useContext(CacheDataContext);

  const currentStep = store.currentStep
    ? bookingConfigData[BookingConfigEnum.BookingSteps][store.currentStep]
    : null;

  const clickHandler = (position: number): void => {
    if (!store.currentStep) {
      throw new Error('[ProgressStepperMobile] store.currentStep is undefined');
    }

    const newIndex = store.currentStep + position;
    if (store.currentStep && store.currentStep > newIndex)
      emit$(AppEvents.ON_NAVIGATION_BACK);
    goToStep(newIndex);
  };

  const buttonClass = useMemo(
    () => `transparent-btn ${store.currentStep === 1 ? 'hidden' : ''}`,
    [store.currentStep],
  );

  if (store.currentStep === 5) return null;

  return (
    <div className="progress-stepper-mobile-wrapper">
      <button
        onClick={() => clickHandler(-1)}
        className={buttonClass}
        type="button"
        aria-label="previous step"
      >
        <ArrowBackIcon />
      </button>
      <div className="current-step-information">
        <div className="current-step-badge">{store.currentStep}</div>
        {currentStep && (
          <span>
            {getStepName(
              currentStep[BookingStepEnum.KeyName],
              t,
              bookingConfigData,
            )}
          </span>
        )}
      </div>
      <button
        onClick={() => clickHandler(1)}
        className={buttonClass}
        type="button"
        aria-label="next step"
        disabled={store.currentStep !== 1}
      >
        <ArrowForwardIcon />
      </button>
    </div>
  );
};

export default memo(ProgressStepperMobile);
